@import "styles/vars";

.alert {
  background-color: #f7f3eb;
  border: 1px solid #fa9903;
  border-radius: 8px;
  position: relative;
  padding: 1.1em 1em;
  &WithIcon {
    padding-left: 3.3em;
  }
  a {
    color: #4169b8;
    &:hover {
      color: #5175bd;
    }
    &:active {
      color: #38558b;
    }
  }
  svg {
    width: 1em;
    height: 1em;
    path {
      fill: #4169b8;
    }
  }
  &Absolute {
    position: fixed;
    top: 7px;
    left: calc(50% + 9em);
    transform: translateX(-50%);
    z-index: 13;
    padding: 0.7em 1.5em 0.7em 3em;
    width: max-content;
  }
  &Icon {
    position: absolute;
    left: -1.8em;
    top: 50%;
    transform: translateY(-50%);
    height: 1.3em;
    width: 1.3em;
    > svg {
      height: 1.3em;
      width: 1.3em;
      path {
        fill: #fa9903;
      }
    }
  }
  &Title {
    font-weight: 600;
    color: #27313d;
    position: relative;
    > span {
      font-size: 1.1em;
      line-height: 1.4em;
      display: flex;
    }
  }
  &Children {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &Message {
    color: #27313d;
    > span {
      font-size: 0.875em;
      line-height: 1.7em;
      display: flex;
      align-items: center;
    }
  }
  &Action {
    display: flex;
    align-items: center;
    margin-left: 0.5em;
    svg {
      width: 1.5em;
      height: 1.5em;
      path {
        fill: $link2;
      }
    }
  }
}
