.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 8em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &Icon {
    width: 8em;
    height: 8em;
    background: url("../../assets/img/loader.svg") no-repeat center center;
    background-size: contain;
  }

  &Text {
    font-size: 1.25em;
    color: #5175bd;
  }
}
