@media only screen and (max-width: 960px) {
  .app-container {
    width: 960px;
  }
}

@media only screen and (max-width: 1132px) {
  body {
    font-size: 14px !important;

    .left-navbar {
      width: 15em;
    }

    .page-content {
      width: calc(100% - 15em);
    }

    .pagination {
      left: 18.75em;
      width: calc(100% - 18.75em);
    }

    .company .company_header h3 {
      font-size: 1.25em;
    }
  }
}

@media only screen and (max-width: 1250px) {
  body {
    font-size: 15px;

    table thead.w-filter tr th:nth-child(1) {
      .input_form,
      .dropdown-component {
        margin-left: 0.5em;

        .input_form-input,
        .dropdown-component__select {
          width: 13.5em;
        }
      }
    }

    .primary-counter_inputs-item:first-child {
      margin-right: 4em !important;
    }
  }
}

@media only screen and (max-width: 1320px) {
  .device-mgmt
    .react-tabs__tab-panel
    .device-mgmt-devices
    .device-mgmt-devices_header
    .device-mgmt-devices_header-item:nth-child(2) {
    flex-direction: column;

    .btn-primary2 {
      margin-bottom: 0.5em;
    }
  }
}

@media only screen and (max-width: 1431px) {
  .device-mgmt
    .react-tabs__tab-panel
    .device-mgmt-devices
    .device-mgmt-devices_header
    .device-mgmt-devices_header-item {
    .input_form-input {
      width: 15em;
    }
    &:nth-child(2) .btn-primary2 {
      margin-right: 0 !important;
    }
  }

  .settings {
    .settings_header-right {
      text-align: right;

      .btn {
        font-size: 0.875em !important;
      }
    }

    .react-tabs__tab-panel-wrapper {
      width: calc(100% - 18.5em) !important;

      .settings_row-custom-inputs {
        .clear-input {
          width: 7.5em !important;

          &:first-child {
            margin-right: 0.5em !important;
          }

          input {
            padding: 0.5em 1.5em 0.5em 1em !important;
          }
        }
      }

      .settings_row-custom-actions {
        .btn-32 {
          padding: 0.5em 1em !important;
        }
      }
    }

    .react-tabs__tab-list {
      width: 18.5em !important;
    }
  }

  .truscan .truscan_table td:nth-child(4) .btn {
    font-size: 0.75em !important;
  }
}

@media only screen and (max-width: 1540px) {
  .truscan .truscan_table td:nth-child(4) .btn {
    font-size: 0.875em;
  }
}

@media only screen and (min-width: 1603px) {
  body {
    font-size: 18px;
  }
}

@media only screen and (min-width: 2048px) {
  body {
    font-size: 22px;
  }
}

@media only screen and (min-width: 2560px) {
  body {
    font-size: 26px;
  }
}

@media only screen and (min-width: 2800px) {
  body {
    font-size: 30px;
  }
}

@media only screen and (min-width: 3200px) {
  body {
    font-size: 32px;
  }
}

@media only screen and (min-width: 3840px) {
  body {
    font-size: 34px;
  }
}
