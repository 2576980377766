.preview {
  width: 31.25em;
  height: 15.625em;
  box-shadow: 0px 4px 20px rgba(34, 35, 37, 0.15);
  background: #f7fafc;
  display: flex;
  position: relative;
  pointer-events: none;
  &Nav {
    background: linear-gradient(310.26deg, #1f2536 57.58%, #4169b8 146.59%);
    max-width: 6.540625em;
    flex: 0 0 6.540625em;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &Logo {
      position: absolute;
      left: 0.71625em;
      top: 0.34625em;
      &Image {
        width: 2.3175em;
        height: 0.7em;
      }
      &Custom {
        height: 1.2em;
        object-fit: contain;
      }
    }
    &Menu {
      color: #fff;
      margin-top: 2.035625em;
      &Item {
        display: flex;
        align-items: center;
        gap: 0.7em;
        font-weight: 500;
        font-size: 0.3465275em;
        line-height: 0.5em;
        color: inherit;
        padding: 1em 0;
        padding-left: 1.3em;
        position: relative;
        margin-bottom: 0.34625em;
        opacity: 0.8;
        span {
          content: "";
          width: 0.25em;
          border-radius: 0 1em 1em 0;
          height: 100%;
          position: absolute;
          left: 0;
        }
        svg {
          width: 1.5em;
          height: 1.5em;
          path {
            fill: inherit;
          }
        }
        &.active {
          opacity: 1;
          background: rgb(65 105 185 / 20%);
          span {
            background: #4169b8;
          }
        }
        &Plus {
          position: absolute;
          right: 0.7em;
          top: 50%;
          transform: translateY(-50%);
          path {
            fill: #ffffff;
          }
        }
      }
    }
    &Footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      opacity: 0.8;
      line-height: 0.5em;
      padding: 0.433125em;
      &Copyright {
        font-size: 0.16em;
        color: #fff;
      }
      &Downloads {
        display: flex;
        align-items: center;
        gap: 0.3em;
        font-weight: 500;
        font-size: 0.2135em;
        svg {
          margin-top: -0.15em;
          width: 1.5em;
          width: 1.5em;
          fill: #fff;
          path {
            fill: inherit;
            opacity: 0.8;
          }
        }
      }
    }
  }
  &Main {
    flex: 1;
    padding: 0.45em 0.86625em 0.709375em 0.86625em;
    &Image {
      width: 100%;
      height: 100%;
    }
    &Wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.45em;
    }
    &Timer {
      padding-left: 1.19125em;
      font-weight: 400;
      font-size: 0.28155375em;
      line-height: 1.075em;
      color: #27313d;
      span {
        color: #fa9903;
        font-weight: 700;
      }
    }
    &Avatar {
      width: 0.7em;
      height: 0.7em;
      background-color: #fff;
      border-radius: 50%;
      line-height: 0.7em;
      font-weight: 500;
      border: 2px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      &Wrap {
        display: flex;
        align-items: center;
      }
      &Down {
        width: 0.52em;
        height: 0.52em;
      }
      svg {
        width: 0.4em;
      }
    }
  }
}
