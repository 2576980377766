.resourceAssignments {
  height: 100%;

  &Header {
    padding: 1em 2em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4em;
  }
  &Title {
    font-weight: 600;
    font-size: 1.5em;
    margin: 0;
  }
}
