@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap");
@import "./_reset.scss";
@import "./_vars.scss";

body {
  height: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  color: #27313d;
  background-color: #f7fafc;
  background-size: cover;
  background-attachment: fixed;
}

#root {
  height: 100%;
}

@import "./global.scss";
@import "./responsive.scss";
@import "./toastify.scss";
@import "./tabs.scss";
@import "./inputs.scss";
