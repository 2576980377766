.info {
  padding: 1.5em;
  background: #f5f8fa;
  border: 1px solid #e3e8ee;
  border-radius: 0.5em;
  margin: 0 2em;

  &Title {
    font-size: 1em;
    margin: 0 0 0.75em;
    font-weight: 500;
  }

  &Subtitle {
    font-size: 0.875em;
    color: #525a64;
    margin-bottom: 1.5em;
  }

  &List {
    &Item {
      font-size: 0.875em;
      &:not(:last-child) {
        margin-bottom: 0.8571em;
      }
      &:before {
        content: "\2022";
        color: #848a92;
        font-weight: bold;
        display: inline-block;
        width: 1em;
      }
    }
  }
}
