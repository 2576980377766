.pageItem {
  border-bottom: 1px solid #e3e8ee;
  padding: 1em 6em 1em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }

  &Wrap {
    display: flex;
    gap: 1em;

    svg {
      width: 1.25em;
      height: 1.5em;
    }
  }

  &Title {
    font-weight: 600;
    font-size: 1em;
    line-height: 150%;
    color: #27313d;
    margin-bottom: 0.25em;
  }

  &Description {
    font-size: 0.875em;
    line-height: 129%;
    color: #525a64;
  }

  &Button {
    background-color: var(--button-bg, #4169b8);
    color: var(--button-color, #fff);
    border-radius: 0.5em;
    height: 2.3em;
    padding: 0 1em;
    font-size: 0.875em;
    line-height: 171%;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.75em;
    transition: all 0.2s ease 0s;
    margin-left: 5.75em;

    svg {
      transform: rotateY(180deg);
      path {
        fill: var(--button-color, #fff);
      }
    }

    &:hover {
      background-color: var(--button-bg-hover, #5175bd);
      border-color: var(--button-border-hover, #5175bd);
      color: var(--button-color, #fff);

      svg path {
        fill: var(--button-color, #fff);
      }
    }

    &:active {
      background-color: var(--button-bg-active, #38558b);
      border-color: var(--button-border-active, #38558b);
      color: var(--button-color, #fff);

      svg path {
        fill: var(--button-color, #fff);
      }
    }
  }
}
