@import "styles/vars";

.header {
  display: flex;
  align-items: center;
  padding: 1em 1.75em;
  background: #fff;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;

  &Back {
    font-size: 0.8125em;
    color: $link2;
    svg path {
      fill: $link2;
    }
    &:hover {
      color: $link2-hover;
      svg path {
        fill: $link2-hover;
      }
    }
    &:active {
      color: $link2-active;
      svg path {
        fill: $link2-active;
      }
    }
  }

  &Domain {
    margin-left: 0.5em;
    font-weight: 500;
    color: #27313d;
  }
}
