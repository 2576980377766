.container {
  border-radius: 0.5em;
  border: 1px solid #e0e0e0;
  padding: 1em 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  height: 100%;
  &Title {
    color: #525a64;
    font-size: 1.125em;
    line-height: 1.3em;
    font-weight: 500;
    margin-bottom: 0.3125em;
  }
  &Row {
    display: flex;
  }
  &Graphic {
    height: 12.625em;
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0 auto;
  }
}

.legend {
  display: flex;
  align-items: baseline;
  gap: 0.25em;
  margin-bottom: 1em;
  padding-left: 0.75em;
  &Color {
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
  }
  &Text {
    font-size: 1em;
    font-weight: 500;
  }
  &Value {
    font-size: 0.75em;
  }
}
