.list {
  margin-bottom: -1.5em;
  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5em 0 1em;
    &Title {
      font-size: 0.8125em;
      color: #525a64;
    }
    &Button {
      font-weight: 500;
      font-size: 0.875em;
    }
  }
  &Content {
    margin: 0 -2em;
    height: 15.75em;
    overflow-y: auto;
    &Item {
      &New,
      &:hover {
        background-color: #f7fafc;
      }
      &Checkbox {
        padding: 0.625em 2em;
        justify-content: space-between;
      }
    }
    &NoResults {
      color: #525a64;
      font-style: italic;
      font-weight: 400;
      margin: 1.5em 0;
      pointer-events: none;
      text-align: center;
    }
  }
}
