.personal {
  padding: 1em 2em;
  border-bottom: 1px solid #e3e8ee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  margin-bottom: 2em;

  &Wrap {
    display: flex;
    width: 70%;
  }
  &Section {
    width: 40%;
    position: relative;

    &:not(:first-child) {
      padding: 0 0 0 32px;
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 50%;
      transform: translateY(50%);
      height: 85%;
      width: 1px;
      border-right: 1px solid #e3e8ee;
    }

    &Title {
      margin: 0 0 0.5em;
      font-weight: 500;
      font-size: 0.875em;
      display: flex;
      align-items: center;
      color: #525a64;

      svg {
        margin-left: 0.5714em;
        cursor: pointer;
      }
    }

    &Data {
      margin: 0;
      font-size: 1.5em;
      color: #27313d;

      .sit-wrapper {
        font-size: 0.6667em;
      }
    }
  }

  &Table {
    tr {
      td,
      th {
        &:nth-child(2) {
          justify-content: flex-end;
        }
      }
    }
    tr {
      td {
        height: 4em;
      }
    }
  }

  .primary-totals_button button {
    width: 12em;
  }
}
