.mfa {
  &Header {
    height: 5.5em;
    padding: 0 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    z-index: 2;

    &.active {
      position: sticky;
      position: -webkit-sticky;
      top: calc(-1em - 1px);
      transition: 0.2s ease-out;

      &.sticked {
        -webkit-box-shadow: 0 0 0.25em rgb(129, 129, 129);
        -moz-box-shadow: 0 0 0.25em rgb(129, 129, 129);
        box-shadow: 0 0 0.25em rgb(129, 129, 129);
      }
    }

    &CounterActions {
      display: flex;
      align-items: center;

      .selectedCount {
        position: relative;
        font-weight: 500;
        padding-right: 1.5em;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background: #e3e8ee;
        }
      }

      .deselectButton {
        margin: 0 1.5em;
      }
    }

    &Filter {
      display: flex;
      align-items: center;
    }
  }
}
