.table {
  width: 100%;
  max-width: 100%;
  font-weight: 400;
  position: relative;
  &.noData {
    height: 100%;
  }
  &.loading {
    &::before {
      content: "";
      z-index: 2;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #ffffff7d;
    }
  }
  &.full {
    tbody {
      tr {
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  &NoData {
    color: #27313d;
    display: flex;
    font-weight: 400;
    width: 100%;
    height: 100%;
    td {
      align-items: center !important;
      border: none;
      display: flex !important;
      flex-direction: column;
      padding: 10% 0 0;
      width: 100% !important;
    }
  }
  &Row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    color: #27313d;
    border-bottom: 1px solid #e3e8ee;
    &Body {
      &Selected,
      &:hover {
        background-color: #f7fafc;
      }
    }
  }
  &Checkbox {
    margin-right: 1em;
  }
  &Col {
    display: inline-flex;
    align-items: center;
    border-color: #e3e8ee;
    padding: 0.5em;
    &Head {
      font-weight: 500;
      border: none;
      color: #577087;
    }
    &Truncate {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:first-child {
      padding-left: 2em;
    }
    &:last-child {
      padding-right: 2em;
    }
    &Sortable {
      color: #27313d;
    }
    &Sorting {
      display: inline-flex;
      align-items: center;
      text-align: left;
      cursor: pointer;
      &Button {
        padding: 0;
        display: flex;
        align-items: center;
        gap: 0.25em;
        transition: color 0.1s ease 0s;
        &:hover {
          color: #2f9bc2;
        }
        &:active {
          color: #2d86a7;
        }
        &:hover {
          .tableColSortingIcon {
            path {
              fill: #2f9bc2;
            }
          }
        }
        &:active {
          path {
            color: #2d86a7;
          }
        }
      }
      &Icon {
        &.iconActive {
          path {
            fill: #2f9bc2;
          }
        }
      }
      &Load {
        height: 1em;
        width: 1em;
        background: url("../../assets/img/loader.svg") no-repeat center center;
        background-size: contain;
      }
    }
    &Search {
      margin-left: 1em;
    }
  }
}
