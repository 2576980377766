@import "styles/vars";

.countDown {
  align-items: center;
  color: $primary;
  display: flex;
  gap: 0.25em;
  > span {
    font-size: 0.875em;
    line-height: 171%;
    color: #525a64;
  }
  &Button {
    font-size: 0.875em;
    line-height: 171%;
    color: #4169b8;
    font-weight: 600;
    &.disabled {
      color: #92a6ce;
    }
  }
  &Numbers {
    font-weight: 600;
    font-size: 0.875em;
    line-height: 171%;
    color: #92a6ce;
  }
}
