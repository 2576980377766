@import "styles/vars";

.customers {
  background: $white;
  border: 1px solid $white2;
  box-shadow: 0px 1.5em 6.25em rgba(31, 37, 54, 0.07);
  border-radius: 0.5em 0.5em 0 0;
  color: $gray5;
  position: relative;
  min-height: calc(100% - 1.75em);
  margin-bottom: 1.75em;
  display: grid;

  &Domains {
    &Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5em 2em;
      border-bottom: 1px solid $white2;
      height: 5.5em;
    }
    &Title {
      font-weight: 600;
      font-size: 1.5em;
    }
  }
}
