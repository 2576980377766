.login {
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  &Row {
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Link {
    font-size: 0.875em;
    font-weight: 500;

    &:only-child {
      margin: 0 auto;
    }
  }

  &Resend {
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Countdown {
    margin: 0;
  }
}