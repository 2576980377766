@import "styles/vars";

.modal {
  &Info {
    background: #f6f9fb;
    padding: 1.5em 1em;
    margin-bottom: 1em;
    &Text {
      font-size: 1em;
      line-height: 150%;
      color: #27313d;
    }
    &Row {
      display: flex;
      align-items: center;
      margin-top: 1em;
      gap: 0.5em;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &Wrap {
    display: flex;
    align-items: center;
    gap: 1em;
    margin-top: 1em;
  }
  &Input {
    width: 100%;
    height: 2.55em;
    background: #ffffff;
    border: 1px solid #b2bccc;
    outline: 1px solid transparent;
    color: #27313d;
    outline-offset: -2px;
    transition:
      border-color 0.2s ease 0s,
      outline-color 0.2s ease 0s;
    border-radius: 0.5em;
    padding: 0.5em 1em;

    &Wrap {
      position: relative;
      width: 100%;
    }

    &Error {
      font-size: 0.75em;
      line-height: 133%;
      color: $red;
      position: absolute;
      padding-left: 1.5em;
      top: calc(100% + 0.25em);
    }

    &.hasValue {
      border-color: #7a97ba;
    }

    &:hover:not(&.error) {
      border-color: #5175bd;
    }

    &:active,
    &:focus {
      border-color: #4169b8;
      outline-color: #4169b8;
    }

    &.error {
      border-color: $red;
      outline-color: $red;
    }
  }

  &Dropdown {
    border-color: #848a92;
    width: 12em;

    &Options {
      max-height: 17em;

      > button {
        &:last-child {
          color: #4169b8;
          font-weight: 600;
        }
      }
    }
  }
}
