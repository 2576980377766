@import "styles/vars";

.mslogin {
  border: 1px solid #9da5af;
  border-radius: 0.25em;
  padding: 0.375em;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: $white;
  width: 9.25em;
  margin-left: 0.5em;

  svg {
    width: 1em;
    height: 1em;
    margin-right: 0.375em;
  }

  span {
    font-size: 0.75em;
  }

  &:hover:not(.msloginPositive) {
    border-color: $azure-hover;
  }

  &:active:not(.msloginPositive) {
    border-color: $azure-hover;
    background: $azure-hover;

    span {
      color: $white;
    }
  }

  &Positive {
    border-color: $azure-green;
    cursor: default;
    width: auto;

    span {
      color: $azure-green;
    }
  }
  &Onboarding {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    color: $link;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    &:hover {
      color: $link-hover;
    }
    &:active {
      color: $link-active;
    }
  }
}
