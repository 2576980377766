.tabList {
  padding: 0;
  color: #4169b8;
  margin-bottom: 0.75em;
  display: flex;
  align-items: center;
  gap: 1.375em;
  &Tab {
    padding: 0;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.5em;
    color: #525a64;
    position: relative;
    padding-bottom: 0.35em;
    cursor: pointer;
    &Selected {
      color: #4169b8;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.25em;
        border-radius: 0.125em;
        background-color: #4169b8;
      }
    }
  }
}
