.codeInput {
  width: 2.75em;
  height: 2.75em;
  border: 1px solid #7a97ba;
  outline: 1px solid transparent;
  outline-offset: -2px;
  border-radius: 0.25em;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #27313d;
  text-align: center;
  transition: all 0.2s ease 0s;
  position: relative;
  &:hover {
    border-color: #5175bd;
  }
  &:focus {
    border-color: #4169b8;
    outline-color: #4169b8;
  }
  &.disabled {
    background: #f7f9fc;
    border-color: #e3e8ee;
    pointer-events: none;
  }
  &.error {
    border-color: #f3657b;
    outline-color: transparent;
  }
  &Wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 0.5em;
    position: relative;
    max-width: fit-content;
    margin: 0 auto;
  }
  &Error {
    text-align: center;
    font-size: 0.75em;
    line-height: 133%;
    margin-top: 0.25em;
    color: #f3657b;
  }
  &Loader {
    position: absolute;
    left: calc(100% + 1em);
    top: 50%;
    transform: translateY(-50%);
  }
}
