@import "styles/vars";

.domainList {
  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1em 0 1em;
  }
  &Title {
    font-size: 0.8125em;
    color: $link2;
  }
  &Button {
    font-size: 0.875em;
  }
  &Primary {
    margin-top: 1em;
  }
  &Wrap {
    height: 12.5em;
    overflow-y: auto;
    margin: 0 -2em;
    &.short {
      height: auto;
    }
  }
  &NoResults {
    height: 12em;
    display: flex;
    justify-content: center;
    margin-top: 4em;
    > p {
      color: #525a64;
      font-style: italic;
      font-weight: 400;
      pointer-events: none;
      text-align: center;
      margin: 0;
    }
  }
  &List {
    &Item {
      color: $gray5;
      &Checkbox {
        padding: 0.625em 2em;
        justify-content: space-between;
      }
      &New,
      &:hover {
        background-color: $gray3;
      }
    }
  }
}
