@import "styles/vars";

.activation {
  display: flex;
  align-items: center;
  width: 17.5em;
  justify-content: space-between;

  .textWrapper {
    display: flex;
    font-size: 0.825em;
    color: $link2;
    align-items: center;
  }

  &Long {
    width: 100%;

    .textWrapperColumn {
      display: flex;
      flex-direction: column;
      font-size: 0.875em;
      color: $link2;
      align-items: flex-start;

      .activationInput {
        width: 35em;
        font-size: 0.75em;
        color: $black;
        margin-left: 0;
      }
    }
  }

  .activationInput {
    outline: none;
    border: none;
    width: 6em;
    margin-left: 0.4em;
    color: $gray5;
  }

  &Actions {
    display: flex;

    &Btn {
      border-radius: 1.5em;
      cursor: pointer;
      height: 2.5em;
      display: flex;
      align-items: center;
      width: 2.5em;
      justify-content: center;

      &:hover {
        background-color: $state-hover;

        svg path {
          fill: #798491;
        }
      }

      &:active {
        background-color: $state-active;

        svg path {
          fill: $link2;
        }
      }

      svg {
        cursor: pointer;
        width: 1.5em;
        height: 1.5em;

        path {
          fill: #798491;
        }
      }

      > span {
        display: flex;
        justify-content: center;
      }
    }

    &Long {
      margin-right: 0.5em;
    }
  }
}
