@import "styles/vars";

.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -3em;
  z-index: 2;

  &Button {
    color: $link2;
    svg path {
      fill: $link2;
    }
    &:hover {
      svg path {
        fill: $link2;
      }
    }
    &:active {
      svg path {
        fill: $link2;
      }
    }
  }

  svg {
    cursor: pointer;
    padding: 0;
    height: 24px;
    width: 24px;

    &:first-child {
      margin-right: 0.5em;
    }
    &:last-child {
      margin-left: 0.5em;
    }

    &:hover:not(.disabled) {
      background: #f7f9fc;
    }
    &:active:not(.disabled) {
      background: #e3e5e8;
    }

    &.disabled {
      pointer-events: none;

      path {
        fill: #a9adb3;
      }
    }
  }

  &Input {
    padding: 0.5em 0.75em;
    width: 4em;
    text-align: center;
    border: 2px solid #e3e8ee;
    border-radius: 0.5em;

    &Wrap {
      font-weight: 500;
    }

    &:hover {
      box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
    }

    &:focus {
      border-color: #bcd6eb;
      background-color: #fff;
      box-shadow: none;
      outline: none;
    }

    &:disabled {
      color: #848a92;

      &:hover {
        box-shadow: none;
      }
    }

    &Warning,
    &Warning:focus {
      border-color: #fa9903;
    }
  }
}
