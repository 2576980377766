.azureAdLoginStatus {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 1em;
  padding: 0.5em 2em 0;
  height: 3.25em;
  &SwitchCheckbox {
    align-items: center;
    display: flex;

    &Title {
      margin-right: 0.875em;
    }

    &Slide {
      font-size: 1.25em;
    }

    &Text {
      color: #848a92;
      font-weight: 700;
      margin-left: 0.25em;

      &.active {
        color: #8bc53f;
      }
    }
  }
}
