.setupMfa {
  min-height: 14.175em;
  position: relative;
  &Title {
    font-size: 1.5em;
    line-height: 117%;
    font-weight: 600;
    text-align: center;
    position: relative;
    margin-bottom: 1em;
  }
  &Back {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    svg {
      width: 1em;
      height: 1em;
    }
  }
  &Resend {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }
  &Info {
    font-weight: 400;
    font-size: 0.875em;
    line-height: 140%;
    text-align: center;
    color: #525a64;
    margin-bottom: 1.5em;
  }
  &InputTitle {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 150%;
    color: #27313d;
    text-align: center;
    margin-bottom: 0.75em;
  }
}
