.loginHeader {
  position: relative;
  text-align: center;
  margin-bottom: 1.5em;
  &Step {
    font-size: 0.75em;
    line-height: 140%;
    text-align: center;
    color: #4169b8;
    padding-bottom: 0.5em;
  }
  &Back {
    position: absolute;
    left: 0;
    top: 0;
    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
  &Title {
    font-size: 1.5em;
    line-height: 117%;
    font-weight: 600;
    text-align: center;
  }
  &Idle {
    margin-top: 0.5em;
  }
}
