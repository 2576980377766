.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background: #fff;
  padding: 2em;
}

.backdrop {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: #ffffff7d;
}
