.licensingTable {
  &Row {
    &Negative {
      background: #fdfafb;
      td {
        &:nth-child(2) {
          color: #f3657b;
        }
      }
    }
    &Positive {
      td {
        &:nth-child(2) {
          color: #4daa90;
        }
      }
    }
  }
  &Cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  tr {
    td {
      height: 4em;
    }
    td,
    th {
      &:not(:first-child) {
        justify-content: flex-end;
      }
    }
  }
}

.licensing {
  &Title {
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.2963em;
    &Wrap {
      display: flex;
      align-items: center;
      gap: 1em;
      padding: 1em 2em;
      border-radius: 0.5em 0.5em 0 0;
      background-color: #fff;
    }
    &Icon {
      svg {
        width: 1.5em;
        height: 1.5em;
        transform: scale(-1);
        path {
          fill: #5d6a7a;
        }
      }
    }
  }
}
