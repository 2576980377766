.setupMfa {
  &Wrap {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  &Title {
    font-size: 1.5em;
    line-height: 117%;
    font-weight: 600;
    text-align: center;
    position: relative;
  }

  &Back {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 1em;
      height: 1em;
    }
  }

  &Information {
    font-weight: 400;
    font-size: 0.875em;
    line-height: 140%;
    margin-bottom: 1.5em;
    text-align: center;
    color: #525a64;
  }

  &Button {
    width: 100%;
    margin-top: 1.5em;
  }

  &Options {
    padding-top: 0.7em;

    &Item {
      display: flex;
      align-items: center;
      text-align: left;
      width: 100%;
      border: 1px solid #7a97ba;
      outline: 1px solid transparent;
      outline-offset: -2px;
      border-radius: 0.25em;
      font-weight: 500;
      font-size: 1em;
      line-height: 112%;
      color: #525a64;
      margin-bottom: 0.75em;
      transition: all 0.2s ease 0s;
      position: relative;

      &:hover {
        border-color: #5175bd;
        background-color: #f8fafc;
      }

      &:active {
        border-color: #38558B;
        background-color: #fff;
      }

      &:focus {
        box-shadow: 0 0 0 3px #bcd6eb;
        background-color: #fff;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        color: #4169b8;
        border-color: #4169b8;
        outline-color: #4169b8;

        .setupMfaOptionsTooltipIcon svg path {
          fill: #4169b8;
        }

        label {
          >span {
            >svg {

              rect,
              path {
                stroke: #4169b8;
              }
            }
          }
        }
      }

      label {
        justify-content: space-between;
        flex: 1;
        padding: 0.875em 1em;

        >span {
          display: flex;
          align-items: center;
          position: relative;

          >svg {
            width: 1.5em;
            height: 1.5em;

            path,
            rect {
              transition: all 0.2s ease 0s;
            }
          }
        }
      }

      &Name {
        margin: 0 0.25em 0 0.75em;
      }
    }

    &Info {
      position: absolute;
      bottom: calc(100% - 0.875em);
      left: 1.5em;
      background: #4daa90;
      border-radius: 0.125em;
      color: #ffffff;
      font-weight: 500;
      font-size: 0.75em;
      line-height: 150%;
      padding: 0.167em 0.5em;
      display: flex;
      align-items: center;
      gap: 0.25em;
    }

    &Tooltip {
      max-width: 20em;
      font-weight: 400;
      font-size: 0.75em !important;
      line-height: 150%;
      color: #525a64 !important;
      padding: 1em !important;
      box-shadow: 0px 0.25em 0.5em rgba(59, 73, 92, 0.08), 0px 4px 32px rgba(59, 73, 92, 0.08) !important;
      border-radius: 0.25em !important;

      &Icon {
        width: 1.25em;
        height: 1.25em;

        svg {
          width: 1.25em;
          height: 1.25em;
        }
      }
    }
  }
}