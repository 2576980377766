@import "styles/vars";

.resetPass {
  min-height: 16.1em;
  position: relative;
  &Info {
    font-weight: 400;
    font-size: 0.875em;
    line-height: 140%;
    text-align: center;
    color: #525a64;
    margin-bottom: 1.5em;
  }
  &Support {
    font-size: 0.875em;
    line-height: 171%;
    text-align: center;
    color: #525a64;
  }
  &Proceed {
    width: 100%;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}
