@import "styles/vars";

.azureLoginHead {
  padding: 1em 2em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5em;

  &Wrap {
    display: flex;
    align-items: center;

    &Button {
      font-size: 0.8125em;
      color: $link2;

      svg path {
        fill: $link2;
      }

      &:hover {
        color: $link2-hover;

        svg path {
          fill: $link2-hover;
        }
      }

      &:active {
        color: $link2-active;

        svg path {
          fill: $link2-active;
        }
      }
    }
  }

  &Title {
    margin: 0 0 0 0.5em;
    font-weight: 500;
    font-size: 1em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
