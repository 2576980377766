@import "styles/vars";

.branding {
  background: #fff;
  border: 1px solid #e3e8ee;
  border-radius: 0.5em 0.5em 0 0;
  box-shadow: 0 1.5em 6.25em rgb(31 37 54 / 7%);
  color: #27313d;
  margin-bottom: 1.75em;
  min-height: calc(100% - 1.75em);
  position: relative;
  display: grid;
  :global {
    .react-tabs__tab-list.no-tabs {
      display: none;
    }
  }
  &Main {
    flex: 1;
    margin: 1.5em 2em 2em 3.5em;
    display: flex;
    justify-content: space-between;
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
      z-index: 1;
    }
  }
  &FooterDisabled {
    opacity: 0.3;
    pointer-events: none;
    z-index: 1;
  }
  &Header {
    margin-bottom: 1.5em;
  }
  &Title {
    padding: 1.3333333333333333em 1.3333333em 0.6666666666666666em;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.3333333333333333em;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.6666666666666666em;
    &.customer {
      padding-top: 1.25em;
    }
    span {
      font-size: 0.666em;
      font-weight: 500;
      color: #27313d;
    }
    svg {
      width: 1.5em;
      height: 1.5em;
      path {
        fill: #525a64;
      }
    }
  }
}
