.authentication {
  display: flex;
  align-items: center;
  gap: 1em;
  &Title {
    padding: 0 1em;
    color: #27313d;
    font-size: 1.125em;
    line-height: 1.3em;
    font-weight: 500;
  }
}
