.table {
  min-height: 17.5em;
  td {
    min-height: 4em;
  }
  &Row {
    &Baseline {
      td {
        align-items: baseline;
      }
    }
  }

  &Name {
    display: flex;
    align-items: center;
    gap: 0.75em;
  }
  &Group {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 0.5em;
  }
}
