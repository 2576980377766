.rows {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 13.125em;

  &Numbers {
    display: flex;
    align-items: center;
    margin: 0 2.5em 0 1em;
    font-weight: 500;

    &Item {
      min-width: 1em;
      text-align: center;
      position: relative;
      color: #848a92;
      transition: all 0.2s ease 0s;
      &Active {
        color: #27313d;
        pointer-events: none;

        &::after {
          content: "";
          position: absolute;
          bottom: -2px;
          left: 0;
          height: 2px;
          width: 100%;
          background: #4169b8;
        }
      }

      &:not(:last-child) {
        margin-right: 0.75em;
      }

      &:hover {
        color: #27313d;
      }
    }
  }
}
