@import "styles/vars";

.headerDropdown {
  &User {
    padding: 1em 0.875em 1em 1.25em;
    border-bottom: 1px solid $white2;
    margin-bottom: 0.5em;
    &Name {
      font-size: 1em;
      line-height: 1.54em;
      color: $gray5;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &Email {
      font-weight: 400;
      font-size: 0.75em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 1.2;
      color: $link2;
    }
  }

  &List {
    &Item {
      font-size: 1em;
      line-height: 1.24em;
      font-weight: 500;
      margin-bottom: 0.25em;
      &:last-child {
        margin-bottom: 0;
      }
      &Active {
        .headerDropdownListLink,
        .headerDropdownListButton {
          background: $white3;
          color: $primary;
          pointer-events: none;
          svg path {
            fill: $primary;
          }
        }
      }
    }
    &Link,
    &Button {
      display: flex;
      align-items: center;
      color: $gray5;
      padding: 0.625em 1.25em;
      gap: 0.75em;
      transition: all 0.2s ease 0s;
      svg {
        width: 1.25em;
        height: 1.25em;
        path {
          transition: all 0.2s ease 0s;
          fill: $gray5;
        }
      }
      &:hover {
        background: $white3;
        color: $primary;
        svg path {
          fill: $primary;
        }
      }
      &:active {
        background: $white3;
        color: $primary-active;
        svg path {
          fill: $primary-active;
        }
      }
    }
    &Button {
      width: 100%;
      height: 2.5em;
      font-size: 1em;
      line-height: 1.54em;
      font-weight: 500;
      border: none;
      > span {
        display: flex;
        align-items: center;
        gap: 0.75em;
      }
    }
  }
}
