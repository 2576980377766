@import "styles/vars";

.setup {
  &Link {
    padding: 1.5em 0 0.5em 2em;
    > div {
      display: flex;
      align-items: center;
      font-size: 0.875em;
    }
  }
  &Activate {
    padding: 0 0 1em 2em;
    > div {
      display: flex;
      align-items: center;
      font-size: 0.875em;
    }
  }
}

.activationCode {
  width: auto;

  svg path {
    fill: $primary;
  }

  button {
    &:hover {
      svg path {
        fill: $primary-hover;
      }
    }
  }

  > div {
    &:first-child {
      text-align: center;

      div {
        font-size: 1.213em;
        color: #27313d;
        font-weight: 500;
      }
    }
  }
}
