.authHeader {
  padding: 2em 0 1em;
  margin: 0 2em;
  border-bottom: 1px solid #e3e8ee;
  display: flex;
  justify-content: space-between;
  &Wrap {
    display: flex;
    align-items: center;
    gap: 1em;
  }
}

.authChart {
  padding: 2em;
}

.downloadButton {
  svg path {
    fill: #fff;
  }
}
