@import "styles/vars";

$hover-color: #dfe1e4;

.header {
  height: 4em;
  width: 100%;
  border-bottom: 1px solid $white2;
  background-color: $white3;
  padding: 1em 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;
  position: relative;
  &Row {
    display: flex;
    align-items: center;
    gap: 2em;
  }
  &Dropdown {
    position: absolute;
    width: 17em;
    top: 2.45em;
    right: -1.25em;
    background: $white;
    border: 1px solid $white2;
    box-shadow: 0px 4px 32px rgba(59, 73, 92, 0.08);
    border-radius: 0.5em;
    z-index: 3;
    cursor: default;
    padding-bottom: 0.5em;
    will-change: transform;

    &Transition {
      &Enter {
        opacity: 0;
        transform: scale(0.9);
        transform-origin: top right;
      }
      &EnterActive {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 200ms, transform 200ms;
      }
      &Exit {
        opacity: 1;
      }
      &ExitActive {
        opacity: 0;
        transform-origin: top right;
        transform: scale(0.9);
        transition: opacity 200ms, transform 200ms;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 2.2em;
      border-left: 1px solid $white2;
      border-top: 1px solid $white2;
      background-color: $white;
      width: 0.45em;
      height: 0.45em;
      transform: translate(50%, -60%) rotate(45deg);
    }
  }

  &Menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    &Photo {
      height: 2em;
      width: 2em;
      background-color: $white;
      border-radius: 50%;
      text-align: center;
      line-height: 2em;
      font-weight: 500;
      border: 2px solid transparent;
      transition: border-color 0.2s ease 0s;
      padding: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      &:hover {
        border-color: $hover-color;
      }

      img {
        border-radius: 50%;
        object-fit: cover;
        height: 2em;
        width: 2em;
      }
    }

    &Opened {
      .headerMenuPhoto {
        border-color: $gray8;
      }
    }
  }
}
