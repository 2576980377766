@import "styles/vars";

.link {
  font-size: 1em;
  color: $link;
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
  outline-color: #BCD6EB;

  svg path {
    fill: $link;
  }

  &Bold {
    font-weight: 600;
  }

  &:hover {
    color: $link-hover;

    svg path {
      fill: $link-hover;
    }
  }

  &:active {
    color: $link-active;

    svg path {
      fill: $link-active;
    }
  }
}