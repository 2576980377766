.devicesHeader {
  padding: 1em 2em 2em 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6.5em;
  background: #fff;
  z-index: 2;
  &Wrap {
    display: flex;
    align-items: center;
  }
  &Enrollment {
    margin-left: 1em;
    &Dropdown {
      > span {
        > span {
          display: inline-flex;
          align-items: center;
          gap: 0.5em;
        }
      }
      &Options {
        font-weight: 600;
      }
    }
  }
}
