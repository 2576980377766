@import "styles/vars";

.dropdown {
  display: inline-flex;
  align-items: center;
  font-size: 1em;
  position: relative;
  &Button {
    align-items: center;
    border: 1px solid #e3e8ee;
    border-radius: 0.5em;
    color: #27313d;
    cursor: pointer;
    display: flex;
    height: 2.55em;
    justify-content: space-between;
    padding: 0 2.25em 0 1em;
    position: relative;
    width: 20em;
    transition: all 0.2s ease 0s;
    &:hover {
      background-color: #f7f9fc;
    }
    &Active {
      background-color: #f7f9fc;
      border-color: #bcd6eb;
    }
    svg {
      fill: #606060;
      position: absolute;
      right: 0.5em;
    }
  }
  &Search {
    font-size: 1em;
    padding: 0.75em 1em;
    width: 100%;
    border: none;
    outline: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5em;
    padding: 0.58em 1em;
    &Title {
      color: $link2;
      font-size: 0.8125em;
    }
    &Select {
      font-weight: 500;
      font-size: 0.875em;
    }
  }
  &Options {
    background-color: #fff;
    border-radius: 0.5em;
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
    max-height: 14.25em;
    overflow-y: auto;
    padding: 0.5em 0;
    position: absolute;
    right: 0;
    top: 3em;
    width: 100%;
    z-index: 3;
    &Transition {
      &Enter {
        opacity: 0;
        transform: scale(0.9);
        transform-origin: top;
      }
      &EnterActive {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 200ms, transform 200ms;
      }
      &Exit {
        opacity: 1;
      }
      &ExitActive {
        opacity: 0;
        transform-origin: top;
        transform: scale(0.9);
        transition: opacity 200ms, transform 200ms;
      }
    }
  }
  &Checkbox {
    font-size: 0.875em;
    justify-content: space-between;
    padding: 0.8em 1em;
    transition: all 0.2s ease 0s;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:hover {
      background-color: #f7f7f7;
      color: #4169b8;
    }
  }
  &NoResultsFound {
    color: #525a64;
    font-style: italic;
    font-weight: 400;
    margin: 1.5em 0;
    pointer-events: none;
    text-align: center;
  }
}
