.link {
  &Title {
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 600;
    margin-bottom: 0.5em;
  }
  &Input {
    width: 100%;
    border: 1px solid #e3e8ee;
    background-color: #f7f9fc;
    color: #848a92;
    font-size: 1em;
    line-height: 1.5em;
    padding: 0.4375em 4.8em 0.4375em 0.75em;
    border-radius: 0.25em;
    white-space: nowrap;
    text-overflow: ellipsis;
    &Wrap {
      position: relative;
      margin-bottom: 1em;
    }
    &Actions {
      position: absolute;
      right: 0.75em;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      gap: 0.75em;
    }
  }
  &Link {
    font-weight: 500;
    font-size: 0.875em;
  }
}
