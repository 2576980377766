.shortcutExpiration {
  align-items: center;
  display: flex;
  position: relative;
  justify-content: flex-end;

  &Icon {
    height: 1.25em;
    margin-left: 0.25em;
    width: 1.25em;
  }

  &Tooltip {
    border-radius: 4px;
    bottom: -4em;
    box-shadow: 0 -1px 10px rgb(31 37 54 / 20%);
    font-size: 13px;
    font-weight: 400;
    opacity: 0;
    padding: 12px;
    position: absolute;
    right: -0.5em;
    transition: opacity 0.2s ease-out;
    width: -webkit-max-content;
    width: max-content;

    &::after {
      background: #fff;
      content: "";
      height: 12px;
      position: absolute;
      right: 0.75em;
      top: -6px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 12px;
    }

    &Active {
      opacity: 1;
    }
  }
}
