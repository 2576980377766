$white: #ffffff;
$white2: #e3e8ee;
$white3: #f7fafc;
$white4: #f7fafc;
$black: #000000;
$gray: #e0e0e0;
$gray2: #a9adb3;
$gray3: #f7f9fc;
$gray4: #121213;
$gray5: #27313d;
$gray6: #606060;
$gray7: #e3e8ee;
$gray8: #aeb4bc;
$red: #f3657b;
$green: #f3f9ec;
$green2: #8bc53f;
$green3: #edf7f4;
$green4: #4daa90;
$orange: #fa9903;
$blue: #1c99a2;

$table-border: #dddddd;

$orange: #fa9903;

$table-border: #dddddd;

$state-hover: #f1f3f7;
$state-active: #e3e6ea;

$primary: #4169b8;
$primary-hover: #5175bd;
$primary-active: #38558b;
$primary-disabled: #92a6ce;

$secondary: #3a4653;

$link: #4169b8;
$link-hover: #5175bd;
$link-active: #38558b;
$link-disabled: #92a6ce;

$link2: #525a64;
$link2-hover: #2f9bc2;
$link2-active: #2d86a7;
$link2-disabled: #a9adb3;

$link-icon: #5d6a7a;
$link-icon-hover: #2f9bc2;
$link-icon-active: #2d86a7;
$link-icon-disabled: #a9adb3;

$azure-primary: #9da6af;
$azure-hover: #4479b2;
$azure-green: #579300;

$border-radius: 0.5em;
