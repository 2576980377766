@import "styles/vars";

$gray-color: #848a92;
$border-color: #bcdaf0;

.dropdown {
  font-size: 1em;
  position: relative;

  i {
    border-radius: 50%;
    width: 1em;
    height: 1em;
    background-size: cover;
  }

  &Label {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 129%;
    color: #27313d;
    margin-bottom: 0.25em;
  }

  &Wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $gray5;
    overflow: hidden;
    padding: 0 0 0 0.75em;
    height: 2.75em;
    border: 1px solid #b2bccc;
    outline: 1px solid transparent;
    outline-offset: -2px;
    border-radius: 0.25em;
    transition: border-color 0.2s ease 0s, outline-color 0.2s ease 0s;
    border-radius: 0.25em;
    position: relative;
    background-color: transparent;
    transition: all 0.3s ease 0s;

    &.focus {
      border-color: #4169b8;
      outline-color: #4169b8;
    }

    &:hover {
      border-color: #5175bd;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      background-color: #fff;
    }

    &::-webkit-search-cancel-button {
      width: 1.5em;
      height: 1.5em;
    }

    &.disabled {
      background: #f7f9fc;
      border-color: #e3e8ee;
      color: #525a64;
      pointer-events: none;
    }

    &.error {
      border-color: #f3657b;
    }

    &Label {
      position: absolute;
      top: 0.45em;
      color: $primary;
      font-size: 0.75em;
      font-weight: 400;
    }
  }

  &Input {
    flex: 1 1;
    padding: 0;
    height: 100%;
    border: none;

    &:focus {
      outline: none;
    }
  }

  &Button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $gray5;
    padding: 0;
    position: relative;
    background-color: transparent;
    transition: all 0.3s ease 0s;

    &Label {
      position: absolute;
      top: 0.45em;
      color: $primary;
      font-size: 0.75em;
      font-weight: 400;
    }

    &Icon {
      display: block;
      margin-left: 0.5em;
      margin-right: 0.5em;
      padding-right: 0.5em;
      border-right: 1px solid #e3e8ee;
      height: 1.5em;
    }

    &Placeholder {
      color: #848a92;
      margin-right: 0.5em;
    }
  }

  &Options {
    position: absolute;
    top: auto;
    left: 0;
    padding: 1em 0;
    background-color: $white;
    box-shadow: 0px 1px 5px rgb(0 0 0 / 25%);
    border-radius: 0.25em;
    z-index: 3;
    width: 100%;
    font-size: 1em;
    overflow: hidden;
    will-change: transform;

    &Countries {
      max-height: 8.55em;
      overflow-y: auto;
    }

    &Transition {
      &Enter {
        opacity: 0;
        transform: scale(0.9);
        transform-origin: top left;
      }

      &EnterActive {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
      }

      &Exit {
        opacity: 1;
      }

      &ExitActive {
        opacity: 0;
        transform-origin: top left;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
      }
    }

    &InputWrap {
      position: sticky;
      top: 0;
      background-color: $white;
    }

    &Input {
      width: 100%;
      height: 2.86em;
      border: none;
      padding: 0.5em 1em;
      font-size: 1em;

      &NoResults {
        font-size: 1em;
        padding: 1em 0;
        text-align: center;
        color: #848a92;
      }

      &:focus {
        outline: none;
      }
    }

    &Item {
      width: 100%;
      padding: 0 1em;
      height: 2.85em;
      display: flex;
      align-items: center;
      gap: 0.5em;
      font-size: 1em;
      background-color: transparent;
      border: none;
      transition: all 0.3s ease 0s;

      &:hover {
        background-color: $gray3;
      }

      &.active {
        background-color: #f7fafb;
      }

      &Icon {
        margin-left: auto;
        line-height: 0.8;
        width: 1.143em;
        height: 1.143em;

        svg {
          width: 1.143em;
          height: 1.143em;
        }
      }

      &Text {
        display: flex;
        align-items: center;
        gap: 0.35em;
        text-align: left;

        >span {
          max-width: 12em;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &Code {
        color: $gray-color;
      }
    }
  }
}

:global {
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $gray-color;
  }
}