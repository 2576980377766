.assignments {
  &List {
    display: flex;
    flex-direction: column;
    &Item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1em 2em;
      border-bottom: 1px solid #e3e8ee;
      &:last-child {
        border-bottom: none;
      }
    }
    &Title {
      color: #27313d;
      font-size: 1em;
      font-weight: 600;
      margin-bottom: 0.25em;
    }
    &Description {
      color: #525a64;
      font-size: 0.875em;
    }
    &Actions {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
  }
  &ReportButton {
    font-size: 0.875em;
    padding: 0 1em 0 1em;
    border-radius: 0.5em;
    &.noReport {
      margin-right: 2em;
    }
    &.loading {
      padding: 0 1em 0 2em;
      color: #4169b8;
      opacity: 1;
    }
    svg {
      width: 1.15em;
      height: 1.15em;
      circle {
        stroke: #4169b8;
      }
    }
  }
}

.tooltip {
  box-shadow:
    0px 4px 8px rgb(59 73 92 / 8%),
    0px 4px 32px rgb(59 73 92 / 8%) !important;
  border: 1px solid #e3e8ee !important;
  &::after {
    margin-left: 4.2em !important;
  }
}
