.uploadLogo {
  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &Label {
    &Title {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 1.7142857142857142em;
      color: #525a64;
      margin-bottom: 0.25em;
    }
    &Info {
      font-size: 0.75em;
      line-height: 1.125em;
      color: #525a64;
    }
  }
  &Button {
    background: #ffffff;
    border: 1px solid #e3e8ee;
    border-radius: 0.25em;
    font-weight: 600;
    font-size: 0.8125em;
    color: #27313d;
    width: 9.62em;
    height: 2.625em;
    gap: 0.25em;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      .uploadLogoRemove {
        opacity: 1;
        visibility: visible;
      }
      &:not(&.uploaded) {
        background-color: #e3e8ee;
        border-color: #e3e8ee;
      }
    }
    &:active {
      &:not(&.uploaded) {
        background-color: #3a4653;
        border-color: #3a4653;
        color: #ffffff;
        svg path {
          fill: #fff;
        }
      }
    }
    svg {
      width: 1em;
      height: 1em;
    }
  }
  &Image {
    height: 2em;
    object-fit: contain;
    border-radius: 0.25em;
    max-height: calc(100% - 0.5em);
    max-width: calc(100% - 0.5em);
    position: absolute;
  }
  &Remove {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: -0.6875em;
    top: -0.6875em;
    svg {
      width: 1.375em;
      height: 1.375em;
      path {
        fill: #848a92;
      }
    }
  }
  &Input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}
