@import "styles/vars";

.modal {
  position: absolute;
  background: #fff;
  width: 30em;
  max-height: 95%;
  min-height: 10.5em;
  top: 50%;
  left: 18.75em;
  transform: translate(18.75em, -50%);
  border-radius: 0.5em;
  z-index: 20;
  &.short {
    min-height: 8.5em;
  }
  &Header {
    position: relative;
    text-align: center;
    padding: 1.5em 0;
    border-radius: 0.5em 0.5em 0 0;
    > span {
      font-size: 1.5em;
      line-height: 116.667%;
      font-weight: 600;
      color: #27313d;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &Info {
      font-size: 0.875em;
      line-height: 140%;
      margin-bottom: 0.5em;
    }
    &Link {
      font-size: 0.875em;
      font-weight: 600;
      line-height: 128.571%;
      text-decoration: underline;
    }
  }
}
