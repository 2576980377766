.smtWrapper {
  position: relative;
  height: 1.25em;
  width: 1.25em;
  margin: 0 0 0 0em;
  svg path {
    fill: #fa9903;
  }

  .tooltip {
    position: absolute;
    width: 1.25em;
    height: 1.25em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.smtHeadWrapper {
  position: relative;
  height: 1.25em;
  width: 1.25em;
  margin: 0 0 0 0.5em;

  .headTooltip {
    position: absolute;
    width: 1.25em;
    height: 1.25em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  svg path {
    fill: #4169b8;
  }
}

.tooltipWrapper {
  box-shadow: 0px 4px 8px rgb(59 73 92 / 8%);
  ul {
    padding-left: 0.8em;
    list-style: disc;
  }
  li {
    list-style: disc;
    &:first-child {
      color: #fa9903;
      &::marker {
        color: #fa9903;
      }
    }
  }
}
