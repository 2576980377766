.mfaSetupType {
  display: flex;
  flex-direction: column;
  &Label {
    font-size: 0.8125em;
    line-height: 138%;
    color: #525a64;
  }
  &Wrap {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    gap: 0.5em;
  }
  &Value {
    font-size: 0.8125em;
    color: #27313d;
    display: inline-flex;
    align-items: center;
    &.date {
      font-weight: 500;
    }
  }

  &Row {
    display: inline-flex;
    align-items: center;
    gap: 0.375em;
    > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    > strong {
      font-weight: 500;
    }
    svg {
      width: 1.23em;
      height: 1.23em;
      rect,
      path {
        stroke: #4169b8;
      }
    }
  }
}
