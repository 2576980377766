.controls {
  display: flex;
  flex-direction: column;
  align-items: center;

  &Title {
    font-size: 0.875em;
    color: #525a64;
    margin: 0 0 0.8571em;
  }

  &Warning {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5em;
    font-size: 0.875em;
    line-height: 1.5em;
    font-weight: 500;
    margin-top: 0.5em;
    margin-left: 4.5em;
    color: #fa9903;
  }

  &Wrap {
    display: flex;
  }

  &Item {
    text-align: center;

    &Title {
      font-weight: 500;
      font-size: 1.125em;
      margin: 0 0 0.4444em;
      color: #525a64;
    }

    &Wrap {
      display: flex;
      align-items: center;

      svg {
        cursor: pointer;
        padding: 0;
        height: 24px;
        width: 24px;

        &:first-child {
          margin-right: 0.5em;
        }
        &:last-child {
          margin-left: 0.5em;
        }

        &:hover:not(.disabled) {
          background: #f7f9fc;
        }
        &:active:not(.disabled) {
          background: #e3e5e8;
        }

        &.disabled {
          pointer-events: none;

          path {
            fill: #a9adb3;
          }
        }
      }

      &Input {
        width: 6em;
        padding: 0.4em;
        text-align: center;
        border: 2px solid #f7f9fc;
        background: #f7f9fc;
        border-radius: 0.5em;

        &Wrap {
          font-weight: 500;
          font-size: 1.25em;
        }

        &:focus {
          border-color: #bcd6eb;
          background-color: #fff;
          box-shadow: none;
        }

        &:disabled {
          color: #848a92;

          &:hover {
            box-shadow: none;
          }
        }

        &.warning,
        &.warning:focus {
          border-color: #fa9903;
        }
      }
    }

    &:first-child {
      margin-right: 8em;
    }
  }

  .primary-counter_inputs-warning {
    display: flex;
    align-items: center;
    margin-top: 0.5em;
    font-weight: 500;
    font-size: 0.875em;
    color: #fa9903;
    height: 1.4286em;

    svg {
      margin-right: 0.5em;
    }
  }
}
