.title {
  display: flex;
  align-items: center;
  gap: 0.5em;

  svg {
    width: 1em;
    height: 1em;

    path {
      fill: #f3657b;
    }
  }
}
