.azureLoginInfo {
  background: #f5f8fa;
  border: 1px solid #e3e8ee;
  border-radius: 0.5em;
  margin: 0 2em;
  padding: 1.5em;

  &Title {
    font-size: 1em;
    margin: 0 0 0.75em;
    font-weight: 500;
  }

  &Text {
    color: #525a64;
    font-size: 0.875em;
  }
}
