@import "styles/vars";

.checkbox {
  display: flex;
  align-items: center;
  gap: 0.75em;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    .checkboxWrapper {
      &.checked {
        background-color: #92a6ce;
        border-color: #92a6ce;
      }
      border-color: #cfd2d5;
    }
  }
  &.left {
    flex-direction: row-reverse;
  }
  &.circle {
    .checkboxWrapper {
      border-radius: 50%;
      background-color: #fff;
      position: relative;
      transition: all 0.2s ease 0s;
      border: 0.09375em solid #848a92;
      &::after {
        content: "";
        width: 0.520625em;
        height: 0.520625em;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        transition: all 0.2s ease 0s;
      }
      &.checked {
        border-color: #4169b8;
        &::after {
          background-color: #4169b8;
        }
      }
      &Icon {
        display: none;
      }
    }
  }
  &Block {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: transparent;
      width: 1em;
      height: 1em;
      transition: all 0.2s ease 0s;
      will-change: transform;
    }
    &:hover {
      &::after {
        width: 2.1em;
        height: 2.1em;
        background-color: #eaeaea;
      }
    }
  }
  &Label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &Input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: relative;
    width: 1.25em;
    height: 1.25em;
    border: 2px solid #848a92;
    border-radius: 0.25em;
    transition: all 150ms;
    z-index: 1;
    &.checked {
      background-color: $primary;
      border-color: $primary;
      .checkboxWrapperIcon {
        polyline {
          stroke-dashoffset: 0;
        }
      }
    }
    &.partial {
      background-color: $primary;
      border-color: $primary;
      .checkboxWrapperIcon {
        polyline {
          stroke-dashoffset: 0;
        }
      }
    }
    &Icon {
      fill: none;
      stroke: white;
      stroke-width: 2px;
      position: relative;
      z-index: 10;
      polyline {
        fill: none;
        stroke: #fff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 1.125em;
        stroke-dashoffset: 1.125em;
        transition: all 0.3s ease;
      }
    }
  }
}
