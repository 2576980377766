@import "styles/vars";

.onboarding {
  border: 0.0625em solid $white2;
  border-radius: 0.5em;
  color: #848a92;
  padding: 1.25em 1.5em 1.5em 1.5em;
  margin-bottom: 1em;
  &.active {
    border: 0.125em solid $green4;
    color: $gray5;
  }
  &.completed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7fafc;
    border-color: #fff;
    .onboardingTitle {
      margin-bottom: 0;
      h5,
      span {
        font-size: 1em;
        font-weight: 400;
      }
    }
  }
  &Title {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 1.25em;
    h5,
    span {
      font-size: 1.25em;
      font-weight: 500;
    }
  }
  &Action {
    margin-left: 1.1875em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    > button {
      font-weight: 600;
      font-size: 0.875em;
      &:last-of-type {
        svg path {
          fill: none;
          stroke: $link;
        }

        &:hover {
          svg path {
            fill: none;
            stroke: $link-hover;
          }
        }

        &:active {
          svg path {
            fill: none;
            stroke: $link-active;
          }
        }
      }
    }
  }
}
