@import "styles/vars";

.domainMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  &Title {
    font-size: 1.125em;
    margin: 0 0 1em 0;
    font-weight: 600;
  }
  &Info {
    font-size: 0.875em;
    color: $gray5;
    margin-bottom: 2em;
  }

  &Actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5em;
    &Link {
      display: flex;
      align-items: center;
      gap: 0.5em;
      border-radius: 0.5em;
      letter-spacing: 0.5px;
      padding: 0.5em 0.75em;
      font-weight: 600;
      font-size: 1em;
      background-color: $white;
      border: 1px solid $link;
      color: $link;

      svg path {
        fill: $link;
      }

      &:hover {
        background-color: $link-hover;
        border-color: $link-hover;
        color: $white;

        svg path {
          fill: $white;
        }
      }

      &:active {
        background-color: $link-active;
        color: $white;

        svg path {
          fill: $white;
        }
      }
    }
  }
}
