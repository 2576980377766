@import "styles/vars";

.dropdownWrapper {
  align-self: flex-start;
  width: 2em;

  &Dropdown {
    border: none;
    border-radius: 50%;
    height: 2.7em;
    padding: 0.5em;
    width: 2.7em;

    &:hover {
      background-color: #f1f3f7;
    }

    &.active {
			border-color: #bcd6eb;
			background-color: $gray3;
		}

    svg {
      left: 50% !important;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &Options {
      width: auto;
      > button {
        font-weight: 600;
        padding: 0.5714em 0.75em 0.5714em 1em;
        font-size: 0.875em;
      }
    }
  }
}
