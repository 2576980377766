.encryptTable {
  display: block;
  max-width: 100%;
  width: 100%;
  margin-bottom: 1.5em;
  &Thead {
    display: block;
    &Row {
      font-size: 0.875em;
      color: #525a64;
      border-bottom: 1px solid #e3e8ee;
      display: flex;
      width: 100%;
    }
    &Col {
      display: inline-flex;
      align-items: center;
      padding: 0.2857em;
      color: #577087;
      font-weight: 500;
      &:nth-child(1) {
        width: 6%;
      }
      &:nth-child(2) {
        width: 14%;
      }
      &:nth-child(3) {
        width: 30%;
      }
      &:nth-child(4) {
        width: 50%;
      }
    }
  }
  &Tbody {
    display: block;
    max-height: 29.5em;
    overflow: scroll;

    &Row {
      display: flex;
      width: 100%;
      color: #525a64;
      cursor: pointer;
      font-weight: 500;
      &:hover {
        background-color: #f7fafc;
      }
      &:first-child {
        .encryptTableTbodyCol {
          border: none;
        }
      }
      &:last-child {
        .encryptTableTbodyCol {
          border-bottom: 1px solid #e3e8ee;
        }
      }
      &Selected {
        background-color: #f7fafc;
      }
    }
    &Col {
      display: inline-flex;
      align-items: center;
      padding: 0;
      position: relative;
      border-top: 1px solid #e3e8ee;
      height: 3em;
      &Status {
        display: flex;
        align-items: center;
        gap: 0.5em;
        &Grey {
          color: #525a64;

          .encryptTableTbodyColStatusInfoIcon {
            path {
              fill: #525a64;
            }
          }
          :global {
            .lock-icon path {
              fill: #525a64;
            }
            .unlock-icon path {
              stroke: #525a64;
            }
          }
        }
        &Orange {
          color: #fa9903;

          .encryptTableTbodyColStatusInfoIcon {
            path {
              fill: #fa9903;
            }
          }
          :global {
            .lock-icon path {
              fill: #fa9903;
            }
            .unlock-icon path {
              stroke: #fa9903;
            }
          }
        }
        &Blue {
          color: #4169b8;
          .encryptTableTbodyColStatusInfoIcon {
            path {
              fill: #4169b8;
            }
          }
          :global {
            .lock-icon path {
              fill: #4169b8;
            }
            .unlock-icon path {
              stroke: #4169b8;
            }
          }
        }
      }
      &:nth-child(1) {
        justify-content: center;
        width: 6%;
        svg {
          cursor: pointer;
          width: 1.5em;
          height: 1.5em;
        }
      }
      &:nth-child(2) {
        width: 14%;
      }
      &:nth-child(3) {
        width: 30%;
      }
      &:nth-child(4) {
        width: 50%;
      }
    }
    &PassRow {
      width: 100%;
      display: flex;
      font-weight: 500;
      &:first-child {
        border-top: none;
      }
      td {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4em;
        padding: 0;
        width: 100%;
        border-top: 1px solid #e3e8ee;
      }
    }
  }
}
