.settings {
  background: #fff;
  border: 1px solid #e3e8ee;
  border-radius: 0.5em 0.5em 0 0;
  box-shadow: 0 1.5em 6.25em rgb(31 37 54 / 7%);
  color: #27313d;
  display: grid;
  margin-bottom: 1.75em;
  min-height: calc(100% - 1.75em);
  position: relative;
  .reactTabs {
    display: flex;
    background: #fff;
    min-height: calc(100% - 5.0625em);
    position: relative;
    &Wrapper {
      position: relative;
      width: 60%;
      background-color: #fff;
      padding: 1.5em 2em;
      box-shadow: 0 0.25em 0.5em rgba(59, 73, 92, 0.08), 0 0.25em 2em rgba(59, 73, 92, 0.08);
    }
    :global {
      .react-tabs__tab-list {
        width: 40%;
        display: flex;
        flex-direction: column;
        margin: 1.5em 0 0;
        border-bottom: unset;
        padding: 0;
        font-size: 0.875em;
        .react-tabs__tab {
          width: 11em;
          font-weight: 500;
          color: #525a64;
          border: none;
          padding: 0.5em 1em;
          border: none;
          border-left: 3px solid transparent;
          &.react-tabs__tab--selected {
            color: #4169b8;
            border-radius: unset;
            border-color: #4169b8;
            box-shadow: 0 0.25em 0.5em rgba(59, 73, 92, 0.08), 0 0.25em 2em rgba(59, 73, 92, 0.08) !important;
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
  &Message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25em 1em;
    border: 1px solid #fa9903;
    border-radius: 0.5em;
    margin-bottom: 1em;

    > div {
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.75em;
      }
    }

    > svg {
      width: 1.25em;
      height: 1.25em;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: #efefef;
      }
    }
  }
  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
    .settings_row-custom-inputs {
      display: flex;
      gap: 1.5em;
      width: 24em;
    }

    &Info {
      display: flex;
      flex-direction: column;
      padding-right: 0.75em;

      &Title {
        font-weight: 500;
        color: #27313d;
      }

      &Desc {
        font-weight: 400;
        font-size: 0.8125em;
        color: #525a64;
        margin-top: 0.25em;
      }
    }
  }
}
