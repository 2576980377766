.computers {
  padding: 1.5em 2em;
  &TotalInfo {
    margin-bottom: 1.5em;
  }
  &AuthenticationFilter {
    padding: 1em 0;
  }
  &Row {
    display: flex;
    gap: 1em;
    margin-top: 1em;
  }
  &Col {
    flex: 0 0 calc(50% - 0.5em);
    max-width: calc(50% - 0.5em);
  }
}
