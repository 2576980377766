@import "styles/vars";

.addDomain {
  display: flex;

  &Wrap {
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1em;
    }
  }
  &Input {
    border-radius: 0.5em;
    margin-right: 1em;
    padding: 0.5em 0.75em 0.5em 2.5em;
    border: 1px solid $white2;
    outline: none;
    width: 17.5em;
    font-weight: 400;
    height: 100%;
    transition: all 0.2s ease 0s;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    }
    &:focus {
      border-color: #bcd6eb;
    }
    &::placeholder {
      font-weight: 300;
      color: #848a92;
    }
  }
}
