.resetPass {
  min-height: 15.2em;
  position: relative;
  &Info {
    font-size: 0.875em;
    line-height: 140%;
    text-align: center;
    color: #525a64;
    margin-bottom: 1.5em;
    strong {
      color: #27313d;
      font-weight: 600;
    }
  }
  &Label {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 150%;
    margin-bottom: 0.75em;
    color: #27313d;
    text-align: center;
  }
  &Button {
    width: 19em;
    margin: 1.5em auto;
  }
  &Form {
    display: flex;
    flex-direction: column;
  }
  &ContactSupport {
    text-align: center;
    font-size: 0.875em;
    line-height: 140%;
    color: #525a64;
  }
  &Resend {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }
}
