.shortcutActions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: baseline;
  grid-gap: 1em;
  padding: 0.5em 2em 1em;

  &Checkbox {
    display: flex;
    align-items: center;
    &.mfa {
      align-items: baseline;
      flex-direction: column;
      justify-self: center;
      gap: 0.25em;
    }

    &Row {
      display: flex;
      align-items: center;
    }

    &Title {
      margin-right: 0.875em;
    }

    &SubTitle {
      font-size: 0.8125em;
      line-height: 138.462%;
      white-space: nowrap;
    }

    &Slide {
      font-size: 1.25em;
    }

    &Text {
      color: #848a92;
      font-weight: 700;
      margin-left: 0.25em;

      &.active {
        color: #8bc53f;
      }
      &.inactive {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
