.header {
  padding: 1em 2em;
  display: flex;
  align-items: center;

  &Total {
    color: #525a64;
    margin-right: 1.5em;
    font-weight: 400;
    line-height: 1.5em;

    &Counter {
      font-weight: 600;
      color: #27313d;
    }
  }
}
