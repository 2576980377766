@import "styles/vars";

.links {
  display: flex;
  align-items: center;
  gap: 1.75em;
  &Button {
    font-weight: 500;
    font-size: 0.8125em;
    line-height: 1.5em;
    transition: all 0.2s ease 0s;
    color: $link;
    svg path {
      fill: $link;
    }
    &:hover {
      color: $link-hover;
      svg path {
        fill: $link-hover;
      }
    }
    &:active,
    &.active {
      color: $link-active;
      svg path {
        fill: $link-active;
      }
    }
    span {
      display: flex;
      align-items: center;
    }
  }
  &Wrap {
    position: relative;
  }
  &Dropdown {
    position: absolute;
    top: calc(100% + 0.5em);
    left: 0;
    background: #ffffff;
    border-radius: 0.25em;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.246203);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    z-index: 1;
    &.opened {
      opacity: 1;
      visibility: visible;
    }
    &.bottom {
      top: initial;
      bottom: calc(100% + 0.5em);
    }
    &.header {
      right: 0;
      left: initial;
    }
    &List {
      padding: 0.375em 0;
      &.contact {
        width: 25.8em;
      }
      &.support {
        width: 15.375em;
        li {
          &:hover {
            background: #f7fafc;
            a span {
              color: #5175bd;
            }
            svg path {
              fill: #5175bd;
            }
          }
          &:active {
            a span {
              color: #38558b;
            }
            svg path {
              fill: #38558b;
            }
          }
        }
      }
      &.connect {
        width: 11.25em;
        li {
          &:hover {
            background: #f7fafc;
            a span {
              color: #5175bd;
            }
            svg path {
              fill: #5175bd;
            }
          }
          &:active {
            a span {
              color: #38558b;
            }
            svg path {
              fill: #38558b;
            }
          }
        }
      }
      li {
        font-weight: 400;
        font-size: 0.8125em;
        line-height: 1.5em;
        color: #27313d;
        display: flex;
        align-items: center;
        gap: 0.5em;
        padding: 0.375em 1em;
        transition: all 0.2s ease 0s;

        svg {
          width: 1em;
          height: 1em;
          flex: 0 0 1em;
        }
        span {
          &:first-child {
            font-weight: 500;
            white-space: nowrap;
          }
          &:last-child {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        a {
          color: #5d6a7a;
          span {
            color: #27313d;
            font-weight: 400 !important;
            transition: all 0.2s ease 0s;
          }
          svg path {
            fill: #5d6a7a;
            transition: all 0.2s ease 0s;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
