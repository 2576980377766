.noDevices {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  &Title {
    font-weight: 600;
    font-size: 1.125em;
  }

  &Subtitle {
    margin: 0.5em 0 2em;
  }
}

.devicesHeaderEnrollment {
  > span {
    > span {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
  }
  &Dropdown {
    font-weight: 600;
  }
}
