@import "styles/vars";

.licensesActions {
  padding: 2em 0 1em;
  margin: 0 2em;
  display: flex;
  justify-content: space-between;

  &Load {
    width: 2em;
    height: 2em;
    margin: 0 0.5em !important;
    visibility: hidden;

    &Active {
      visibility: visible;
    }
  }

  &Filter {
    display: flex;
    align-items: center;
  }
  &Buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    &Link {
      border: 1px solid rgba(39, 49, 61, 0.3);
      background-color: #fff;
      color: #27313d;
      border-radius: 0.5em;
      letter-spacing: 0.5px;
      padding: 0 0.75em;
      font-weight: 600;
      font-size: 0.875em;
      line-height: 1.1429em;
      padding: 0.5em 0.81em;
      height: 2.3em;
      color: #27313d;
      transition: all 0.2s ease 0s;
      margin-right: 1em;
      &:hover {
        color: #27313d;
        background-color: rgba(82, 90, 100, 0.05);
      }
      &:active {
        background: #3a4653;
        color: #fff;
      }
    }
    &Generate {
      font-size: 0.875em;
      width: 10.86em;
      padding: 0;
      svg {
        width: 1.15em;
        height: 1.15em;
        circle {
          stroke: $gray5;
        }
      }
    }
    &Download {
      > span {
        display: flex;
        width: 1.25em;
        height: 1.25em;
        svg {
          width: 1.25em;
          height: 1.25em;
        }
      }
    }
  }
}
