.layout {
  &Overlay {
    position: absolute;
    left: 18.75em;
    top: 4em;
    bottom: 0;
    width: calc(100% - 18.75em);
    height: calc(100% - 4em);
    z-index: 10;
    background-color: rgb(39 49 61 / 30%);
  }
  &Container {
    height: 100%;
    display: flex;
  }
  &Content {
    height: 100vh;
    width: calc(100% - 18.75em);
  }
  &Wrapper {
    height: calc(100% - 4em);
    overflow-y: auto;
    padding: 1em 1.25em 1.25em;
  }
  &Fallback {
    height: calc(100% - 1.75em);
    position: relative;
    background-color: #fff;
    border: 1px solid #e3e8ee;
    box-shadow: 0px 1.5em 6.25em rgb(31 37 54 / 7%);
    border-radius: 0.5em 0.5em 0 0;
  }
  &Copyright {
    position: fixed;
    bottom: 1.1em;
    right: 2em;
    font-size: 0.8125em;
    line-height: 1.5em;
    color: #5d6a7a;
    text-align: right;
    z-index: 2;
  }
}
