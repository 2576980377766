@import "styles/vars";

.alertAzure {
  margin-bottom: 0.8em;
  &Connect {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    color: $link;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    &:hover {
      color: $link-hover;
    }
    &:active {
      color: $link-active;
    }
  }
}
