@import "styles/vars";

.info {
  &Message {
    position: absolute;
    left: 6em;
    top: 0.15em;
    background: $white;
    box-shadow:
      0px 0.25em 0.5em rgba(59, 73, 92, 0.08),
      0px 0.25em 2em rgba(59, 73, 92, 0.08);
    border-radius: 0.25em;
    z-index: 1;
    width: fit-content;
    cursor: default;
    border: 1px solid #e3e8ee;

    &:after {
      content: "";
      width: 0.75em;
      height: 0.75em;
      transform: rotate(-45deg);
      background: $white;
      position: absolute;
      box-shadow: -0.125em -0.125em 0.25em rgba(59, 73, 92, 0.08);
      z-index: -1;
      left: -0.425em;
      top: 1.5em;
      border-top: 1px solid #e3e8ee;
      border-left: 1px solid #e3e8ee;
    }
    &Wrapper {
      max-height: 10.625em;
      overflow-y: auto;
      padding: 0.75em;
    }

    &List {
      li {
        font-size: 0.75em;
        color: #525a64;
        display: flex;
        align-items: center;
        white-space: nowrap;
        span {
          color: #fa9903;
          font-size: 0.8333333333333334em;
          margin-left: 0.4em;
        }
        &.head {
          color: #8bc53f;
          font-weight: 500;
          font-size: 0.75em;
          line-height: 1em;
          margin-bottom: 0.5em !important;
          &::before {
            content: "";
            width: 0.25em;
            height: 0.25em;
            background: #8bc53f;
            border-radius: 50%;
            display: block;
            margin-right: 0.25em;
          }
        }
        &.offline {
          color: #848a92;
          &::before {
            background: #848a92;
          }
        }
        &:not(:last-child) {
          margin-bottom: 0.25em;
        }
        svg {
          margin-right: 0.5em;
        }
      }
    }

    &Info {
      font-size: 0.75em;
      color: $gray5;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    &Head {
      display: block;
      padding: 0;
      border: none;
      font-size: 0.75em;
      font-weight: 500;
      margin-bottom: 1em;
      color: #27313d;
    }
  }
}
