.error {
  background: #fff;
  border: 1px solid #e3e8ee;
  box-shadow: 0px 1.5em 6.25em rgba(31, 37, 54, 0.07);
  border-radius: 0.5em 0.5em 0 0;
  color: #27313d;
  position: relative;
  margin-bottom: 1.75em;
  min-height: calc(100% - 1.75em);
  display: grid;
  &Wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &Title {
    font-weight: 600;
    font-size: 1.125em;
    line-height: 24px;
    color: #27313d;
    margin: 0 0 0.2em;
  }
  &Subtitle {
    font-weight: 400;
    font-size: 1em;
    color: #000000;
    margin: 0;
  }
}
