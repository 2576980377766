.appsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em 1.5em 2em;
  height: 4em;

  &Wrap {
    display: flex;
    align-items: center;
    &Dropdown {
      margin-left: 0.8571em;
    }
  }
}
