.rolesHeader {
  padding: 1em 2em;
  height: 4.5em;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 2;
  &.active {
    position: sticky;
    top: calc(-1em - 1px);
    transition: 0.2s ease-out;
    &.sticked {
      box-shadow: 0 0 0.25em rgb(129, 129, 129);
    }
  }
  &Wrap {
    display: flex;
    align-items: center;
    &.hidden {
      visibility: hidden;
      opacity: 0;
    }
  }
  &Search {
    margin-left: 1em;
    height: 2.5em;
  }
  &AllUsers {
    color: #525a64;
    margin-right: 0.25em;
  }
  &AllSearch {
    display: flex;
    align-items: center;
    &.hide {
      display: none;
    }
  }
  &Actions {
    display: flex;
    align-items: center;

    &.hide {
      display: none;
    }

    &Count {
      position: relative;
      font-weight: 500;
      padding-right: 1.5em;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 1px;
        background: #e3e8ee;
      }
    }
    &Btn {
      &Secondary {
        margin: 0 1.5em;
      }
      &Primary {
        width: 9em;
      }
    }
  }
}
