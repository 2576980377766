.headerActions {
  background: #fff;
  display: flex;
  align-items: baseline;
  padding: 0 2em 0;
  height: 3.25em;
  z-index: 2;

  &.active {
    position: sticky;
    top: calc(-1em - 1px);
    transition: 0.2s ease-out;

    &.sticked {
      height: auto;
      box-shadow: 0 0 0.25em #818181;
      padding: 1em 2em;
    }
  }

  &Button {
    padding: 0 2.25em;
  }

  &Count {
    font-weight: 500;
    padding-right: 1.5em;
    position: relative;

    &::after {
      background: #e3e8ee;
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
    }
  }

  &Wrap {
    display: flex;
    gap: 1.5em;
    margin-left: 1.5em;
  }
}
