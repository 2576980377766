.encrypts {
  position: relative;
  &TabBackdrop {
    z-index: 2;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffff7d;
  }
  &Table {
    tr {
      td {
        height: 4em;
        &:last-child {
          justify-content: flex-end;
        }
      }
      &:hover {
        td {
          &:last-child {
            .encryptsDetailsButton {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
    &RowUnlicensed {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  &Hostname {
    &Wrap {
      align-items: center;
      display: flex;
      gap: 0.5em;
    }
    &Online {
      path {
        fill: #8bc53f;
      }
    }
    &Name {
      font-weight: 500;
    }
    &AddInfo {
      color: #848a92;
      font-size: 0.75em;
      font-weight: 500;
      margin-left: 3.4167em;
    }
  }
  &Status {
    align-items: center;
    display: flex;
    font-weight: 500;
    gap: 0.5em;
    &LoadIcon {
      width: 1.5em;
      height: 1.5em;
      margin: 0 !important;
    }
    &Grey {
      color: #525a64;
      &StatusIcon {
        path {
          stroke: #525a64;
        }
      }
    }
    &Orange {
      color: #fa9903;
      svg path {
        fill: #fa9903;
      }
    }
    &Blue {
      color: #4169b8;
      svg path {
        fill: #4169b8;
      }
    }
    &Wrap {
      height: 1.25em;
      width: 1.25em;
      position: relative;
    }
    &Tooltip {
      height: 1.25em;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1.25em;
    }
  }
  &Action {
    align-items: center;
    color: #848a92;
    display: flex;
    font-weight: 700;
    gap: 0.5em;
    &Checked {
      color: #8bc53f;
    }
    &Disabled {
      color: #a9adb3;
      cursor: not-allowed;
    }
  }
  &Details {
    &Button {
      line-height: 1;
      padding: 0;
      opacity: 0;
      visibility: hidden;
    }
    &Loading {
      width: 1.5em;
      height: 1.5em;
      margin: 0;
    }
  }
}
