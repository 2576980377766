.downloads {
  position: absolute;
  bottom: 1.75em;
  right: 0;
  width: 16.75em;
  border: 1px solid #3d4252;
  border-radius: 0.5em;
  background: #1f2536;
  z-index: 2;
  &.inverted {
    border-color: #e3e8ee;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(135, 135, 135, 0.25);
    .downloads {
      &Title {
        color: #000;
      }
      &List {
        &Link {
          color: #000;
          &:hover {
            color: #4169b8;
            svg path {
              fill: #4169b8;
            }
            &.secureConnect {
              > span {
                svg {
                  path {
                    &:nth-child(1) {
                      stroke: #4169b8;
                      fill: none;
                    }
                    &:nth-child(2) {
                      fill: #4169b8;
                    }
                  }
                }
              }
            }
          }
          svg {
            path {
              fill: #000;
            }
          }
          &.secureConnect {
            > span {
              svg {
                path {
                  &:first-child {
                    fill: transparent;
                    stroke: #000;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &Overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: #ffffff1a;
  }
  &Title {
    color: #ffffff;
    font-weight: 500;
    font-size: 0.89em;
    line-height: 1.5em;
    padding: 1.5em 1em 1em;
    position: relative;
    z-index: 2;
  }
  &List {
    padding-bottom: 0.5em;
    position: relative;
    z-index: 2;
    &Item {
      font-size: 0.875em;
      font-weight: 500;
    }
    &Link {
      display: flex;
      align-items: center;
      padding: 0.75em 1em;
      color: #fff;
      transition: color 0.2s ease 0s;
      &.secureConnect {
        &:hover {
          > span {
            svg {
              path {
                &:nth-child(1) {
                  stroke: #2f9bc2;
                  fill: none;
                }
                &:nth-child(2) {
                  fill: #2f9bc2;
                }
              }
            }
          }
        }
      }
      &:hover {
        color: #2f9bc2;
        > svg {
          opacity: 1;
          visibility: visible;
          path {
            fill: #2f9bc2;
          }
        }
        > span {
          svg {
            path {
              fill: #2f9bc2;
            }
          }
        }
      }
      &:active {
        color: #2d86a7;
        outline: none;
        > svg {
          path {
            fill: #2d86a7;
          }
        }
        > span {
          svg {
            path {
              fill: #2d86a7;
            }
          }
        }
      }

      > svg {
        transition: all 0.2s ease 0s;
        opacity: 0;
        visibility: hidden;
        margin-left: 0.3em;
        path {
          fill: #2f9bc2;
        }
      }
      > span {
        display: flex;
        align-items: center;
        > svg {
          height: 1.6em;
          width: 1.6em;
          margin-right: 0.75em;
        }
      }
    }
    &Tooltip {
      color: #27313d !important;
      font-size: 0.825em !important;
      line-height: 1.5em !important;
      :global(.multi-line) {
        text-align: left !important;
        padding: 0 !important;
      }
    }
  }
}
