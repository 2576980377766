.input-wrapper {
  position: relative;

  .input-control {
    padding: 1.5em 3em 0.5em 1em;
    color: #27313d;
    border: 1px solid #a9adb3;
    border-radius: 0.5em;
    width: 100%;
    height: 3.5em;
    background: #f7f9fc;
    outline: none !important;
    -webkit-text-security: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;

    &.pass {
      padding: 1.5em 6em 0.5em 1em;
    }

    &.pass-hidden {
      -webkit-text-security: disc;
    }

    &:focus:not(:read-only) {
      border-color: #bcdaf0;

      + .floater:before,
      &:not(:placeholder-shown) + .floater:before {
        color: #4169b8;
      }
    }

    &:read-only {
      border: none;
      background: #fff;

      &:focus + .floater:before,
      &:not(:placeholder-shown) + .floater:before {
        color: #525a64;
      }
    }

    &.error {
      border-color: #f3657b !important;

      &:focus + .floater:before,
      &:not(:placeholder-shown) + .floater:before {
        color: #f3657b;
      }
    }

    &.disabled {
      background: #f7f9fc;
      border: 1px solid #a9adb3;
      pointer-events: none;
      opacity: 0.6;

      &:focus + .floater:before,
      &:not(:placeholder-shown) + .floater:before {
        color: #a9adb3;
      }
      &:not(.touched) {
        + .floater:before {
          color: #a9adb3;
        }
      }
    }

    &:not(.touched) {
      + .floater:before {
        display: block;
        position: absolute;
        top: 50%;
        left: 1em;
        transform: translateY(-50%);
        color: #525a64;
        pointer-events: none;
        transition: 0.25s all ease-in-out;
      }
    }

    &.touched {
      + .floater:before {
        position: absolute;
        top: 1em;
        left: 1.35em;
        font-size: 0.75em;
        color: #525a64;
      }
    }

    &:focus + .floater:before,
    &:not(:placeholder-shown) + .floater:before {
      position: absolute;
      top: 1.25em;
      left: 1.35em;
      font-size: 0.75em;
      color: #4169b8;
    }
  }

  #first-name + .floater:before,
  #first-name:focus + .floater:before {
    content: "First Name";
  }

  #last-name + .floater:before,
  #last-name:focus + .floater:before {
    content: "Last Name";
  }

  #enter-your-login-email-address + .floater:before,
  #enter-your-login-email-address:focus + .floater:before {
    content: "Enter your login email address";
  }

  #enter-six-digit-code + .floater:before,
  #enter-six-digit-code:focus + .floater:before {
    content: "Click here to type MFA code or get MFA Push";
  }

  #enter-your-password + .floater:before,
  #enter-your-password:focus + .floater:before {
    content: "Enter your password";
  }

  #enter-temporary-password + .floater:before,
  #enter-temporary-password:focus + .floater:before {
    content: "Enter Current Password";
  }

  #enter-new-password + .floater:before,
  #enter-new-password:focus + .floater:before {
    content: "Enter New Password";
  }

  #confirm-new-password + .floater:before,
  #confirm-new-password:focus + .floater:before {
    content: "Confirm New Password";
  }

  #encrypt-pass + .floater:before,
  #encrypt-pass:focus + .floater:before {
    content: "Encryption password";
  }

  #recovery-key + .floater:before,
  #recovery-key:focus + .floater:before {
    content: "Recovery Key";
  }

  #phone-number + .floater:before,
  #phone-number:focus + .floater:before {
    content: "Phone Number";
  }

  #enter-your-username + .floater:before,
  #enter-your-username:focus + .floater:before {
    content: "Enter your username";
  }

  #contactText + .floater:before,
  #contactText:focus + .floater:before {
    content: "Contact information";
  }

  #phone + .floater:before,
  #phone:focus + .floater:before {
    content: "Phone number";
  }

  #email + .floater:before,
  #email:focus + .floater:before {
    content: "Email address";
  }

  #publicWebsite + .floater:before,
  #publicWebsite:focus + .floater:before {
    content: "Website";
  }

  #address1 + .floater:before,
  #address1:focus + .floater:before {
    content: "Address";
  }

  #city1 + .floater:before,
  #city1:focus + .floater:before {
    content: "City";
  }

  #state1 + .floater:before,
  #state1:focus + .floater:before {
    content: "State";
  }

  #zipCode1 + .floater:before,
  #zipCode1:focus + .floater:before {
    content: "Zip code";
  }

  #country1 + .floater:before,
  #country1:focus + .floater:before {
    content: "Country";
  }

  .input-actions {
    position: absolute;
    top: 1em;
    right: 1em;

    .icon-wrapper {
      border-radius: 1.5em;
      cursor: pointer;
      height: 3em;
      display: flex;
      align-items: center;
      width: 3em;
      justify-content: center;

      &:hover {
        background-color: #f1f3f7;
      }
      &:active {
        background-color: #e5e6ea;
      }
    }

    svg {
      cursor: pointer;
      width: 1.5em;
      height: 1.5em;

      path {
        fill: #798491;
      }
      &:last-child {
        margin-left: 0.75em;
      }

      &:hover path {
        fill: #525a64;
      }
    }
  }

  .input-error {
    position: absolute;
    bottom: -1.75em;
    font-size: 0.75em;
    font-weight: 400;
    color: #f3657b;
    width: max-content;
    left: 1.5em;
  }
}
