.modal {
  &Header {
    position: sticky;
    top: 4.456875em;
    background-color: #fff;
    z-index: 10;
  }
  &Info {
    display: flex;
    gap: 0.5em;
    span {
      font-size: 0.875em;
    }
    svg {
      flex: 0 0 1em;
      width: 1em;
      height: 1.375em;
      path {
        fill: #f3657b;
      }
    }
  }
  &SelectAll {
    border-bottom: 1px solid #e4e5e6;
    padding: 1.5em 0.5em 0.75em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &Wrap {
      display: flex;
      align-items: center;
      gap: 1em;
    }
  }
  &ListCount {
    font-size: 0.875em;
    line-height: 1.125em;
    font-weight: 400;
    color: #525a64;
  }
  &List {
    &Item {
      padding: 0.75em 0.5em;
      &.checked {
        background-color: #f7fafc;
      }
      &.customer {
        padding-left: 3em;
      }
      &Wrapper {
        display: flex;
        align-items: center;
        width: 100%;
      }
      &Wrap {
        padding: 0 1em;
        display: flex;
        flex-direction: column;
        gap: 0;
      }
      &Name {
        font-size: 1em;
        line-height: 1.5em;
        color: #27313d;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 0.25em;
        &.offline {
          color: #848a92;
        }
      }
      &TooltipVersion {
        font-size: 0.85714em;
        line-height: 1.5em;
      }
      &Version {
        font-weight: 400;
        b {
          font-weight: 500;
        }
      }
      &Response {
        font-weight: 400;
        b {
          font-weight: 500;
        }
      }
      &Status {
        line-height: 1em;
        color: #8bc53f;
        font-size: 0.75em;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 0.25em;
        &.offline {
          color: #848a92;
        }
      }
      &Dot {
        display: block;
        width: 0.25em;
        height: 0.25em;
        border-radius: 100%;
        background-color: #8bc53f;
        &.offline {
          background-color: #848a92;
        }
      }
      &Update {
        margin-left: auto;
        color: #fa9903;
        display: flex;
        align-items: center;
        gap: 0.25em;
        position: relative;
        &.offline {
          span {
            color: #848a92;
          }
        }
        span {
          font-weight: 600;
          font-size: 0.75em;
        }
        svg {
          width: 1.25em;
          height: 1.25em;
          cursor: pointer;

          path {
            transition: all 0.2s ease 0s;
          }

          &:hover {
            path {
              &:nth-child(1) {
                fill: #525a64;
              }

              &:nth-child(2) {
                fill: #fff;
              }

              &:nth-child(3) {
                fill: #fff;
              }
            }
          }
        }
      }
      &Tooltip {
        box-shadow: 0px 4px 32px 0px #3b495c14 !important;
        border: 1px solid #e3e8ee !important;

        &::after {
          border-right: 1px solid #e3e8ee !important;
          border-top: 1px solid #e3e8ee !important;
          bottom: -0.34em !important;
        }

        &Content {
          font-size: 0.85714em;
          line-height: 1.5em;
          white-space: nowrap;
          display: flex;
          align-items: center;
          > div {
            font-weight: 500;
            &:last-child {
              color: #f3657b;
            }
          }
        }
        &Wrap {
          width: 1.25em;
          height: 1.25em;
          &.failed {
            svg {
              path {
                &:nth-child(1) {
                  stroke: #f3657b;
                }

                &:nth-child(2) {
                  fill: #f3657b;
                }

                &:nth-child(3) {
                  fill: #f3657b;
                }
              }
              &:hover {
                path {
                  &:nth-child(1) {
                    fill: #f3657b;
                  }

                  &:nth-child(2) {
                    fill: #fff;
                  }

                  &:nth-child(3) {
                    fill: #fff;
                  }
                }
              }
            }
          }

          svg {
            width: 1.25em;
            height: 1.25em;

            path {
              transition: all 0.2s ease 0s;
            }

            &:hover {
              path {
                &:nth-child(1) {
                  fill: #525a64;
                }

                &:nth-child(2) {
                  fill: #fff;
                }

                &:nth-child(3) {
                  fill: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.toast {
  &Title {
    font-size: 1em;
    font-weight: 500;
  }
  &Message {
    font-weight: 400;
    font-size: 0.9285714285714286em;
  }
}
