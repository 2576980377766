.myProfile {
  background: #fff;
  position: relative;
  border: 1px solid #e3e8ee;
  box-shadow: 0px 1.5em 6.25em rgba(31, 37, 54, 0.07);
  border-radius: 0.5em 0.5em 0 0;
  margin-bottom: 1.75em;
  min-height: calc(100% - 1.75em);
  display: grid;

  &Title {
    font-weight: 600;
    font-size: 1.5em;
    padding: 1em 1.3333em 0 1.3333em;
    background-color: #fff;
    border-radius: 0.5em 0.5em 0 0;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #e3e8ee;
  }

  &NoTabs {
    display: none;
  }
}
