.validation {
  &Button {
    width: 100%;
  }

  &Info {
    margin-bottom: 1.5em;
    font-size: 0.875em;
    line-height: 140%;
    color: #525A64;
    text-align: center;

    strong {
      color: #27313D;
      font-weight: 600;
    }
  }

  &Wrap {
    text-align: center;

    button {
      font-size: 0.875em;
      line-height: 171%;
      font-weight: 600;
      margin-top: 1em;
    }
  }
}