.numbers {
  display: flex;
  align-items: center;

  &Item {
    min-width: 1em;
    text-align: center;
    cursor: pointer;
    position: relative;
    color: #848a92;
    font-weight: 600;

    &:not(:last-child) {
      margin-right: 0.75em;
    }

    &:hover {
      color: #27313d;
    }

    &Active {
      color: #27313d;
      pointer-events: none;

      &:after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        height: 2px;
        width: 100%;
        background: #4169b8;
      }
    }

    &Dots,
    &Dots:hover {
      color: #848a92;
      pointer-events: none;
    }
  }
}
