@import "styles/vars";

.noResults {
  display: flex;
  height: 16.05em;
  justify-content: center;
  color: #525a64;
  font-style: italic;
  font-weight: 400;
  pointer-events: none;
  text-align: center;
  margin-top: 4em;
}

.list {
  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5em 0 1em;
  }
  &Wrap {
    margin: 0 -2em -1.5em -2em;
    height: 17.55em;
    overflow-y: auto;
  }
  &SelectCountries {
    font-size: 0.8125em;
    color: $link2;
  }
  &SelectAllBtn {
    font-size: 0.875em;
  }
  &Item {
    color: $gray5;
    &New,
    &:hover {
      background-color: $gray3;
    }
  }
  &Checkbox {
    justify-content: space-between;
    padding: 0.6em 2em;
  }
}
