.admin {
  display: flex;

  .adminText {
    color: #4169b8;
    font-weight: 700;
    font-size: 0.875em;
    padding: 0 0.4em 0.3em 0;
  }
}

.user {
  color: #848a92;
  font-weight: 700;
  font-size: 0.875em;
  padding: 0.3em 0;
}
