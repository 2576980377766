.shortcut {
  &Header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5em;
    gap: 1em;
    border-radius: 0.5em;
    border: 1px solid #e3e8ee;
    background: #f5f8fa;
    margin: 0 2em;

    &Title {
      color: #27313d;
      font-size: 1em;
      font-weight: 500;
      line-height: 1.5rem;
    }

    &Desc {
      color: #27313d;
      font-size: 0.875em;
      line-height: 1.5rem;
    }
  }
}
