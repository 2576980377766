.background {
  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &Label {
    &Title {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 1.7142857142857142em;
      color: #525a64;
      margin-bottom: 0.25em;
    }
    &Info {
      font-size: 0.75em;
      line-height: 1.125em;
      color: #525a64;
    }
  }
  &Wrap {
    position: relative;
    &:hover {
      .backgroundClear {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &Button {
    background: #ffffff;
    border: 1px solid #e3e8ee;
    transition: all 0.2s ease 0s;
    border-radius: 0.25em;
    font-size: 0.8125em;
    width: 9.62em;
    height: 2.625em;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.error {
      border-color: #f3657b;
    }
    &:hover {
      border-color: #bcd6eb;
    }
    &Color {
      display: block;
      width: 2.625em;
      flex: 0 0 2.625em;
      height: 100%;
      border-radius: 0.2em 0 0 0.2em;
      border-right: 1px solid #e3e8ee;
      &Wrap {
        display: flex;
        align-items: center;
        padding-left: 1em;
      }
      &Input {
        width: 100%;
        border: none;
        height: 100%;
        outline: none;
        font-size: 1em;
        border-radius: 0.2em;
        padding: 0.5em 0;
        &::placeholder {
          color: #000000;
        }
        &:focus {
          &::placeholder {
            opacity: 0;
          }
        }
      }
    }
    &Hex {
      font-weight: 400;
      font-size: 0.8125em;
      color: #000000;
      text-align: center;
      display: block;
      flex: 1;
    }
  }
  &Clear {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: -0.6875em;
    top: -0.6875em;
    font-size: 0.8125em;
    svg {
      width: 1.375em;
      height: 1.375em;
      path {
        fill: #848a92;
      }
    }
  }
  &Input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}
