.table {
  min-height: 17.5em;
  td {
    height: 4em;
  }
  &Status {
    font-weight: 500;
    color: #4daa90;
    &.failed {
      color: #f3657b;
    }
  }
}
