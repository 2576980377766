.item {
  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    padding: 0.75em 0.5em;
    &.checked {
      background-color: #f7fafc;
    }
  }
  &Wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
  }
  &Name {
    display: flex;
    align-items: center;
    gap: 0.625em;
    padding: 0;
    &Icon {
      transform: rotate(-90deg);
      transition: all 0.2s ease 0s;
      &.open {
        transform: rotate(0);
        path {
          fill: #38558b;
        }
      }
    }
    span {
      font-size: 1em;
      line-height: 1.5em;
      color: #27313d;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 0.25em;
      &.open {
        color: #38558b;
      }
    }
  }
  &Count {
    color: #27313d;
    font-size: 1em;
    &.open {
      color: #38558b;
    }
  }
}
