@import "styles/vars";

.alertNoSentryInstalled {
  padding: 1.3em 2em 1em;
  &Wrap {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &Buttons {
    display: flex;
    align-items: center;
    gap: 0.75em;
  }
  &Input {
    text-align: center;
    margin-left: 1.5em;
    font-weight: 500;
  }
  &ButtonIcon {
    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
  &Button {
    align-items: center;
    color: $primary;
    display: inline-flex;
    font-weight: 600;
    gap: 0.3em;
    padding: 0;
    border: 0;
    background: none;
    &:hover {
      color: $primary-hover;
      svg path {
        fill: $primary-hover;
      }
    }
    &:active {
      color: $primary-active;
      svg path {
        fill: $primary-active;
      }
    }
  }
}
