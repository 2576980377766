.header {
  padding: 1.25em 1.75em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0.5em 0.5em 0 0;

  &Left {
    width: 60%;
    display: flex;
    align-items: center;

    &BackBtn {
      font-weight: 500;
      margin-right: 1.5em;
      svg path {
        fill: #525a64;
      }
    }

    &Title {
      font-weight: 600;
      font-size: 1.5em;
      color: #27313d;
      margin-right: 0.6667em;
    }

    &Dropdown {
      font-weight: 500;
      width: 18.25em;
      &Options {
        font-weight: 500;
        > button {
          padding-right: 2.4em;
        }
      }
    }
  }

  &Right {
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.5em;

    &Dropdown {
      &Options {
        font-weight: 600;
      }
    }
  }
}
