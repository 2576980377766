.idle {
  display: flex;
  align-items: center;
  font-size: 0.875em;
  height: 4em;

  b {
    color: #fa9903;
  }

  svg {
    margin-right: 0.75em;
  }
}
