.refresh {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &Wrap {
    &Button {
      margin-right: 1em;
      svg path {
        transition: all 0.2s ease 0s;
      }
      &.isLoading {
        background-color: #38558b;
        border-color: #38558b;
        color: #ffffff;
        opacity: 1;
        pointer-events: none;

        svg {
          animation: spin 2s linear infinite;
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  &Status {
    cursor: pointer;
    &Online path {
      fill: #8bc53f;
    }
    &Offline path {
      fill: #fa9903;
    }
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
