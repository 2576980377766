.table {
  tr {
    height: 4em;
  }

  &Name {
    font-weight: 500;
    font-size: 1em;
    color: #27313d;
    padding-right: 4em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Type {
    font-weight: 500;
    font-size: 0.875em;
    &.inherit {
      color: #848a92;
    }
    &.default {
      color: #4169b8;
    }
    &.custom {
      color: #4daa90;
    }
  }

  &Button {
    visibility: hidden;
    transition: 0.2s transform cubic-bezier(0.1, 1.26, 0.83, 1) 0s;
    transform: scale(0.95);
    will-change: transform;
    text-align: right;
    margin-left: auto;
    svg {
      scale: (-1);
      path {
        fill: #fff;
      }
    }
  }

  tbody {
    tr {
      &:hover {
        td {
          &:nth-child(4) {
            .tableButton {
              visibility: visible;
              transform: scale(1);
            }
          }
        }
      }
    }
  }

  &Status {
    align-items: center;
    display: flex;
    font-weight: 600;
    gap: 0.25em;

    &Enabled {
      color: #8bc53f;
      font-size: 0.875em;
    }

    &Disabled {
      color: #848a92;
      font-size: 0.875em;
    }
  }
}
