.setupMfa {
  &Info {
    font-size: 0.875em;
    line-height: 140%;
    text-align: center;
    color: #525a64;
    margin-bottom: 1.7143em;

    strong {
      color: #27313d;
      font-weight: 600;
    }
  }

  &Button {
    width: 100%;
    margin-top: 1.5em;
  }

  &Input {
    width: 2.75em;
    height: 2.75em;
    border: 1px solid #7a97ba;
    outline: 1px solid transparent;
    outline-offset: -2px;
    border-radius: 0.25em;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    color: #27313d;
    text-align: center;
    transition: all 0.2s ease 0s;

    &:hover {
      border-color: #5175bd;
    }

    &:focus {
      border-color: #4169b8;
      outline-color: #4169b8;
    }

    &.error {
      border-color: #f3657b;
    }

    &Title {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 150%;
      color: #27313d;
      text-align: center;
      margin-bottom: 0.75em;
    }

    &Wrap {
      display: flex;
      justify-content: center;
      gap: 0.5em;
    }

    &Error {
      text-align: center;
      font-size: 0.75em;
      line-height: 133%;
      margin-top: 0.25em;
      color: #f3657b;
    }
  }
}