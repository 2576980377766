@import "styles/vars";

.input {
  width: 100%;
  height: 3.25em;
  background: #ffffff;
  border: 1px solid #b2bccc;
  outline: 1px solid transparent;
  color: #27313d;
  outline-offset: -2px;
  transition: border-color 0.2s ease 0s, outline-color 0.2s ease 0s;
  border-radius: 0.25em;
  padding: 1.5em 3em 0.5em 0.875em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.hasValue {
    border-color: #7a97ba;
  }
  &:hover:not(&.error) {
    border-color: #5175bd;
    + .inputPlaceholder {
      color: #5175bd;
    }
  }
  &:active,
  &:focus {
    border-color: #4169b8;
    outline-color: #4169b8;
  }
  &.viewAction {
    padding: 1.5em 2.4em 0.5em 0.875em;
  }
  &.password {
    padding: 1.5em 4.7em 0.5em 0.875em;
  }
  &.error {
    border-color: $red;
    outline-color: $red;
  }

  &.disabled {
    background: #f7f9fc;
    border-color: #e3e8ee;
    color: #848a92;
    pointer-events: none;
  }

  &Wrapper {
    position: relative;
  }

  &Container {
    position: relative;
  }

  &Placeholder {
    display: block;
    position: absolute;
    pointer-events: none;
    color: #525a64;
    font-size: 1em;
    left: 0.75em;
    top: 1em;
    backface-visibility: hidden;
    transition: 0.25s all ease-in-out;

    &.active {
      top: 0.5em;
      left: 1.1666666666666667em;
      color: $primary;
      font-size: 0.75em;
    }

    &.disabled {
      color: #a9adb3;
    }

    &.error {
      color: $red;
    }
  }

  &ErrorMessage {
    font-size: 0.75em;
    font-weight: 400;
    color: $red;
    width: max-content;
    margin-top: 0.25em;
    margin-left: 1.1666666667em;
  }

  &Actions {
    display: flex;
    align-items: center;
    position: absolute;
    gap: 0.5em;
    right: 0.75em;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5em;
    height: 1.5em;

    &.showPassIcon {
      width: 3.5em;
    }
  }

  &Clear,
  &ShowPass {
    &:hover {
      svg path {
        fill: #27313d;
      }
    }
    &:active {
      svg path {
        fill: #38558b;
      }
    }
    svg {
      width: 1.5em;
      height: 1.5em;
      path {
        transition: all 0.2s ease 0s;
        fill: #848a92;
      }
    }
  }
}
