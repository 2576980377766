@import "styles/vars";

.shortcut {
  height: 100%;
}

.infoTooltip {
  padding: 0.6em !important;
  box-shadow:
    0px 4px 8px rgb(59 73 92 / 8%),
    0px 4px 32px rgb(59 73 92 / 8%) !important;
  border: 1px solid #e3e8ee !important;
  &::after {
    margin-left: 8.9em !important;
  }
}
