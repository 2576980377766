.setup {
  &Info {
    font-size: 0.875em;
    line-height: 140%;
    text-align: center;
    color: #525a64;
    margin-bottom: 1.5em;
  }
  &Button {
    margin-top: 1.5em;
    margin-bottom: 1em;
    width: 100%;
  }
  &Link {
    display: block;
    font-weight: 600;
    font-size: 0.875em;
    line-height: 171%;
    margin: 0 auto;
  }
  &Label {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 150%;
    color: #27313d;
    text-align: center;
    margin-bottom: 0.75em;
  }
  &Resend {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }
}
