button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.f12 {
  font-size: 0.75em;
}

.f14 {
  font-size: 0.875em;
}

.__react_component_tooltip {
  padding: 12px !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  color: #27313d !important;
  font-weight: 400;
  box-shadow: 0 10px 10px rgba(31, 37, 54, 0.2);

  &.place-top {
    box-shadow: 0 -5px 10px rgba(31, 37, 54, 0.2);
  }
}

.react-tabs__tab--disabled {
  pointer-events: none;
}

.disabled {
  cursor: not-allowed;
}

.no-results-message {
  text-align: center;
  font-style: italic;
  color: #525a64;
  pointer-events: none;
  font-weight: 400;
  margin: 1.5em 0;
}

input[type="search"] {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button {
  width: 1em;
  height: 1em;
  cursor: pointer;
  margin: 0;
  background: url("../assets/img/close-cross.svg") no-repeat;
  border-radius: 50%;
  background-size: contain;

  &:hover {
    background-color: #efefef;
  }
}

.truncate-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
  /* Change the color to your own background color */
  -webkit-text-fill-color: #27313d;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  /*your box-shadow*/
  -webkit-text-fill-color: #27313d;
}
