.setupMfa {
  &Title {
    font-weight: 600;
    font-size: 1.5em;
    line-height: 117%;
    color: #27313d;
    margin-bottom: 1.5em;
    text-align: center;
  }

  &QrCode {
    display: block;
    width: 11.25em;
    height: 11.25em;
    margin: 0.75em auto 1em;
  }

  &Info {
    font-size: 0.875em;
    line-height: 140%;
    text-align: justify;
    color: #525a64;

    strong {
      color: #27313d;
      font-weight: 600;
    }
  }

  &InputTitle {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 150%;
    color: #27313d;
    text-align: center;
    margin-bottom: 0.75em;
  }
}