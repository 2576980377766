@import "styles/vars";

.table {
  tr {
    height: 4em;
    &:hover {
      .tableButton {
        visibility: visible;
        transform: scale(1);
      }
    }
    td {
      &:last-child {
        justify-content: flex-end;
      }
    }
  }
  &Button {
    visibility: hidden;
    transition: 0.2s transform cubic-bezier(0.1, 1.26, 0.83, 1) 0s;
    transform: scale(0.8);
    will-change: transform;
    font-size: 0.8em;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    svg path {
      fill: $red;
    }
    &:hover {
      background-color: $state-hover;
      svg path {
        fill: $red;
      }
    }
    &:active {
      background-color: $state-active;
      svg path {
        fill: $red;
      }
    }
  }
}

.deleteTooltip {
  padding: 0.6em !important;
  box-shadow:
    0px 4px 8px rgb(59 73 92 / 8%),
    0px 4px 32px rgb(59 73 92 / 8%) !important;
  &::before {
    display: none !important;
  }
  &::after {
    margin-left: 42px !important;
  }
}
