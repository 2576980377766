@import "styles/vars";

.timeout {
  &Wrap {
    display: flex;
    align-items: center;
    gap: 0.25em;
    &Switch {
      font-size: 1.25em;
    }
  }

  &Status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    padding: 0 2em 1.5em;

    &Row {
      display: flex;
      align-items: center;
      gap: 1em;
    }

    &Wrap {
      display: flex;
      align-items: center;
      gap: 0.25em;

      &Text {
        color: #8bc53f;
        font-weight: 700;
        line-height: 1em;
        transition: all 0.2s ease 0s;

        &.disabled {
          color: #848a92;
        }
      }
    }

    > span {
      font-size: 1em;
      line-height: 150%;
      color: #27313d;
    }
  }

  &Period {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-right: 1.5em;

    &Button {
      border-radius: 1.5em;
      cursor: pointer;
      height: 2em;
      display: flex;
      align-items: center;
      width: 2em;
      justify-content: center;
      &:hover {
        background-color: $state-hover;

        svg path {
          fill: #798491;
        }
      }

      &:active {
        background-color: $state-active;

        svg path {
          fill: $link2;
        }
      }
    }

    span {
      display: flex;
      gap: 0.5em;
    }

    svg {
      cursor: pointer;
      width: 1.5em;
      height: 1.5em;

      path {
        fill: #798491;
      }
    }
  }
}
