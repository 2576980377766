@import "styles/vars";

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        margin-right: 0.93em;
        path{
            fill: $white;
        }
    }
}