.totals {
  padding: 1em 2em 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  &Sections {
    display: flex;
    flex-direction: column;
    width: 70%;
    &Wrapper {
      display: flex;
      gap: 1em;
    }
    &Tooltip {
      margin: 0.5em;
      &Text {
        color: #525a64;
        font-size: 1.15em;
        font-style: normal;
      }

      &Value {
        color: #27313d;
        font-style: normal;
        font-weight: 500;
        font-size: 1.15em;
      }
    }
  }
  &Section {
    border: 1px solid #e3e8ee;
    border-radius: 0.5em;
    padding: 0.75em;
    width: 16em;

    &Title {
      font-weight: 600;
      font-size: 0.875em;
      color: #525a64;
      margin: 0 0 0.5em 0;
    }

    &Date {
      display: flex;
      align-items: center;

      &Available {
        font-size: 0.875em;
        color: #27313d;
      }

      &Tooltip {
        margin: 0 0.5em 0 0;
      }
    }

    &:nth-child(1) {
      :global {
        .__react_component_tooltip {
          margin-left: 6.5em;
          padding: 0 !important;

          &::after {
            left: 1.5em;
          }
        }
      }
    }

    &:nth-child(2) {
      :global {
        .__react_component_tooltip {
          margin-left: 6.15em;
          padding: 0 !important;
        }
      }
    }

    &.warning {
      border-color: #fa9903;

      .customer-totals_section-data_available b {
        color: #fa9903;
      }
    }
  }

  &Warning {
    font-weight: 500;
    font-size: 0.875em;
    color: #fa9903;
    display: flex;
    align-items: center;
    margin-top: 0.5em;
    height: 1.4286em;

    svg {
      margin-right: 0.5em;
    }
  }
}
