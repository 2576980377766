.remember {
  &Wrap {
    display: flex;
    align-items: center;
    gap: 0.25em;

    &Switch {
      font-size: 1.25em;
    }
  }

  &Status {
    display: flex;
    align-items: center;
    gap: 1em;
    margin: 0 0 1.5em 2em;

    &Wrap {
      display: flex;
      align-items: center;
      gap: 0.25em;

      &Text {
        color: #8bc53f;
        font-weight: 700;
        line-height: 1em;
        transition: all 0.2s ease 0s;

        &.inactive {
          opacity: 0.5;
          pointer-events: none;
        }

        &.disabled {
          color: #848a92;
        }
      }
    }

    > span {
      font-size: 1em;
      line-height: 150%;
      color: #27313d;
    }
  }

  &Tooltip {
    max-width: 30em;
    left: 33.8em !important;
    box-shadow: 0px 4px 32px 0px #3b495c14 !important;
    border: 1px solid #e3e8ee !important;

    &Wrap {
      width: 1.25em;
      height: 1.25em;

      svg {
        width: 1.25em;
        height: 1.25em;

        path {
          transition: all 0.2s ease 0s;
        }

        &:hover {
          path {
            &:nth-child(1) {
              fill: #525a64;
            }

            &:nth-child(2) {
              fill: #fff;
            }

            &:nth-child(3) {
              fill: #fff;
            }
          }
        }
      }
    }

    &::after {
      margin-left: -11.9em !important;
      border-right: 1px solid #e3e8ee !important;
      border-top: 1px solid #e3e8ee !important;
      bottom: -0.3125em !important;
    }
  }
}

:global {
  .place-bottom {
    &::after {
      border-left: 1px solid #e3e8ee !important;
      border-top: 1px solid #e3e8ee !important;
      border-right: none !important;
      border-bottom: none !important;
    }
  }
}
