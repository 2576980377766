@import "styles/vars";

.domainMgmt {
  &Row {
    padding: 1.3em 1.8em 1.8em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &Title {
    font-weight: 600;
    font-size: 1.5em;
    margin: 0;
  }
  &Status {
    font-size: 0.825em;
    color: $link2;
    display: flex;
    align-items: center;
  }
  &Link {
    font-size: 0.83em;
  }
  &AdSync {
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: 0.8125em;
    line-height: 185%;
    color: #27313d;
    padding: 0 0 0 1.8em;
    &Link {
      font-weight: 600;
    }
  }
}

.domainMgmtTable {
  display: block;
  width: 100%;
  tbody {
    width: 100%;
    display: block;
  }
  &Wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.2em;
    border-bottom: 1px solid $white2;
    height: 5em;
    &:hover {
      background-color: transparent;
    }
  }
  &Item {
    padding: 0;
    border-bottom: none;
    &AzureButton {
      border-radius: 0;
    }
    &Name {
      font-weight: 500;
      color: #181818;
      font-size: 1em;
      line-height: 1em;
    }
    &Tooltip {
      border: 1px solid rgba(39, 49, 61, 0.3);
      background-color: $white;
      color: #27313d;
      font-size: 0.875em;
      line-height: 1.1429em;
      padding: 0.5em 1em;
      border-radius: 0;
      font-weight: 600;
      opacity: 0.5;
      cursor: default;
    }
    &Button {
      border-radius: 0;
    }
    &Status {
      font-weight: 600;
      font-size: 0.875em;
      line-height: 1em;
      display: flex;
      align-items: center;
      color: $green4;
      svg {
        width: 1em;
        height: 1em;
        margin-right: 0.6em;
        path {
          fill: $green4;
        }
      }
      &.notConnected {
        color: #848a92;
        svg {
          path {
            fill: #848a92;
          }
        }
      }
    }
    .sentryLink {
      font-size: 0.8125em;
      svg {
        width: 1.41em;
        height: 1.41em;
      }
    }
    &:nth-child(1) {
      width: 38%;
    }
    &:nth-child(2) {
      width: 42%;
    }
    &:nth-child(3) {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.tooltip {
  &Sentries {
    margin-top: 0.75em;
  }
  &Sentry {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #27313d;
  }
}
