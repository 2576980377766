@import "styles/vars";

.geoBlock {
  &Info {
    background: #f5f8fa;
    border: 1px solid #e3e8ee;
    border-radius: 0.5em;
    margin: 0 2em;
    padding: 1.5em;
    &Title {
      font-size: 1em;
      margin: 0 0 0.75em;
      font-weight: 500;
    }
    &Text {
      color: #525a64;
      font-size: 0.875em;
    }
  }
  &Header {
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 5.5em;
    justify-content: center;
    padding: 0 2em;
    z-index: 1;
  }
  &Actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  &SwitchCheckbox {
    align-items: center;
    display: flex;
    &Title {
      margin-right: 0.875em;
    }
    &Slide {
      font-size: 1.25em;
    }
    &Text {
      color: #848a92;
      font-weight: 700;
      margin-left: 0.25em;
      &Active {
        color: #8bc53f;
      }
    }
  }
}
