@import "styles/vars";

.password {
  list-style: none;
  font-size: 0.8125em;
  line-height: 150%;
  text-align: left;
  &Item {
    list-style: inherit;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0.5em;
    color: #525a64;
    b {
      font-weight: 500;
    }
    &.done {
      svg path {
        fill: #4daa90;
      }
    }
  }
  &Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    svg {
      width: 1.5em;
      height: 1.5em;
      path {
        transition: all 0.2s ease 0s;
        fill: #a9adb3;
      }
    }
  }
}

.tooltip {
  position: absolute;
  width: max-content;
  z-index: 2;
  cursor: default;
  box-shadow: 0px 0.25em 0.5em rgb(59 73 92 / 10%), 0px 0.25em 2em rgb(154 156 160 / 10%);
  padding: 1em;
  background: #ffffff;
  bottom: calc(100% - 0.8em);
  border: 1px solid $gray7;
  border-radius: $border-radius;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  &::after {
    content: "";
    width: 0.5em;
    height: 0.5em;
    transform: rotate(135deg) translateY(-50%);
    background: $white;
    position: absolute;
    left: 50%;
    text-align: center;
    box-shadow: 0.125em -0.125em 0.25em rgb(59 73 92 / 10%);
    z-index: -1;
    top: calc(100% - 0.4em);
    border-top: 1px solid $gray7;
    border-right: 1px solid $gray7;
  }
  &Title {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 129%;
    color: #27313d;
    margin-bottom: 0.5em;
  }
}
