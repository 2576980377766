.billing {
  background: #fff;
  position: relative;
  border: 1px solid #e3e8ee;
  box-shadow: 0px 1.5em 6.25em rgba(31, 37, 54, 0.07);
  border-radius: 0.5em 0.5em 0 0;
  margin-bottom: 1.75em;
  min-height: calc(100% - 1.75em);

  &Header {
    font-size: 1.5em;
    margin: 0;
    padding: 1.3333em 1.3333em 0.3333em;
    border-bottom: 1px solid #e3e8ee;
  }

  .braintreeWrapper {
    width: 50%;
    padding: 2em;  
  }
}

.setCardBtn { 
  position: absolute;
  top: 6em;
  right: 2em;
}
