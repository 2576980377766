@import "styles/vars";

.actions {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $white3;
  height: 100%;
  width: 11em;
  right: 0;
  opacity: 0;
  cursor: default;
  transition: all 0.2s ease 0s;

  &Wrapper {
    width: 2.5em;
    height: 2.5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 1em;
    border-radius: 50%;

    &:hover {
      background: rgba(169, 173, 179, 0.25);
    }
  }
}
