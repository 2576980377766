.bar {
  border: 1px solid #e0e0e0;
  padding: 1em 0;
  border-radius: 0.5em;
  min-height: 22em;
  &Title {
    color: #525a64;
    font-size: 1.125em;
    font-weight: 500;
    line-height: 1.3em;
    margin-bottom: 1em;
    padding: 0 1.333em;
  }
  &Chart {
    height: 100%;
  }
}
