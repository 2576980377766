.whiteList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3em;
  padding: 2em 2.5em;
  &Info {
    display: flex;
    gap: 1em;
    &Icon {
      width: 1.5em;
      height: 1.5em;
    }
    &Title {
      color: #27313d;
      font-size: 1em;
      line-height: 1.5em;
      font-weight: 600;
      margin-bottom: 0.5em;
    }
    &Text {
      color: #525a64;
      font-size: 0.875em;
      line-height: 1.28em;
      margin-bottom: 0.5em;
    }
  }
  &Notify {
    &Title {
      font-weight: 500;
    }
    &Text {
      font-weight: 400;
    }
  }
  &Dropdown {
    > span {
      > span {
        display: inline-flex;
        align-items: center;
        gap: 0.5em;
      }
    }
    &Options {
      font-weight: 600;
    }
  }
}
