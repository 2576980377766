.cancelButton {
  max-width: 9em;
}
.discardButton {
  position: absolute;
  bottom: 2.2em;
  left: 2.1em;
  padding: 0;
  border: none;
  font-size: 0.875em;
  transition: all 0.2s ease 0s;
  z-index: 10;
}
