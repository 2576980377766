.error {
  &Info {
    font-size: 0.875em;
    line-height: 140%;
    text-align: center;
    color: #525a64;
    margin-bottom: 1.5em;
  }
  &Button {
    width: 100%;
  }
}
