@import "styles/vars";

.header {
  background: #f5f8fa;
  border: 1px solid #e3e8ee;
  border-radius: 0.5em;
  padding: 0.9375em 1.5em;
  margin: 1em 2em;
  display: flex;
  align-items: baseline;
  gap: 8em;
  &.customer {
    margin: -0.125em 2em 0;
  }
  &Wrap {
    width: 14em;
  }
  &Checkbox {
    display: flex;
    align-items: center;
    gap: 0.5em;
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
    &Wrap {
      display: flex;
      align-items: center;
      gap: 0.25em;
      margin-right: 0.5em;
      svg {
        width: 1.25em;
        height: 1.25em;
        cursor: pointer;

        path {
          transition: all 0.2s ease 0s;
        }

        &:hover {
          path {
            &:nth-child(1) {
              fill: #525a64;
            }

            &:nth-child(2) {
              fill: #fff;
            }

            &:nth-child(3) {
              fill: #fff;
            }
          }
        }
      }
    }
    &Tooltip {
      box-shadow: 0px 4px 32px 0px #3b495c14 !important;
      border: 1px solid #e3e8ee !important;
      padding: 0.5em 0.75em !important;

      &::after {
        border-right: 1px solid #e3e8ee !important;
        border-top: 1px solid #e3e8ee !important;
        bottom: -0.34em !important;
      }
      &Wrap {
        width: 1.25em;
        height: 1.25em;
      }
    }
    &Title {
      font-size: 1em;
      line-height: 1.5em;
      color: #27313d;
    }
    &Text {
      font-weight: 700;
      font-size: 0.875em;
      line-height: 1em;
      color: #848a92;
      &.active {
        color: #8bc53f;
      }
    }
  }
  &Clear {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.5em;
    color: #848a92;
    &.disabled {
      color: #a9adb3;
    }
  }
  &Info {
    font-weight: 400;
    font-size: 0.8125em;
    color: #525a64;
    margin-top: 0.25em;
  }
}
