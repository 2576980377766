.form {
  margin: 0 -2em;
  padding: 0 2em;
  &Control {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.5em 0;
    margin-bottom: 2.5em;
    &:nth-child(2) {
      .formWrap {
        &:nth-child(3),
        &:nth-child(4) {
          flex: 0 0 calc(50% - 0.375em);
          max-width: calc(50% - 0.375em);
        }
        &:nth-child(5) {
          flex: 0 0 calc(70% - 0.375em);
          max-width: calc(70% - 0.375em);
        }
        &:nth-child(6) {
          flex: 0 0 calc(30% - 0.375em);
          max-width: calc(30% - 0.375em);
        }
      }
    }
  }
  &MaxLegth {
    text-align: right;
    font-size: 0.8125em;
    line-height: 1.125em;
    color: #4169b8;
    span {
      color: #525a64;
    }
  }
  &Title {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.5em;
    margin-bottom: 0.75em;
  }
  &Wrap {
    flex: 0 0 100%;
    max-width: 100%;
  }
  &Input {
    height: 2.5em;
    font-size: 1em;
    padding: 0.5em 1em;
    background: #f7f9fc;
    border-radius: 0.5em;
    border: none;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &.textarea {
      height: 7em;
      resize: none;
      white-space: initial;
      text-overflow: initial;
      overflow: initial;
    }
    &:focus {
      outline: none;
    }
  }
  &Socials {
    display: flex;
    align-items: center;
    gap: 0.5em;
    &Name {
      font-weight: 500;
      font-size: 1.125em;
      line-height: 1.5em;
      color: #27313d;
    }
    &Value {
      display: flex;
      align-items: center;
      gap: 0.5em;
      font-weight: 600;
      font-size: 0.875em;
      line-height: 1.125em;
      color: #4169b8;
    }
    &Wrap {
      display: flex;
      align-items: center;
      gap: 1em;
    }
    &Input {
      border: 1px solid #e3e8ee;
      border-radius: 0.25em;
      width: 100%;
      font-weight: 400;
      font-size: 0.8125em;
      line-height: 1.5em;
      color: #27313d;
      padding: 0.1em 0.75em;
      outline: none;
    }
    &Link {
      flex: 1;
    }
    &Remove {
      svg {
        width: 1.5em;
        height: 1.5em;
        path {
          fill: #606060;
        }
      }
    }
  }
  &Actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    bottom: 0;
    right: 2em;
    left: 2em;
    gap: 1em;
    padding: 1.5em 0;
    border-top: 1px solid #e3e8ee;
    background-color: #fff;

    .switcher {
      display: flex;
      justify-content: center;
      align-items: center;

      &Title {
        font-size: 0.75em;
        font-weight: 500;
        margin-left: 0.5em;
        white-space: nowrap;
      }
    }
    &Buttons {
      display: flex;
      gap: 1em;
    }
    &Button {
      max-width: 7.5em;
      transition: all 0.2s ease 0s;
      justify-content: flex-end;
      &.loading {
        max-width: 9.1875em;
      }
      &:first-child {
        padding: 0 2.042em;
      }
      &:last-child {
        padding: 0 1.93em;
        text-align: right;
      }
      svg {
        width: 1.15em;
        height: 1.15em;
      }
    }
  }
}

.loaderWrap {
  height: 15em;
}
