.custResolution {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1.5em;
  }

  &Cancel {
    background-color: #fff;
    border-color: #fff;
    color: #5d6a7a;
  }

  &Info {
    display: flex;
    flex-direction: column;
    padding-right: 0.75em;

    &Title {
      font-weight: 500;
      color: #27313d;
    }

    &Desc {
      font-weight: 400;
      font-size: 0.8125em;
      color: #525a64;
      margin-top: 0.25em;
    }
  }
  &Dropdown {
    max-height: unset;
    overflow-y: unset;
    > button {
      &:last-child {
        color: #4169b8;
        font-weight: 600;
      }
    }
  }

  &.customDimensions {
    display: block;

    .custResolutionInfo {
      margin-bottom: 1em;
    }

    .custResolutionRowCustom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .custResolutionClearInput {
      width: 11.25em;
      &:first-child {
        margin-right: 1.5em;
      }
    }
  }
}
