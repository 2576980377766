.links {
  padding: 1em 2em;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: -3.25em;
  right: 0;

  &Item {
    display: flex;
    align-items: center;
    gap: 0.5em;

    &:first-child {
      margin-right: 2.5em;
    }

    &Link {
      font-size: 0.875em;
      color: #5d6a7a;
      svg {
        path {
          fill: #5d6a7a;
        }
      }
      &:hover {
        color: #2f9bc2;
        svg path {
          fill: #2f9bc2;
        }
      }
      &:active {
        color: #2d86a7;
        svg path {
          fill: #2d86a7;
        }
      }
    }
  }
}

.linksInfo {
  color: #fa9903;
  font-size: 0.8125em;
  font-weight: 500;
  padding: 1em 2em;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: -5.7em;
  right: 0;
  display: flex;
  align-items: center;
  gap: 0.5em;
  svg path {
    fill: #fa9903;
  }
}
