.appsHeader {
  height: 5.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em 2em;

  &Wrap {
    display: flex;
  }
  &Status {
    display: flex;
    align-items: center;
  }

  &Checkbox {
    margin: 0 0.25em 0 0.875em;
    font-size: 1.25em;
    &Text {
      font-weight: 700;
      color: #848a92;
      &Active {
        color: #8bc53f;
      }
    }
  }

  &Refresh {
    font-weight: 500;
    font-size: 0.875em;
    border-radius: 1.4286em;
    > span {
      display: flex;
      align-items: center;
    }

    &Loading {
      svg {
        animation: spin 2s linear infinite;
      }
    }

    svg {
      margin-right: 0.65em;
      transform: revert;

      path {
        fill: #fff;
      }
    }
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
