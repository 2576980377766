.statusMessage {
  position: relative;
  flex: auto;
}

.table {
  tr {
    height: 4em;
  }

  &Name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-weight: 500;
  }

  &Upn {
    color: #525a64;
    font-size: 0.75em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &Wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &Status {
    align-items: center;
    display: flex;
    font-weight: 600;
    gap: 0.25em;

    &Enabled {
      color: #8bc53f;
      font-size: 0.875em;
    }

    &Disabled {
      color: #848a92;
      font-size: 0.875em;
    }
  }
}
