@import "styles/vars";

.noData {
  &Message {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &Title {
    font-weight: 600;
    margin-bottom: 0.5em;
  }
  &Link {
    font-weight: 600;
    color: $link;
    &:hover {
      color: $link-hover;
    }
    &:active {
      color: $link-active;
    }
  }
}
