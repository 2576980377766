.table {
  margin: 2em 0 0 !important;
  position: relative;
  &.withApps {
    td,
    th {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        justify-content: flex-end;
      }
    }
    .tableRow {
      &:hover {
        td {
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            .tableRowUsersCount,
            .tableRowComputersCount {
              opacity: 0;
            }
          }
          &:nth-child(5) {
            div {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &.withoutColumns {
    .tableRow {
      &:hover {
        td {
          &:nth-child(3) {
            div {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  td,
  th {
    &:nth-child(3),
    &:nth-child(4) {
      justify-content: flex-end;
    }
  }
  &Row {
    cursor: pointer;
    transition: all 0.2s ease 0s;
    &.inactive {
      cursor: default;
      td {
        &:nth-child(1),
        &:nth-child(2) {
          opacity: 0.6;
        }
      }
    }
    &UsersCount,
    &ComputersCount {
      opacity: 0.6;
      transition: all 0.2s ease 0s;
    }

    td {
      height: 4em;
      position: relative;
    }

    &:hover {
      td {
        &:nth-child(3),
        &:nth-child(4) {
          .tableRowUsersCount,
          .tableRowComputersCount {
            opacity: 0;
          }
        }
        &:nth-child(4) {
          div {
            opacity: 1;
          }
        }
      }
    }
  }
}
