.licensing {
  &Wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5em 2em;
  }
  &Filter {
    display: flex;
    align-items: center;
    gap: 3em;
  }
  &Stat {
    color: #27313d;
    strong {
      font-weight: 700;
    }
  }
  &Row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    padding: 0 2em 3em;
  }
}
