@import "styles/vars";

.onboarding {
  min-height: 30em;
  position: relative;
  padding: 2em 2.5em;
  border-radius: 0.5em 0.5em 0 0;

  &Steps {
    display: inline-block;
    background: #4daa901a;
    color: $primary;
    border-radius: 0.2857em;
    font-size: 0.875em;
    font-weight: 600;
    padding: 0.2875em 0.8571em;
  }

  &Title {
    font-weight: 600;
    font-size: 1.5em;
    margin: 0.5em 0 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Link {
      font-weight: 400;
      color: #5d6a7a;
      font-size: 0.575em;
      transition: all 0.2s ease 0s;

      svg path {
        fill: #ff0000;
      }

      &:hover {
        color: #2f9bc2;

        svg path {
          fill: #ff0000;
        }
      }

      &:active {
        color: #2d86a7;

        svg path {
          fill: #ff0000;
        }
      }
    }
  }
}
