.sharedDrives {
  display: block;
  margin-bottom: 1.5em;

  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Info {
    display: flex;
    flex-direction: column;
    padding-right: 0.75em;
    &Title {
      display: flex;
      align-items: center;
      color: #27313d;
      font-weight: 500;
    }
    &Button {
      color: #27313d;
      margin-right: 0.75em;
      transition: all 0.2s ease 0s;
      &.disabled {
        color: #27313d;
        svg path {
          fill: #848a92;
        }
      }
      svg {
        width: 1.25em;
        height: 1.25em;
        path {
          transition: all 0.2s ease 0s;
          fill: #848a92;
        }
      }
    }
  }

  &List {
    margin-top: 1em;
    height: 9.75em;
    overflow-y: scroll;
    padding: 0.5em 0;
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;

    &Item {
      display: flex;
      align-items: center;
      color: #27313d;
      padding-left: 0.5em;

      &:not(:last-child) {
        margin-bottom: 0.8em;
      }
    }
  }
}
