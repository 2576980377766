.roles {
  &Title {
    padding: 1em 1.3333em;
    font-weight: 600;
    font-size: 1.5em;
  }

  &Role {
    display: flex;
    align-items: center;
    font-weight: 600;

    svg {
      width: 1.25em;
      height: 1.25em;
      margin: 0 0.5em 0 0;
    }
    &Admin {
      color: #4169b8;
      svg path {
        fill: #4169b8;
      }
    }
    &NA {
      color: #848a92;
      svg path {
        fill: #848a92;
      }
    }
  }
  &Table {
    tr {
      td {
        height: 4em;
      }
    }
  }
}
