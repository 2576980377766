.workspaceContent {
  &Header {
    padding: 1em 2em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4em;
  }
  &Title {
    font-weight: 600;
    font-size: 1.5em;
    margin: 0;
  }
  :global {
    .react-tabs__tab-list {
      &.no-tabs {
        margin: 0 !important;
        padding: 0 !important;
        border-bottom: none !important;
        display: none;
        .react-tabs__tab {
          visibility: hidden;
        }
      }
    }
  }
}
