.form {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  &Container {
    position: relative;
    min-height: 18em;
  }
  &Info {
    font-size: 0.875em;
    line-height: 140%;
    text-align: center;
    color: #525a64;
    margin-bottom: 1.5em;
    strong {
      color: #27313d;
      font-weight: 600;
    }
  }
  &Button {
    width: 100%;
  }
  &Input {
    &Wrap {
      position: relative;
    }
  }
}
