.item {
  position: relative;
  transition: all 0.2s ease 0s;

  &:hover {
    background: rgba(65, 105, 184, 0.2);
    &.branding {
      background-color: #ffffff1a;
    }
    &.inverted {
      background-color: #0000001a;
    }
  }

  &.active {
    background: rgba(65, 105, 184, 0.2);

    &.branding {
      background-color: #ffffff1a;
      > span {
        background: #27313d;
      }
    }

    &.inverted {
      background-color: #0000001a;
      > span {
        background: #27313d;
      }
    }
    > span {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0.25em;
      border-radius: 0 0.25em 0.25em 0;
      background: #4169b8;
    }
  }

  &Disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.inverted {
    .itemLink {
      color: #000;
      span {
        color: #000;
      }
      svg path {
        fill: #000;
      }
    }
  }

  &Link {
    display: flex;
    align-items: center;
    gap: 1em;
    cursor: pointer;
    padding: 0.5em 0.75em 0.5em 1em;
    height: 2.5em;
    border: 1px solid transparent;
    border-radius: 0.5em;
    color: #fff;
    font-weight: 500;

    &:hover {
      color: #fff;
      svg path {
        fill: #fff;
      }
    }

    svg {
      height: 1.5em;
      width: 1.5em;
      height: 1.5em;

      path {
        fill: #fff;
      }

      &:nth-child(3) {
        margin: 0;
        position: absolute;
        right: 1em;
      }
    }

    span {
      font-weight: 500;
      color: #fff;
    }
  }
  &Sublist {
    &Link {
      padding-left: 3.75em;
      font-weight: 400;
    }

    &DomainName {
      font-size: 0.75em;
      pointer-events: none;
      display: block;
      padding: 0.625em 0.75em 0.625em 5.1em;
      font-weight: 600;
      color: #4daa90;
      letter-spacing: 0.25px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &.inverted {
        color: #000;
      }
    }
  }
}
