.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.125em;
  padding: 0 2.5em;
  position: fixed;
  bottom: 0;
  z-index: 2;
  left: 18.75em;
  width: calc(100% - 18.75em);
  background: #f1f5f7;
  margin: 0;
  border-radius: unset;

  &.branding {
    padding-right: 10em;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 2.5em);
    height: 1px;
    background: #e3e8ee;
  }
  &Load {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 102%;
      background: rgba(255, 255, 255, 0.5);
      z-index: 2;
      border-radius: 2em;
    }
  }

  &Empty {
    width: 13.125em;
  }

  &Pages {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 25em;
    gap: 1em;
    position: relative;
  }

  &Flip {
    width: 4em;
    color: #848a92;
    pointer-events: none;
    svg path {
      fill: #848a92;
    }

    &Active {
      color: #4169b8;
      cursor: pointer;
      pointer-events: unset;

      svg path {
        fill: #4169b8;
      }
    }

    &Next {
      > span {
        flex-direction: row-reverse;
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
