@import "styles/vars";

.alert {
  padding: 0.688em 1.5em;
  margin-bottom: 1em;
  > div {
    > div {
      &:first-child {
        > span {
          font-size: 1.125em;
          font-weight: 600;
          color: #27313d;
          span {
            color: #fa9903;
          }
        }
      }
    }
  }
}
