.row {
  align-items: center;
  display: flex;
  padding: 1.25em 2.5em;
  position: relative;
  &Title {
    font-weight: 500;
    width: 20%;
  }
  &Value {
    font-size: 0.875em;
    width: 40%;
  }
  &Action {
    width: 40%;
    &Message {
      position: absolute;
      bottom: 0.7em;
      font-weight: 500;
      font-size: 0.67em;
      color: #4daa90;
    }
  }
  &Email {
    &Edit {
      border-bottom: 1px solid #e0e0e0;
      margin: 0 2.5em;
      padding: 0 0 1.25em;
      &Info {
        margin-bottom: 1em;
        width: 55%;
        span {
          color: #4169b8;
          font-weight: 600;
        }
      }
      &InputWrapper {
        align-items: center;
        display: flex;
        padding-right: 1em;
        width: 60%;

        .domainName {
          margin-left: 1.5em;
        }

        input {
          width: 22em;
        }
      }
      &Button {
        margin-top: 2em;
        width: 9.5em;
      }
    }
  }
}
