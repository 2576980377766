.table {
  &Button {
    visibility: hidden;
    transition: 0.2s transform cubic-bezier(0.1, 1.26, 0.83, 1) 0s;
    transform: scale(0.95);
    will-change: transform;
  }

  th {
    height: 3.5em;
  }
  td {
    height: 4em;
  }
  tbody {
    tr {
      &:hover {
        td {
          &:nth-child(3) {
            .tableButton {
              visibility: visible;
              transform: scale(1);
            }
          }
        }
      }
    }
  }
  th,
  td {
    &:nth-child(2),
    &:nth-child(3) {
      justify-content: flex-end;
    }
  }
}
