@import "styles/vars";

.header {
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 5.5em;
  justify-content: center;
  padding: 0 2em;
  z-index: 1;
  &Actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  &SwitchCheckbox {
    align-items: center;
    display: flex;
    &Title {
      margin-right: 0.875em;
    }
    &Slide {
      font-size: 1.25em;
    }
    &Text {
      color: #848a92;
      font-weight: 700;
      margin-left: 0.25em;
      &.active {
        color: #8bc53f;
      }
    }
  }
}
