.table {
  min-height: 17.5em;
  &Row {
    &Baseline {
      td {
        align-items: baseline;
      }
    }
  }
  &Status {
    font-weight: 500;
    color: #4daa90;
    &.failed {
      color: #f3657b;
    }
  }
  &List {
    &Item {
      margin-bottom: 0.5em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &Key {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 1.1375em;
      color: #27313d;
    }
    &Value {
      font-size: 0.75em;
      color: #27313d;
    }
  }
}
