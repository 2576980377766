@import "styles/vars";

.table {
  &Empty {
    tr {
      td {
        padding: 3em 0 0;
      }
    }
  }
  tr {
    td {
      height: 4.75em;
      &:not(:first-child) {
        justify-content: flex-end;
        font-size: 1.25em;
        font-weight: 500;
        height: 3.8em;
        position: relative;
      }
    }
    th {
      &:not(:first-child) {
        justify-content: flex-end;
      }
    }
  }
}

.backdrop {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  background: #ffffff7d;
}

.cancelChanges {
  font-size: 0.7em;
  color: $link2;
  transition: all 0.2s ease 0s;
  svg path {
    fill: $link2;
    transition: all 0.2s ease 0s;
  }
  &:hover {
    color: $link2-hover;
    svg path {
      fill: $link2-hover;
    }
  }
  &:active {
    color: $link2-active;
    svg path {
      fill: $link2-active;
    }
  }
}
