@import "styles/vars";

.modalTitle {
  display: flex;
  align-items: center;
  gap: 0.5em;
  svg {
    width: 0.833em;
    height: 0.833em;
    path {
      fill: $red;
    }
  }
}
