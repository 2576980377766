@import "styles/vars";

.dropdown {
  display: inline-flex;
  align-items: center;
  font-size: 1em;
  position: relative;

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &Label {
    font-weight: 300;
    margin-right: 0.5em;
    color: #848a92;
  }

  &Select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.55em;
    color: $gray5;
    padding: 0 2.25em 0 1em;
    border: 1px solid #e3e8ee;
    border-radius: 0.5em;
    cursor: pointer;
    position: relative;
    width: 100%;
    transition: all 0.2s ease 0s;

    &.active {
      border-color: #bcd6eb;
      background-color: $gray3;
    }

    &:hover {
      background-color: $gray3;
    }

    svg {
      position: absolute;
      right: 0.5em;
      fill: #606060;
    }

    &Value {
      height: 1.4286em;
      line-height: 1.4286em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &Options {
    position: absolute;
    top: 3em;
    right: 0;
    background-color: $white;
    border-radius: 0.5em;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.246203);
    z-index: 3;
    width: -webkit-fill-available;
    width: -moz-available;
    max-height: 15.25em;
    overflow-y: auto;
    padding: 0.5em 0;
    cursor: default;

    &Transition {
      &Enter {
        opacity: 0;
        transform: scale(0.9);
        transform-origin: top;
      }
      &EnterActive {
        opacity: 1;
        transform: translateX(0);
        transition:
          opacity 200ms,
          transform 200ms;
      }
      &Exit {
        opacity: 1;
      }
      &ExitActive {
        opacity: 0;
        transform-origin: top;
        transform: scale(0.9);
        transition:
          opacity 200ms,
          transform 200ms;
      }
    }

    &Item {
      display: block;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1em;
      padding: 0.58em 1.18em;
      color: $gray5;
      width: 100%;
      text-align: left;

      svg {
        position: absolute;
        right: 0.5em;
        fill: #606060;
      }

      svg {
        width: 1.72em;
        height: 1.72em;
      }

      &.active {
        cursor: default;
        pointer-events: none;
        background-color: #f7f7f7;

        svg {
          right: 1.145em;
          width: 1.5em;
          height: 1.5em;

          path {
            fill: $primary-active;
          }
        }

        svg {
          path {
            fill: $primary-active;
          }
        }

        &:hover {
          background-color: $white;
        }
      }

      &:hover {
        background-color: #f7f7f7;
        color: $primary;

        svg {
          path {
            fill: $primary;
          }
        }
      }

      &.custom-field {
        font-weight: 600;
        color: $primary;
      }

      &.disabled {
        color: #848a92;
        pointer-events: none;
      }
    }
  }
}
