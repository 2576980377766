@import "styles/vars";

.assignments {
  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5em 0 1em;
    &Title {
      color: $link2;
      font-size: 0.8125em;
      font-weight: 400;
    }
    &Select {
      background: transparent;
      border: none;
      padding: 0;
      color: $primary;
      font-size: 0.875em;
      &:hover {
        color: $primary-hover;
      }
      &:active {
        color: $primary-active;
      }
    }
  }
  &NoResultsFound {
    color: $link2;
    font-style: italic;
    font-weight: 400;
    margin-top: 4em;
    pointer-events: none;
    text-align: center;
  }
  &Content {
    height: 10.1em;
    overflow-y: auto;
    margin: 0 -2em;
    &MfaEnabled {
      height: 14.4em;
    }
  }
  &Item {
    position: relative;
    &:hover {
      background-color: $white3;
    }
    &NewItem {
      background-color: $white3;
    }
    &Checkbox {
      padding: 0.625em 2em;
      justify-content: space-between;
    }
  }
}
