@import "styles/vars";

.status {
  display: flex;
  align-items: flex-end;
  font-weight: 500;

  &.notValidated {
    color: #575b5f;
  }
  &.notActivated {
    color: $blue;
  }
  &.active {
    color: $green2;
  }

  svg {
    cursor: pointer;
    margin-left: 0.5em;

    path {
      fill: $green2;
    }

    &.error {
      path {
        fill: $red;
      }
    }
    &.warning {
      path {
        fill: $orange;
      }
    }
  }
}
