.branding {
  background: #fff;
  border: 1px solid #e3e8ee;
  border-radius: 0.5em 0.5em 0 0;
  box-shadow: 0 1.5em 6.25em rgb(31 37 54 / 7%);
  color: #27313d;
  margin-bottom: 1.75em;
  min-height: calc(100% - 1.75em);
  position: relative;
  display: grid;
}

.viewBy {
  padding: 1em 0 1em 2em;
}

.noDomains {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 0.25em;
  &Title {
    font-weight: 600;
    font-size: 1em;
    line-height: 1.5em;
    color: #27313d;
  }
  &Link {
    font-size: 0.875em;
    line-height: 1.25em;
  }
}
