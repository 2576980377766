.encryptPass {
  &Title {
    margin-bottom: 1.5em;
  }
  &Wrap {
    margin-bottom: 2em;
  }
  &PassInfo {
    &Title {
      font-size: 0.8125em;
      font-weight: 500;
      margin-bottom: 0.6154em;
    }
    &List {
      font-size: 0.8125em;
      margin-bottom: 0.25em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
