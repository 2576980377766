.shortcutFilter {
  position: relative;
  margin-left: 2em;

  &Button {
    padding: 0;
    &Disabled {
      pointer-events: none;
      .shortcutFilterSelect > svg,
      .shortcutFilterValue {
        opacity: 0.6;
      }
    }
  }

  &Select {
    display: flex;
    align-items: center;
    height: 2.55em;
    padding: 0 1em;
    border: 1px solid #e3e8ee;
    border-radius: 0.5em;
    cursor: pointer;
    position: relative;
    width: auto;
    transition: all 0.2s ease 0s;

    &:hover {
      background: #f7f9fc;
    }

    &Active {
      border-color: #bcd6eb;
      background: #f7f9fc;
      z-index: 1;
    }

    & > svg {
      margin-right: 0.5em;
    }
  }

  &Value {
    color: #4169b8;
    font-weight: 500;
    font-size: 0.9375em;
  }

  &Options {
    position: absolute;
    top: 3em;
    left: 0;
    background-color: #fff;
    border-radius: 0.25em;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.246203);
    z-index: 2;
    padding: 1em 0;
    width: 17.5em;
    cursor: default;

    &Transition {
      &Enter {
        opacity: 0;
        transform: scale(0.9);
        transform-origin: top;
      }
      &EnterActive {
        opacity: 1;
        transform: translateX(0);
        transition:
          opacity 200ms,
          transform 200ms;
      }
      &Exit {
        opacity: 1;
      }
      &ExitActive {
        opacity: 0;
        transform-origin: top;
        transform: scale(0.9);
        transition:
          opacity 200ms,
          transform 200ms;
      }
    }

    &Header {
      font-size: 0.8125em;
      color: #525a64;
      padding: 0 1.2308em;

      &Status {
        padding-top: 0.75em;
      }
    }

    &List {
      padding: 1em;
      &Item {
        padding: 0.12em 0;
        margin-bottom: 0.75em;
        font-size: 0.875em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &Actions {
      padding: 0.5em 1em 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn {
        width: 48%;
      }
    }
  }
}
