.computer {
  &Title {
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.2963em;
    &Wrap {
      display: flex;
      align-items: center;
      gap: 1em;
      padding: 1em 2em;
      border-radius: 0.5em 0.5em 0 0;
      background-color: #fff;
    }
    &Icon {
      svg {
        width: 1.5em;
        height: 1.5em;
        transform: scale(-1);
        path {
          fill: #5d6a7a;
        }
      }
    }
  }
  &Filter {
    padding: 1.5em 2em 1em;
    border-bottom: 1px solid #e3e8ee;
  }
  &Graphic {
    padding: 0 2.5em 2.5em;
    height: 27.5em;
  }
  &Search {
    padding: 1.5em 2em;
    &Input {
      width: 21.375em;
    }
  }
}
