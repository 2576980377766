@import "styles/vars";

.registation {
  min-height: 30.5em;

  &Row {
    position: relative;
    margin-bottom: 1.5em;

    &Group {
      display: flex;
      justify-content: space-between;
      gap: 0.75em;
    }
  }

  &Checkboxes {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1em;
    margin-bottom: 0.75em;
  }

  &Checkbox {
    display: flex;
    align-items: center;
    gap: 0.5em;

    &Switch {
      width: 3.125em;
      height: 1.5em;

      input {
        &:checked+span {
          &::before {
            transform: translate(1.32em, -50%) !important;
          }
        }
      }

      span {
        background-color: #EFEFEF !important;

        &::before {
          border-radius: 0.9375em !important;
          top: 50%;
          transform: translateY(-50%) !important;
          left: 0.25em;
          width: 1.3125em;
          height: 1.26em;
          background-color: #4169B8;
          border-color: #4169B8 !important;
        }
      }
    }

    &Text {
      cursor: pointer;

      &.bold {
        font-weight: 500;
      }
    }
  }

  &Info {
    text-align: center;
    color: #525A64;
    font-size: 1em;
    line-height: 150%;

    strong {
      color: #27313D;
    }
  }

  &Promo {
    border: 1px solid $green4;
    border-radius: 0.5em;
    padding: 0.72em 1em;
    background-color: $green3;
    margin-bottom: 1.5em;
    color: $gray5;
    font-size: 0.875em;
  }
}