@import "styles/vars";

.onboarding {
  background: #fff;
  padding: 2.5em;
  border: 1px solid #e3e8ee;
  border-radius: 0.5em 0.5em 0 0;
  box-shadow: 0px 1.5em 6.25em rgba(31, 37, 54, 0.07);

  &Title {
    margin: 0;
    font-size: 1.5em;
    font-weight: 600;
  }

  &Link {
    font-size: 1em;
    color: $link;
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    font-weight: 600;

    svg path {
      fill: $link;
    }

    &:hover {
      color: $link-hover;

      svg path {
        fill: $link-hover;
      }
    }

    &:active {
      color: $link-active;

      svg path {
        fill: $link-active;
      }
    }
  }

  &Wrap {
    border: 0.125em solid #4daa90;
    border-radius: 0.5em;
    margin-top: 1.5em;
  }

  &Step {
    &Title {
      font-weight: 500;
      font-size: 1.25em;
      padding: 1em 1.25em 1em;

      span {
        margin-right: 0.75em;
      }
    }

    &Info {
      padding: 1.5em 3.25em;
      background: #f7fafc;
      border-top: 1px solid #e3e8ee;

      >div {
        display: flex;
        align-items: center;
      }

      &Button {
        font-weight: 600;
        font-size: 0.875em;
      }
    }

    &:first-child>div {
      border-bottom: 1px solid #e3e8ee;

      svg {
        width: 1.5em;
        height: 1.5em;
      }
    }

    &:last-child>div {
      border-radius: 0 0 0.5em 0.5em;

      p:last-child {
        margin-top: 1.25em;
      }
    }
  }
}

.activationCode {
  width: auto;

  svg path {
    fill: $primary;
  }

  button {
    &:hover {
      svg path {
        fill: $primary-hover;
      }
    }
  }

  >div {
    &:first-child {
      text-align: center;

      div {
        font-size: 1.213em;
        color: #27313D;
        font-weight: 500;
      }
    }
  }
}