.wrapper {
  position: relative;
  font-size: 1em;
  height: 1em;
  width: 1em;
  margin: 0 0 0 0.5em;

  &Tooltip {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
