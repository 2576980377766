@import "styles/vars";

.onboarding {
  border: 0.0625em solid $white2;
  border-radius: 0.5em;
  color: #848a92;
  padding: 1.25em 1.5em 1em 1.5em;
  overflow: hidden;
  &.active {
    border: 0.125em solid $green4;
    color: $gray5;
  }
  &.isAutovalidaton {
    padding: 1.25em 1.5em 1em 0.3125em;
    .onboardingTitle {
      margin-left: 1.125em;
    }
  }
  &.completed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7fafc;
    border-color: #fff;
    .onboardingTitle {
      margin-bottom: 0;
      h5,
      span {
        font-size: 1em;
        font-weight: 400;
      }
    }
  }
  &Title {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 1em;
    h5,
    span {
      font-size: 1.25em;
      font-weight: 500;
    }
  }
  &Info {
    margin-left: 1.1875em;
    margin-bottom: 1.5em;
    span {
      color: $link2;
      font-size: 0.8125em;
      line-height: 1.4em;
    }
  }

  &Variants {
    margin-left: 1.1875em;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  }
  &Variant {
    margin: 0 -1.5em 0 -2.6875em;
    padding: 0 1.5em 0 2.6875em;
    position: relative;
    &.active {
      background: #f7fafc;
      &::after {
        content: "";
        background: #f7fafc;
        width: 100%;
        height: 1em;
        position: absolute;
        right: 0;
        left: 0;
        top: -1em;
      }
    }
    &Checkbox {
      position: relative;
      z-index: 1;
      font-weight: 500;
    }
  }
  &Step {
    border: 0.0625em solid $white2;
    border-radius: 0.5em;
    color: #848a92;
    padding: 1.25em 1.5em;

    // &.completed {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   background: $white3;
    //   border-color: $white;
    // }

    &.active {
      border: 0.125em solid $green4;
      color: $gray5;
      padding: 0;

      .onboardingStepTitle {
        font-weight: 500;
        padding: 1.25em 1.5em 1em;
        display: flex;
        align-items: center;
        gap: 0.5em;

        &Step2 {
          padding-bottom: 0;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }

    &ActionWrap {
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 0 1.5em 1.2em 2.6em;

      > button {
        font-weight: 600;
        font-size: 0.875em;

        &:nth-child(2) {
          svg path {
            fill: none;
            stroke: $link;
          }

          &:hover {
            svg path {
              fill: none;
              stroke: $link-hover;
            }
          }

          &:active {
            svg path {
              fill: none;
              stroke: $link-active;
            }
          }
        }
      }

      &.validation {
        font-weight: 400;
        padding-left: 3em;
      }
    }

    &Checkbox {
      &Button {
        font-weight: 600;
        display: flex;
        align-items: center;
      }

      &.active {
        background: $white3;
      }

      &.onPremise {
        border-bottom: 1px solid $gray7;
      }

      &Title {
        align-items: center;
        display: flex;
        font-weight: 500;
        padding: 0;
      }

      &Details {
        & > div {
          font-size: 0.875em;
          display: flex;
          align-items: center;
          padding: 0 0 1em 3.5em;

          .ms-login {
            margin-left: 0.5em;
          }
        }
      }
    }

    &.active {
      background: $white3;
      padding-top: 0.5em;

      &.on-premise {
        border-bottom: 1px solid $gray7;
        margin-bottom: 1em;
      }

      &.azure {
        border-top: 1px solid $gray7;
      }
    }
  }
  &Link {
    font-size: 0.875em;
    padding: 1em 0 0 5.2em;
    border-top: 1px solid $gray7;
    margin: 1.5em -1.5em 0;

    > a {
      font-weight: 600;
      color: $link;

      &:hover {
        color: $link-hover;
      }

      &:active {
        color: $link-active;
      }
    }
  }
}
