.licensingTable {
  &Row {
    &Negative {
      background: #fdfafb;
      td {
        &:nth-child(2) {
          color: #f3657b;
        }
      }
    }
    &Positive {
      td {
        &:nth-child(2) {
          color: #4daa90;
        }
      }
    }
  }
  &Cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  tr {
    td {
      height: 4em;
    }
    td,
    th {
      &:not(:first-child) {
        justify-content: flex-end;
      }
    }
  }
}
