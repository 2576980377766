.reset {
  &Row {
    align-items: center;
    color: #27313d;
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    margin-bottom: 1.5em;
  }
  &Dropdown {
    align-items: center;
    display: inline-flex;
    font-size: 1em;
    position: relative;
    width: 100%;
    &.disabled {
      opacity: 0.6;
      pointer-events: none;
      .resetDropdownSelect {
        background: #fbfbfb;
      }
    }

    &Select {
      align-items: center;
      border: 1px solid #e3e8ee;
      border-radius: 0.5em;
      color: #27313d;
      cursor: pointer;
      display: flex;
      height: 2.55em;
      justify-content: space-between;
      padding: 0 2.25em 0 1em;
      position: relative;
      width: 100%;
      transition: all 0.2s ease 0s;
      &.active {
        background-color: #f7f9fc;
        border-color: #bcd6eb;
      }
      &.disabled {
        pointer-events: none;
      }

      &Value {
        height: 1.4286em;
        line-height: 1.4286em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      svg {
        fill: #606060;
        position: absolute;
        right: 0.5em;
      }
    }

    &Options {
      background-color: #fff;
      border-left: 0.5em;
      border-radius: 0.5em;
      box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
      cursor: default;
      border-left: 0.5em;
      max-height: 19em;
      overflow-y: auto;
      padding: 1.5em 1em 1em;
      position: absolute;
      right: 0;
      top: 3em;
      width: -webkit-fill-available;
      width: -moz-available;
      z-index: 3;

      &Search {
        border: none;
        border-radius: 0.25em 0.25em 0 0;
        font-size: 1em;
        margin-bottom: 1em;
        padding: 0.5625em 0;
        outline: none;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &Row {
        align-items: center;
        color: #27313d;
        font-weight: 500;
        margin: 0;
        padding: 0.5em 0.25em;
        display: flex;
        justify-content: space-between;
        &.selectAll {
          padding: 0 0.25em;
        }
        &.userDomain {
          border-bottom: 1px solid rgba(82, 90, 100, 0.1);
          margin: 1em 0 0.5em;
          padding: 0.5em 0.25em 1em;
        }

        &Label {
          color: #525a64;
          font-size: 0.8125em;
        }
        &SelectAll {
          font-size: 0.875em;
          font-weight: 500;
        }
      }

      &Checkbox {
        width: 100%;
        justify-content: space-between;
      }
    }

    &Empty {
      text-align: center;
      font-style: italic;
      color: #525a64;
      pointer-events: none;
      font-weight: 400;
      margin: 1.5em 0;
    }
  }
}
