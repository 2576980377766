@import "styles/vars";

.ad {
  &Status {
    display: flex;
    align-items: center;
    gap: 0.4em;
    position: relative;
    margin-right: 2em;
    > svg {
      width: 1em;
      height: 1em;
    }
  }
  &Message {
    font-weight: 600;
    font-size: 0.8125em;
    color: $green4;
  }
  &Popover {
    svg path {
      fill: $link2;
    }
    &Opened {
      svg path {
        fill: $link2;
      }
    }
    p {
      color: $gray5;
      font-size: 0.72em;
    }
    a {
      color: $primary;
      font-weight: 600;
      &:hover {
        color: $primary-hover;
      }
      &:active {
        color: $primary-active;
      }
    }
  }
}
