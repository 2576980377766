.passMgmt {
  padding: 1.7142857142857142em 1.4285714285714286em 1.7142857142857142em 1.7142857142857142em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &Title {
    font-weight: 600;
    font-size: 1.5em;
    margin-bottom: 1.5238em;
  }

  &Message {
    font-weight: 400;
    line-height: 1.3em;
    font-size: 0.875em;
    margin-bottom: 2.3em;
    padding-left: 2em;
    position: relative;

    span {
      color: #4daa90;
      font-weight: 600;
    }

    &Icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: 1.3em;
      width: 1.3em;

      svg {
        height: 1.3em;
        width: 1.3em;

        path {
          fill: #5d6a7a;
        }
      }
    }
  }

  &Row {
    display: flex;
    align-items: center;
    position: relative;
  }

  &Col {
    flex: 0 0 50%;
    max-width: 50%;
  }

  &Input {
    width: 10%;
    border: 1px solid #e3e8ee;
    color: #27313d;
    border-radius: 0.5em;
    padding: 0.375em 0;
    text-align: center;
    margin-right: 1em;

    &Disabled {
      background: #fff;
      border-color: #e3e8ee;
      color: #848a92;
      pointer-events: none;
    }

    &Title {
      font-weight: 500;
      font-size: 1em;
      color: #27313d;
      margin: 0 0 0.3125em;
    }

    &Subtitle {
      font-weight: 400;
      font-size: 0.9em;
      color: #525a64;
      margin: 0;
    }

    &Error {
      border-color: #f3657b;
    }

    &:focus {
      outline: none;
      border-color: #bcd6eb;
    }
  }

  &Label {
    font-weight: 400;
    font-size: 0.8125em;
    line-height: 150%;
    color: #525a64;
    margin: 0;
  }

  &Item {
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }

  &Actions {
    justify-content: flex-end;
    display: flex;
    gap: 1em;
    margin-top: 3em;
  }

  &Error {
    font-weight: 400;
    font-size: 0.7em;
    color: #f3657b;
    position: absolute;
    top: calc(100% + 0.3125em);
    left: 0.5em;
  }
}
