.login {
  background: #fff;
  height: 100%;
  &Header {
    background-image: url("../../assets/img/login_bg_image2.svg");
    background-size: cover;
    height: 8.125em;
  }
  &Logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.25em;
    position: absolute;
    right: 0;
    left: 0;
    top: 2.25em;
    svg {
      width: 4.875em;
      height: 1.5em;
    }
  }
  &Wrap {
    margin: -2.125em 0 0;
  }
  &Container {
    background: #fff;
    box-shadow: 0px 15px 35px rgba(59, 73, 92, 0.1);
    border-radius: 0.5em;
    width: 31.25em;
    margin: 0 auto;
    padding: 2em;
  }
  &Row {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }
  &Copyright {
    bottom: 0;
    color: #5d6a7a;
    font-size: 0.75em;
    line-height: 1.5em;
    padding: 1em 2em;
    position: absolute;
    right: 0;
    text-align: right;
  }
}
