.hostList {
  &Item {
    margin-bottom: 0.75em;
    padding: 0.75em 1em;
    border: 1px solid #E3E8EE;
    border-radius: 0.5em;

    &.offline {
      border-color: #FA9903;
    }

    &:last-child {
      margin-bottom: 2em;
    }

    &Row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &Info {
      margin-bottom: 0.5em;

      &Name {
        font-weight: 500;
        font-size: 1em;
        line-height: 150%;
        color: #27313D;
      }

      &Status {
        span {
          display: flex;
          align-items: center;
          gap: 0.3125em;
          font-weight: 600;
          font-size: 0.875em;
          line-height: 129%;

          svg {
            width: 1em;
            height: 1em;
          }

          &.online {
            color: #8BC53F;
          }

          &.offline {
            color: #FA9903;
          }
        }
      }
    }

    &Version,
    &Response {
      font-size: 0.75em;
      line-height: 150%;
      color: #525A64;

      b {
        font-weight: 500;
        color: #27313D;
      }
    }
  }
}

.noMessage {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}