.info {
  padding: 1em 2.5em;
  &Row {
    display: flex;
    align-items: center;
  }
  &Col {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    border-right: 1px solid #e0e0e0;
    padding-left: 1.5em;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border-right: none;
    }
  }
  &Title {
    color: #525a64;
    font-size: 1.125em;
    line-height: 1.3em;
    font-weight: 500;
    margin-bottom: 0.3125em;
  }
  &Count {
    font-size: 1.5em;
    line-height: 1.3em;
    color: #27313d;
    font-weight: 500;
  }
}
