@import "styles/vars";

.workspace {
  height: calc(100% - 2.5em);

  &Wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5em 1.3333em 0.6667em;
  }

  &Title {
    font-weight: 600;
    font-size: 1.5em;
    margin: 0;
  }
}

.header {
  padding: 1em 2em;
  display: flex;
  align-items: center;

  &Total {
    color: #525a64;
    margin-right: 1.5em;
    font-weight: 400;
    line-height: 1.5em;

    &Counter {
      font-weight: 600;
      color: #27313d;
    }
  }
}
