.preview {
  width: 31.25em;
  height: 15.625em;
  box-shadow: 0px 4px 20px rgba(34, 35, 37, 0.15);
  position: relative;
  pointer-events: none;
  &Logo {
    left: 50%;
    position: absolute;
    top: 1.25em;
    transform: translateX(-50%);
    &Image {
      width: 2.9em;
      height: auto;
    }
    &Custom {
      height: 1.2em;
      width: auto;
      max-width: 11em;
      object-fit: contain;
    }
  }
  &Header {
    background-image: url("../../../../../../../../assets/img/login_bg_image2.svg");
    background-size: cover;
    height: 3.9875em;
  }
  &Wrapper {
    width: 15.25em;
    height: 7.425em;
    background-color: #fff;
    border-radius: 0.25em;
    box-shadow: 0 15px 35px 0 rgb(59 73 92 / 10%);
    margin: -1em auto 0;
    padding: 0.976875em 1.4em 1.039375em;
    display: flex;
    flex-direction: column;
    gap: 0.7325em;
    &Title {
      text-align: center;
      font-weight: 600;
      font-size: 0.7325em;
    }
    &Input {
      font-size: 0.488125em;
      background: #f7f9fc;
      border: 0.5px solid #a9adb3;
      border-radius: 0.5em;
      height: 100%;
      padding-left: 1em;
      display: flex;
      align-items: center;
      width: 100%;
      color: #525a64;
      margin: 0 auto;
    }
    &Button {
      background-color: #4169b8;
      border: 1px solid #4169b8;
      color: #fff;
      text-align: center;
      border-radius: 0.5em;
      font-weight: 600;
      font-size: 0.5125em;
      padding: 0.6em 0;
    }
  }
}
