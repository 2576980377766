.workspaceTable {
  &.alert {
    height: calc(100% - 85px) !important;
  }
  tr {
    transition: all 0.2s ease 0s;
    &:hover {
      .workspaceTableButton {
        visibility: visible;
        transform: scale(1);
      }
    }
    td {
      height: 4em;
      &:last-child {
        justify-content: flex-end;
      }
    }
  }
  &RowLoading {
    .workspaceTableButton {
      visibility: visible;
      transform: scale(1);
    }
  }
  &Hostname {
    align-items: center;
    display: flex;
    gap: 0.75em;
  }
  &Icon {
    align-items: center;
    display: flex;
    gap: 0.75em;
    img {
      width: 2em;
    }
  }
  &Status {
    padding: 0.4167em 1.3333em;
    font-size: 0.75em;
    font-weight: 600;
    line-height: 1.3333em;
    border-radius: 1.6666em;
    &Online {
      background-color: #8bc53f;
      color: #fff;
    }
    &Offline {
      border: 1px solid #a9adb3;
      color: #a9adb3;
    }
  }
  &Button {
    width: 9em;
    visibility: hidden;
    transition: 0.2s transform cubic-bezier(0.1, 1.26, 0.83, 1) 0s;
    transform: scale(0.95);
    will-change: transform;
  }
}
