.switchCheckbox {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 0.875em;
  margin: 0;

  &Input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      & + .switchCheckboxSlider {
        background-color: #8bc53f;
      }

      &:disabled + .switchCheckboxSlider {
        background-color: #c5e29f;
        cursor: default;

        &::before {
          border-color: #c5e29f;
        }
      }
    }

    &:disabled + .switchCheckboxSlider {
      background-color: #a9adb3;
      cursor: default;

      &:before {
        border-color: #a9adb3;
      }
    }

    &:checked + .switchCheckboxSlider::before {
      -webkit-transform: translateX(0.75em);
      -ms-transform: translateX(0.75em);
      transform: translateX(0.75em);
      border-color: #8bc53f;
    }
  }

  &Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #848a92;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 0.875em;

    &::before {
      position: absolute;
      content: "";
      height: 1.25em;
      width: 1.25em;
      left: 0;
      top: -0.185em;
      border: 0.125em solid #848a92;
      border-radius: 50%;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}
