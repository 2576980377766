.noLicenses {
  padding: 1em 0 0 2em;
  display: flex;
  align-items: center;

  &Message {
    margin-left: 0.5em;
  }

  &Link {
    color: #2f9bc2;
    font-weight: 600;

    &:hover {
      color: #2d86a7;
    }
  }

  &Count {
    color: #fa9903;
    font-weight: 600;
  }

  svg path {
    fill: #fa9903;
  }
}
