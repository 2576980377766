@import "styles/vars";

.modal {
  position: absolute;
  background: #fff;
  width: 30em;
  max-height: 95%;
  min-height: 10.5em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5em;
  outline: none;
  &.short {
    min-height: 8.5em;
  }
  &Overlay {
    position: absolute;
    left: 18.75em;
    top: 4em;
    bottom: 0;
    width: calc(100% - 18.75em);
    height: calc(100% - 4em);
    z-index: 10;
    background-color: rgba(39, 49, 61, 0.3) !important;
  }
  &Header {
    position: relative;
    text-align: center;
    padding: 1.5em 0;
    border-radius: 0.5em 0.5em 0 0;
    > span {
      font-size: 1.5em;
      line-height: 116.667%;
      font-weight: 600;
      color: #27313d;
    }
  }

  &Close {
    position: absolute;
    right: 1.5em;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    svg {
      width: 1.5em;
      height: 1.5em;
      path {
        transition: all 0.2s ease 0s;
        fill: #525a64;
      }
    }
  }
  &Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &Options {
      display: flex;
      align-items: center;
      gap: 0.5em;
      margin-top: 0.5em;
      > button {
        font-weight: 600;
        font-size: 0.875em;
        &:last-of-type {
          svg path {
            fill: none;
            stroke: $link;
          }

          &:hover {
            svg path {
              fill: none;
              stroke: $link-hover;
            }
          }

          &:active {
            svg path {
              fill: none;
              stroke: $link-active;
            }
          }
        }
      }
    }
  }
}
