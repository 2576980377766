@import "styles/vars";

.customers {
  background: $white;
  border: 1px solid $white2;
  border-radius: 0.5em 0.5em 0 0;
  box-shadow: 0 1.5em 6.25em rgb(31 37 54 / 7%);
  color: $gray5;
  display: grid;
  margin-bottom: 1.75em;
  min-height: calc(100% - 1.75em);
  position: relative;

  &Domain {
    background: $white;
    box-shadow: 0px 1.5em 6.25em rgba(31, 37, 54, 0.07);
    border-radius: 0.5em 0.5em 0 0;
    color: $gray5;
    position: relative;
  }

  &Header {
    padding: 1em 2em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.5em;
  }

  &Wrap {
    display: flex;
    align-items: center;
    &Button {
      font-size: 0.8125em;
      color: $link2;
      svg path {
        fill: $link2;
      }
      &:hover {
        color: $link2-hover;
        svg path {
          fill: $link2-hover;
        }
      }
      &:active {
        color: $link2-active;
        svg path {
          fill: $link2-active;
        }
      }
    }
  }

  &Title {
    margin: 0 0 0 0.5em;
    font-weight: 500;
    font-size: 1em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
