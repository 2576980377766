.container {
  border-radius: 0.5em;
  border: 1px solid #e0e0e0;
  padding: 1em 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  &.azure {
    padding: 0 0 0 1em;
    border: none;
  }
  &Graphic {
    height: 12.625em;
  }
}

.link {
  display: block;
  text-align: center;
  border-top: 1px solid #e0e0e0;
  padding-top: 1.5em;
  > a {
    font-weight: 500;
  }
}
