.analytics {
  &Title {
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.2963em;
    &Wrap {
      padding: 1em 2em;
      border-radius: 0.5em 0.5em 0 0;
      background-color: #fff;
    }
  }
}
