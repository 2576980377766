.totals {
  padding: 1em 2em;
  border-bottom: 1px solid #e3e8ee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  &Backdrop {
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    background: #ffffff7d;
  }

  &Sections {
    display: flex;
    width: 70%;
  }

  &Section {
    width: 40%;
    position: relative;

    &:last-child {
      padding: 0 0 0 2em;
    }

    &:first-child:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 50%;
      transform: translateY(50%);
      height: 85%;
      width: 1px;
      border-right: 1px solid #e3e8ee;
    }

    &Title {
      margin: 0 0 0.5em;
      font-weight: 500;
      font-size: 0.875em;
      display: flex;
      align-items: center;
      color: #525a64;

      svg {
        margin-left: 0.5714em;
        cursor: pointer;
      }
    }

    &Date {
      display: flex;
      align-items: center;
      font-size: 1.5em;
      color: #27313d;

      &Tooltip {
        font-size: 0.6667em !important;
      }
    }
  }
}
