.login {
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;
  &Title {
    font-size: 1.5em;
    line-height: 117%;
    font-weight: 600;
    text-align: center;
  }
  &Button {
    width: 100%;
  }
}
