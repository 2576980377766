.status {
  &Container {
    text-align: center;
    &Absolute {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &Disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
    &Error {
      border: 1px solid #fcc2c3;
      padding: 1em;
      border-radius: 0;
      background: #fce4e4;
      color: #f3657b;
      width: 90%;
    }
  }

  &Header {
    font-weight: 600;
    margin-bottom: 0.5em;
    font-size: 1.125em;
  }
}
