.brandingEdit {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 4.125em;
  &Wrap {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    margin-bottom: 2.5em;
  }
  &Title {
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 600;
    color: #27313d;
  }
  &Info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5em;
    &Title {
      font-weight: 600;
      font-size: 1em;
      line-height: 1.5em;
      color: #27313d;
    }
    &Button {
      width: 7.9em;
      text-align: center;
    }
  }
}
