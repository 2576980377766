@import "styles/vars";

.dropdown {
  display: inline-flex;
  align-items: center;
  font-size: 1em;
  position: relative;
  &TypeButton {
    .dropdownIsButton {
      width: 100%;
      justify-content: space-between;
      > span {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  &Label {
    font-weight: 300;
    margin-right: 0.5em;
    color: #848a92;
    &Icon {
      position: absolute;
      right: 0.5em;
      width: 1.5em;
      height: 1.5em;
      svg path {
        fill: #606060;
      }
    }
  }
  &Value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &IsButton {
    &Active {
      background-color: $primary-active;
      border-color: $primary-active;
      &:hover {
        background-color: $primary-active;
        border-color: $primary-active;
      }
    }
    svg path {
      fill: #fff;
    }
  }
  &Button {
    align-items: center;
    border: 1px solid #e3e8ee;
    border-radius: 0.5em;
    color: #27313d;
    cursor: pointer;
    display: flex;
    height: 2.55em;
    justify-content: space-between;
    padding: 0 2.25em 0 1em;
    position: relative;
    transition: all 0.2s ease 0s;
    &.disabled {
      pointer-events: none;
      background-color: #f7f9fc;
      border-color: #e3e8ee;
      svg {
        fill: #a9adb3;
      }
    }
    &StrightRight {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    &Small {
      border-radius: 1.4286em;
      height: 2em;
    }
    &Active {
      background-color: #f7f9fc;
      border-color: #bcd6eb;
      z-index: 1;
    }
    &Icon {
      padding: 0 2.25em 0 2.75em;
    }
    svg {
      fill: #606060;
      position: absolute;
      right: 0.5em;
      &:first-child {
        left: 1em;
      }
    }
  }
  &Options {
    position: absolute;
    top: 3em;
    right: 0;
    background-color: $white;
    border-radius: 0.5em;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.246203);
    z-index: 3;
    max-height: 15.25em;
    overflow-y: auto;
    padding: 0.5em 0;
    cursor: default;
    will-change: transform;
    &.top {
      top: auto;
      bottom: 3em;
    }
    &Search {
      border: none;
      font-size: 0.875em;
      outline: none;
      padding: 0.65em 1.1429em;
      width: 100%;
      font-weight: 500;
      border-radius: 0.5em 0.5em 0 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &Transition {
      &Enter {
        opacity: 0;
        transform: scale(0.9);
        transform-origin: top;
      }
      &EnterActive {
        opacity: 1;
        transform: translateX(0);
        transition:
          opacity 200ms,
          transform 200ms;
      }
      &Exit {
        opacity: 1;
      }
      &ExitActive {
        opacity: 0;
        transform-origin: top;
        transform: scale(0.9);
        transition:
          opacity 200ms,
          transform 200ms;
      }
    }
    &Item {
      color: #27313d;
      cursor: pointer;
      display: block;
      width: 100%;
      text-align: left;
      font-size: 1em;
      overflow: hidden;
      padding: 0.58em 1.18em;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: all 0.2s ease 0s;
      &.active {
        padding-right: 2.4em;
      }
      &:hover {
        background-color: #f7f7f7;
        color: #4169b8;
        svg path {
          fill: #4169b8;
        }
      }
      &Icon {
        color: #525a64;
        font-size: 1em;
        padding-left: 3em;
        text-align: left;
        svg {
          left: 1em;
        }
      }
      svg {
        position: absolute;
        right: 1.145em;
        fill: #606060;
        height: 1.5em;
        width: 1.5em;
      }
      &Active {
        cursor: default;
        pointer-events: none;
      }
      &Disabled {
        color: #848a92;
        pointer-events: none;
      }
    }
  }
  &NoResults {
    color: #525a64;
    font-style: italic;
    font-weight: 400;
    margin: 1.5em 0;
    pointer-events: none;
    text-align: center;
  }
}
