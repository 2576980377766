@import "styles/vars";

.login {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  font-size: 1em;
  &Title {
    font-size: 1.5em;
    line-height: 117%;
    font-weight: 600;
    text-align: center;
    position: relative;
  }
  &Back {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    svg {
      width: 1em;
      height: 1em;
    }
  }
}

.azure {
  &LoginBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 0.375em;
    background: $white;
    border: 1px solid $azure-primary;
    color: $gray5;
    border-radius: 0;
    font-weight: 600;
    padding: 0.7em;
    width: 100%;
    transition: all 0.2s ease 0s;
    height: 3em;
    cursor: pointer;
    &:hover {
      border-color: $azure-hover;
    }
    &:active {
      background: $azure-hover;
      color: $white;
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  &InputWrap {
    margin-bottom: 2em;
  }
}
