.table {
  tr {
    &:hover {
      td {
        &:last-child {
          > div,
          > button {
            display: block;
          }
        }
      }
    }
    td {
      &:last-child {
        justify-content: flex-end;
        > div,
        > button {
          display: none;
        }
      }
    }
  }
}
