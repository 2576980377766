.search {
  position: relative;
  align-self: stretch;
  &Input {
    padding: 0.44em 0.75em 0.44em 2.5em;
    border: 1px solid #e3e8ee;
    border-radius: 0.5em;
    outline: none;
    width: 17.5em;
    font-weight: 400;
    color: #27313d;
    transition: all 0.2s ease 0s;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &.isLoading {
      opacity: 0.7;
      pointer-events: none;
    }
    &FullWidth {
      width: 100%;
    }
    &StrightLeft {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      margin-left: -1px;
      height: 100%;
    }
    &:hover {
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    }

    &:focus {
      border-color: #bcd6eb;
    }

    &::placeholder {
      font-weight: 300;
      color: #848a92;
    }
  }
  &Icon {
    position: absolute;
    top: 50%;
    left: 1em;
    transform: translateY(-50%);
  }
  &Action {
    position: absolute;
    top: 50%;
    right: 1em;
    width: 1em;
    height: 1em;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.2s ease 0s;
    border-radius: 50%;
    &:hover {
      background-color: #e3e8ee;
    }
  }
}
