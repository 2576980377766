.info {
  background-color: #fff;
  border-radius: 0.5em;
  &Row {
    display: flex;
  }
  &Col {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 1.5em;
    &:first-child {
      border-right: 1px solid #e0e0e0;
    }
    &.azure {
      border: none;
    }
  }
  &Title {
    color: #525a64;
    font-size: 1.125em;
    line-height: 1.3em;
    margin-bottom: 0.3125em;
    font-weight: 500;
  }
  &Count {
    color: #27313d;
    font-weight: 500;
    font-size: 1.75em;
    line-height: 1.3em;
  }
}
