.bulkActions {
  background: #fff;
  display: flex;
  align-items: baseline;
  padding: 1.5em 2em 2em 2em;
  z-index: 2;
  height: 6.5em;

  &.active {
    position: sticky;
    top: calc(-1em - 1px);
    transition: 0.2s ease-out;

    &.sticked {
      height: auto;
      box-shadow: 0 0 0.25em #818181;
      padding: 1em 2em;
    }
  }
  &Wrap {
    display: flex;
    align-items: center;
  }
  &Count {
    font-weight: 500;
    padding-right: 1.5em;
    margin-right: 1.5em;
    position: relative;
    &::after {
      background: #e3e8ee;
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
    }
  }
  &ButtonsWrap {
    display: flex;
    gap: 1.5em;
  }
}
