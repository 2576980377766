.company {
  background: #fff;
  border: 1px solid #e3e8ee;
  box-shadow: 0px 1.5em 6.25em rgba(31, 37, 54, 0.07);
  border-radius: 0.5em 0.5em 0 0;
  color: #27313d;
  position: relative;
  margin-bottom: 1.75em;
  min-height: calc(100% - 1.75em);
  display: grid;
  &Title {
    padding: 0.6667em 1.3333em;
    font-weight: 600;
    font-size: 1.5em;
    margin: 0;
  }
}
