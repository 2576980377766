.statusEnabled,
.statusPending,
.statusReset,
.statusDisabled {
  font-size: 0.875em;
  font-weight: 600;
}

.statusEnabled {
  color: #4daa90;
}

.statusPending {
  color: #d89800;
}

.statusReset {
  color: #d14710;
}

.statusDisabled {
  color: #5d6a7a;
}
