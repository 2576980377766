.assignmentsEmpty {
  margin-top: 2.5em;
  padding: 0 2em;

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
  }

  &Title {
    font-size: 1em;
    margin: 0 0 0.75em;
  }

  &List {
    li {
      font-size: 0.875em;

      &:not(:last-child) {
        margin-bottom: 0.8571em;
      }

      &:before {
        content: "\2022";
        color: #848a92;
        font-weight: bold;
        display: inline-block;
        width: 1em;
      }
    }
  }

  &Info {
    margin-top: 1.5em;
    padding: 1.5em;
    background: #f5f8fa;
    border: 1px solid #e3e8ee;
    border-radius: 0.5em;
  }
}
