.devices {
  position: relative;
  display: flex;
  flex-direction: column;
  &TabBackdrop {
    z-index: 2;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffff7d;
  }
}

.statusMessage {
  position: relative;
  flex: auto;
}

.table {
  &RowChecked {
    background-color: #f7fafc;
  }
  &RowUnlicensed {
    border-color: rgba(227, 232, 238, 0.4);
    td {
      .name {
        &Hostname {
          &Name,
          &Icon {
            opacity: 0.4;
            pointer-events: none;
          }
          &Wrap {
            svg path {
              fill: #fa9903;
            }
          }
        }
        &Status {
          opacity: 0.4;
          pointer-events: none;
        }
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
  tr {
    td {
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      height: 4.75em;
    }
  }
}
.name {
  &Hostname {
    align-items: center;
    display: flex;
    height: 2em;
    &Icon {
      margin-right: 0.5em;
      &Online {
        path {
          fill: #8bc53f;
        }
      }
    }
    &Name {
      font-weight: 500;
    }
    &Wrap {
      height: 1.25em;
      margin: 0 0 0 0.25em;
      position: relative;
      width: 1.25em;
    }
    &Tooltip {
      height: 1.25em;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1.25em;
    }
  }
  &Status {
    color: #525a64;
    font-size: 0.75em;
    font-weight: 600;
    line-height: 1em;
    margin-left: 2.8571em;
    &Info {
      display: flex;
      align-items: center;
      svg {
        height: 1.25em;
        width: 1.25em;
      }
      &Installed {
        color: #4daa90;
        svg path {
          fill: #4daa90;
        }
      }
      &Uninstalled {
        color: #848a92;
        svg path {
          fill: #848a92;
        }
      }
      &Pending {
        color: #fa9903;
        svg path {
          fill: #fa9903;
        }
      }
    }
  }
}

.status {
  background-color: #8bc53f;
  border-radius: 1.6666em;
  color: #fff;
  font-size: 0.75em;
  font-weight: 600;
  line-height: 1.3333em;
  padding: 0.4167em 1.3333em;
  &Offline {
    border: 1px solid #a9adb3;
    color: #a9adb3;
    background-color: transparent;
  }
}

.deviceInfo {
  font-size: 0.875em;
  span {
    font-weight: 500;
    margin-left: 0.5em;
  }
}

.device {
  &Button {
    transition: all 0.2s ease 0s;
    font-weight: 600;
    &Delete {
      color: #f3657b;
      &:hover {
        color: #ac0909;
      }
      &:active {
        color: #8f0505;
      }
    }
    &Uninstall {
      color: #2f9bc2;
      &:hover {
        color: #2d86a7;
      }
      &:active {
        color: #2b6781;
      }
    }
  }
}

.allStatuses {
  th,
  td {
    &:first-child {
      display: none;
    }
    &:nth-child(2) {
      padding-left: 2em;
    }
  }
}
