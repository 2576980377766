@import "styles/vars";

.list {
  margin-bottom: 2.125em;
  &Colums {
    margin-bottom: 0.625em;
    font-size: 0.8125em;
    color: $link2;
  }

  &Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 1.25em;
    &.row {
      display: flex;
      flex-direction: column;
      row-gap: 1em;
    }
  }
}
