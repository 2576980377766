.tooltip {
  opacity: 1 !important;
  font-size: 0.875em !important;
  line-height: 171% !important;
  padding: 0.75em !important;
  color: #27313d;
  border: 1px solid #e3e8ee;
  box-shadow: 0px 10px 25px rgba(31, 37, 54, 0.1);
  border-radius: 0.25em;
}
