.table {
  margin-top: 1em;
  tr {
    height: 4em;
    &:hover {
      td {
        &:last-child {
          > div,
          > button {
            display: block;
          }
        }
      }
    }
    td {
      &:last-child {
        justify-content: flex-end;
        > div,
        > button {
          display: none;
        }
      }
    }
  }

  &Name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-weight: 500;
  }

  &Upn {
    color: #525a64;
    font-size: 0.75em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &Wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &Actions {
    width: 100%;
    text-align: right;
  }
}
