@import "styles/vars";

.controls {
  &Item {
    text-align: center;

    &Title {
      font-weight: 500;
      font-size: 1.125em;
      margin: 0 0 0.4444em;
      color: #525a64;
    }

    &Button {
      color: $link2;
      svg path {
        fill: $link2;
      }
      &:hover {
        svg path {
          fill: $link2;
        }
      }
      &:active {
        svg path {
          fill: $link2;
        }
      }
    }

    &Wrap {
      display: flex;
      align-items: center;

      svg {
        cursor: pointer;
        padding: 0;
        height: 24px;
        width: 24px;

        &:first-child {
          margin-right: 0.5em;
        }
        &:last-child {
          margin-left: 0.5em;
        }

        &:hover:not(.disabled) {
          background: #f7f9fc;
        }
        &:active:not(.disabled) {
          background: #e3e5e8;
        }

        &.disabled {
          pointer-events: none;

          path {
            fill: #a9adb3;
          }
        }
      }

      &Input {
        width: 6em;
        padding: 0.4em;
        text-align: center;
        border: 2px solid #f7f9fc;
        background: #f7f9fc;
        border-radius: 0.5em;
        transition: all 0.2s ease 0s;

        &Wrap {
          font-weight: 500;
          font-size: 1.25em;
        }

        &:hover {
          box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
        }

        &:focus {
          border-color: #bcd6eb;
          background-color: #fff;
          box-shadow: none;
          outline: none;
        }

        &:disabled {
          color: #848a92;

          &:hover {
            box-shadow: none;
          }
        }

        &.warning,
        &.warning:focus {
          border-color: #fa9903;
        }
        &[disabled] {
          pointer-events: none;
        }
      }
    }

    &:first-child {
      margin-right: 8em;
    }
  }
}
