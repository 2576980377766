.navbar {
  position: relative;
  width: 18.75em;
  height: 100vh;
  background: #1f2536 url("../../../assets/img/navbar_backgroung.svg") no-repeat;
  background-size: contain;
  padding: 1em 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &Menu {
    overflow-y: auto;
  }

  &BrandedLinks {
    gap: 1.25em;
    padding: 0.5em 0 0.7em;
    margin-top: 1em;
    font-size: 0.93em;
    border-top: 1px solid rgb(132 138 146 / 30%);
    &:empty {
      border-top: none;
      padding: 0;
    }
  }

  &Logo {
    display: inline-block;
    margin: 0 0 2.5em 1em;
    svg path {
      fill: #fff;
    }
    &:hover {
      svg path {
        fill: #fff;
      }
    }
    img {
      height: 2.85em;
    }
  }
  &List {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  &Footer {
    padding: 1em 1em 0;

    &Wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &Copyright {
      font-weight: normal;
      font-size: 0.75em;
      color: rgb(255 255 255 / 70%);

      &.inverted {
        color: #525a64;
      }

      span:first-child {
        margin-right: 0.25em;
      }
    }

    &Downloads {
      position: relative;

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
      }

      &.inverted {
        .navbarFooterDownloadsBtn {
          color: #000;
          &:hover {
            color: #4169b8;
            svg path {
              fill: #4169b8;
            }
          }
          &.active,
          &:active {
            color: #38558b;
            svg path {
              fill: #38558b;
            }
          }
          svg path {
            fill: #000;
          }
        }
      }

      &Btn {
        color: #fff;
        display: flex;

        > span {
          flex-direction: row-reverse;
          gap: 0;
        }

        svg path {
          fill: #fff;
        }

        &:hover {
          color: #e6e6e6;
          svg path {
            fill: #e6e6e6;
          }
        }
        &:active,
        &.active {
          color: #c1c1c1;
          svg path {
            fill: #c1c1c1;
          }
        }
      }

      > ul {
        position: absolute;
        bottom: 1.75em;
        right: 0;
        width: 16.75em;
        border: 1px solid rgba(227, 232, 227, 0.3);
        border-radius: 0.5em;
        font-weight: 500;
        background: #1f2536;
        z-index: 2;
        box-shadow: -0.125em -0.125em 0.5em rgba(114, 114, 114, 0.4);

        a {
          color: #fff;

          &:first-child li {
            border-radius: 0.5em 0.5em 0 0;
          }
          &:last-child li {
            border-radius: 0 0 0.5em 0.5em;
          }

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.75em 1em;
            cursor: pointer;
            position: relative;
            font-size: 0.875em;

            &:hover {
              background-color: rgba(227, 232, 238, 0.1);

              & > svg {
                opacity: 1;
              }
            }

            span {
              display: flex;
              align-items: center;

              > svg {
                margin-right: 0.75em;
              }
            }

            > svg {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
