.container {
  &Row {
    display: flex;
  }
  &Col {
    flex: 0 0 50%;
    max-width: 50%;
    &:first-child {
      border-right: 1px solid #e0e0e0;
      margin-right: 0.75em;
    }
    &.azure {
      border: none;
      margin: 0;
    }
  }
  &Title {
    color: #525a64;
    font-size: 1.125em;
    line-height: 1.3em;
    font-weight: 500;
    margin-bottom: 0.3125em;
  }
  &Count {
    font-size: 1.5em;
    color: #27313d;
    font-weight: 500;
    line-height: 1.3em;
  }
  &SmallTitle {
    color: #5d6a7a;
    font-size: 1em;
    line-height: 1.3em;
    font-weight: 500;
    margin-bottom: 0.25em;
  }
  &SmallCount {
    color: #27313d;
    font-size: 1em;
    line-height: 1.3em;
    font-weight: 500;
  }
  &Subcol {
    &:first-child {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 0.75em;
    }
    &:last-child {
      padding-top: 0.375em;
    }
  }
}
