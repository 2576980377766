.react-tabs {
  height: calc(100% - 4em);
  &__tab-list {
    margin: 0;
    border-bottom: 1px solid #e3e8ee;
    padding: 0 2em;
    position: relative;
  }
  &__tab {
    padding: 0.8889em;
    border: none;
    box-shadow: none;
    font-weight: 500;
    font-size: 1.125em;
    color: #848a92;
    &--selected {
      color: #4169b8;
    }
    &--selected,
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.15em;
        border-radius: 0.125em;
        background-color: #4169b8;
      }
    }

    &:hover {
      &:not(.react-tabs__tab--selected) {
        color: #525a64;
      }
    }
  }
  &__tab-panel {
    height: calc(100% - 3.625em);
    position: relative;
  }
}
