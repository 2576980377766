.eula {
  height: calc(100% - 11.125em);
  padding: 0;
  &Cancel {
    max-width: 10.5em;
  }
  &Content {
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 3em;
    ul,
    ol {
      padding-left: 2em;
      list-style: disc;
      margin-bottom: 1em;
      li {
        list-style: inherit;
        margin-bottom: 0.5em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    ol {
      list-style: decimal;
    }
    p {
      margin-bottom: 1em;
    }
  }

  &Intro {
    font-weight: 600;
  }
}
