.assignments {
  &Table {
    tr {
      transition: all 0.2s ease 0s;
      position: relative;
      th,
      td {
        &:last-child {
          justify-content: flex-end;
        }
      }
      td {
        height: 4em;
      }
      &:hover {
        .assignmentsButton {
          visibility: visible;
          transform: scale(1) translateY(-50%);
        }
      }
    }
  }
  &Button {
    position: absolute;
    top: 50%;
    right: 2em;
    visibility: hidden;
    transition: 0.2s transform cubic-bezier(0.1, 1.26, 0.83, 1) 0s;
    transform: scale(0.95) translateY(-50%);
    will-change: transform;
  }
}
