.validation {
  position: relative;
  min-height: 8.8em;

  &Button {
    width: 100%;
  }

  &Info {
    margin-bottom: 1.5em;
    font-size: 0.875em;
    line-height: 140%;
    color: #525A64;
    text-align: center;

    b {
      color: #27313d;
    }
  }
}