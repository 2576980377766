.Toastify__toast-container {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 0.875em;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 0.5em;
  width: 100%;
  display: flex;
  justify-content: center;

  .Toastify__toast {
    min-height: unset;
    font-family: "IBM Plex Sans", sans-serif;
    margin: 0;
    cursor: default;
    position: absolute;
    top: 4px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .Toastify__toast-body {
    padding: 6px 0;
  }

  .Toastify__toast--success {
    background: #74b37a;
  }
  .Toastify__toast--error {
    background: #f3657b;
  }
  .Toastify__toast--warning {
    background: #eba54e;
  }

  .Toastify__close-button {
    margin-top: 4px;
    align-self: unset;
    margin-left: 20px;
    opacity: 1;
  }
}
