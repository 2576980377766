@import "styles/vars";

.dropdown {
  display: inline-flex;
  align-items: center;
  font-size: 1em;
  position: relative;
  margin-left: 1em;
  &Button {
    align-items: center;
    color: #27313d;
    cursor: pointer;
    display: flex;
    height: 2.55em;
    justify-content: space-between;
    padding: 0 2.25em 0 1em;
    position: relative;
    width: 100%;
    &:hover {
      .dropdownValue {
        color: $primary;
      }
    }
    &:active {
      .dropdownValue {
        color: $primary-active;
      }
    }
    &Active {
      .dropdownValue {
        color: $primary-active;
      }
      &:hover {
        .dropdownValue {
          color: $primary-active;
        }
      }
    }
    svg {
      fill: #5d6a7a;
      position: absolute;
      right: 0.5em;
    }
  }
  &Label {
    color: #525a64;
    font-size: 0.875em;
    font-weight: 300;
    margin-right: 0.5em;
  }
  &Value {
    color: #5d6a7a;
    height: 1.4286em;
    line-height: 1.4286em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &Options {
    border-radius: 0.5em;
    background-color: #fff;
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
    padding: 0.5em 0;
    position: absolute;
    right: 0;
    top: 3em;
    width: 100%;
    z-index: 3;
    &Item {
      width: 100%;
      text-align: left;
      color: #27313d;
      font-size: 1em;
      padding: 0.58em 1.18em;
      position: relative;
      transition: all 0.2s ease 0s;
      &:hover {
        background-color: #f7f7f7;
        color: #4169b8;
      }
      &Active {
        cursor: default;
        svg {
          position: absolute;
          right: 1.145em;
          height: 1.5em;
          width: 1.5em;
        }
      }
    }
    &Transition {
      &Enter {
        opacity: 0;
        transform: scale(0.9);
        transform-origin: top right;
      }
      &EnterActive {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 200ms, transform 200ms;
      }
      &Exit {
        opacity: 1;
      }
      &ExitActive {
        opacity: 0;
        transform-origin: top right;
        transform: scale(0.9);
        transition: opacity 200ms, transform 200ms;
      }
    }
  }
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 1.15em;
  height: 1.15em;

  &Path {
    stroke: #5d6a7a;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
