@import "styles/vars";

.info {
  &Wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -2em;
    height: 1.25em;
    width: 1.25em;
    text-align: left;
    z-index: 10;

    svg {
      cursor: pointer;
      path {
        fill: $primary;
      }
    }
  }

  &Content {
    background: $white;
    width: max-content;
    z-index: 1;
    position: absolute;
    cursor: default;
    box-shadow: 0px 0.25em 0.5em rgba(59, 73, 92, 0.1), 0px 0.25em 2em rgba(154, 156, 160, 0.1);
    &.left {
      top: 50%;
      transform: translateY(-50%);
      right: 2em;
      padding: 0.75em 1em;
      &::after {
        content: "";
        width: 0.6em;
        height: 0.6em;
        transform: rotate(-45deg) translateY(-50%);
        background: $white;
        position: absolute;
        z-index: -1;
        right: -8px;
        top: 50%;
        box-shadow: 0.125em 0.125em 0.25em rgba(59, 73, 92, 0.08);
      }
    }
    &.right {
      top: 50%;
      transform: translateY(-50%);
      left: 2em;
      padding: 0.75em 1em;
      &::after {
        content: "";
        width: 0.6em;
        height: 0.6em;
        transform: rotate(-45deg) translateY(-50%);
        background: $white;
        position: absolute;
        z-index: -1;
        left: -2px;
        top: 50%;
        box-shadow: -0.125em -0.125em 0.25em rgba(59, 73, 92, 0.08);
      }
    }
    &.down {
      right: -1em;
      top: calc(100% + 0.3125em + 0.25em);
      border: 1px solid $gray7;
      border-radius: $border-radius;
      padding: 0.75em;
      &::after {
        content: "";
        width: 0.5em;
        height: 0.5em;
        transform: rotate(-45deg);
        background: $white;
        position: absolute;
        box-shadow: 0.125em -0.125em 0.25em rgba(59, 73, 92, 0.1);
        z-index: -1;
        right: 1.25em;
        top: -0.3125em;
        border-top: 1px solid $gray7;
        border-right: 1px solid $gray7;
      }
    }

    li {
      font-size: 0.8125em;
    }
  }

  &Title {
    font-weight: 500;
    font-size: 0.875em;
    margin-bottom: 0.5em;
  }
}
