@import "styles/vars";

.validation {
  position: relative;
  min-height: 12.2em;

  &Info {
    font-size: 0.875em;
    line-height: 140%;
    text-align: center;
    color: #525A64;
    font-weight: 400;

    &Email {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-weight: 600;
      gap: 0.5em;
      margin: 0.5em 0 1.5em;
      color: #27313D;
    }
  }

  &Footer {
    text-align: center;
    color: $link2;
    font-size: 0.8125em;
    margin-top: 1.5em;

    &Button {
      font-weight: 600;
    }

    span {
      padding-right: 0.3em;
    }
  }
}