.header {
  background: #f7fafc;
  border: 1px solid #e3e8ee;
  border-radius: 0.5em;
  padding: 1em 1.5em;
  margin-bottom: 1em;

  &Row {
    display: flex;
    align-items: center;
    position: relative;
  }

  &Col {
    flex: 0 0 50%;
    max-width: 50%;
  }

  &Title {
    margin: 0 0 0.3125em;
    font-weight: 600;
    font-size: 1em;
    color: #27313d;
  }

  &Subtitle {
    font-size: 0.8125em;
    color: #525a64;
  }

  &Switch {
    margin-right: 1em;

    &Text {
      h4 {
        font-weight: 500;
        font-size: 0.875em;
        color: #27313d;
        margin: 0 0 0.3125em;
      }

      p {
        font-weight: 400;
        font-size: 0.75em;
        color: #525a64;
      }
    }
  }
}
