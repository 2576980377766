@import "styles/vars";

.resetMfa {
  &Info {
    text-align: center;
    font-size: 0.875em;
    line-height: 140%;
    color: #525a64;
    margin-bottom: 1.5em;
  }
  &Wrap {
    margin-bottom: 0.5em;
  }
  &Label {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 150%;
    color: #27313d;
    text-align: center;
    margin-bottom: 0.75em;
  }
}
