.info {
  &Section {
    display: flex;
    align-items: center;
    margin: 0 0 0.7143em;

    &Text {
      color: #525a64;
      font-size: 0.875em;
      margin: 0;
      b {
        font-size: 1.1428em;
        font-weight: 600;
        color: #27313d;
      }
    }
  }
}
