.footer {
  border-top: 1px solid #e3e8ee;
  margin: 0 2em;
  padding: 1em 0;
  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &Links {
    display: flex;
    align-items: center;
    gap: 0.75em;
  }
  &Link {
    display: flex;
    align-items: center;
    gap: 0.75em;
    font-weight: 500;
    font-size: 1em;
    line-height: 1.5em;
    color: #4169b8;
    svg {
      width: 1.25em;
      height: 1.25em;
      path {
        fill: #4169b8;
      }
    }
    &Icon {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
    &Wrap {
      display: flex;
      align-items: center;
      gap: 0.75em;
    }
  }
  &Actions {
    display: flex;
    align-items: center;
    gap: 2.5em;
    &Checkbox {
      display: flex;
      align-items: center;
      gap: 1em;
    }
    &Buttons {
      display: flex;
      align-items: center;
      gap: 1.5em;
    }
    &Save {
      max-width: 9.75em;
      transition: max-width 0.3s ease 0s;
      justify-content: flex-end;
      padding: 0 1.93em;
      text-align: right;
      &.loading {
        max-width: 11em;
      }
    }
  }
}
