@import "styles/vars";

.domainMgmt {
  &Info {
    margin: 1.2em 2.2em;
    border: 1px solid $white2;
    border-radius: 8px;
    padding: 0.7em 1.1em;
    display: flex;
  }
  &Icon {
    margin-right: 0.4em;
    svg {
      width: 1em;
      height: 1em;
    }
  }
  &Title {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.5em;
    color: $gray5;
  }
  &Text {
    font-size: 0.875em;
    line-height: 1.42em;
    color: $link2;
  }
  &Link {
    color: $link;
    font-size: 0.92em;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    gap: 0.6em;
    &:hover {
      color: $link-hover;
    }
    &:active {
      color: $link-active;
    }
    svg {
      transform: scale(-1);
      path {
        fill: $link;
      }
    }
  }
}
