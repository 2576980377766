.variant {
  display: flex;
  align-items: center;
  text-align: left;
  border: 1px solid #7a97ba;
  outline: 1px solid transparent;
  outline-offset: -2px;
  border-radius: 0.25em;
  font-weight: 500;
  font-size: 1em;
  line-height: 112%;
  color: #525a64;
  margin-bottom: 0.75em;
  transition: all 0.2s ease 0s;
  position: relative;
  width: 100%;

  &:hover {
    border-color: #5175bd;
    background-color: #f8fafc;
  }

  &:active {
    border-color: #38558B;
    background-color: #fff;
  }

  &:focus {
    box-shadow: 0 0 0 3px #bcd6eb;
    background-color: #fff;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    color: #4169b8;
    border-color: #4169b8;
    outline-color: #4169b8;

    .setupMfaOptionsTooltipIcon svg path {
      fill: #4169b8;
    }

    label {
      >span {
        >svg {

          rect,
          path {
            stroke: #4169b8;
          }
        }
      }
    }
  }

  label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    justify-content: space-between;
    flex: 1;
    padding: 0.875em 1em;

    >span {
      display: flex;
      align-items: center;
      position: relative;

      >svg {
        flex: 0 0 1.5em;
        width: 1.5em;
        height: 1.5em;

        path,
        rect {
          transition: all 0.2s ease 0s;
        }
      }
    }
  }

  &Name {
    margin: 0 0.25em 0 0.75em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
      font-weight: 700;
    }
  }
}