@import "styles/vars";

.setPass {
  &Info {
    text-align: center;
    font-size: 0.875em;
    line-height: 140%;
    color: #525a64;
    margin-bottom: 1.5em;
    strong {
      color: #27313d;
      font-weight: 700;
    }
  }
  &Wrap {
    position: relative;
  }
  &Form {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  }
  &Tooltip {
    position: absolute;
    width: 23em;
    z-index: 2;
    cursor: default;
    box-shadow:
      0px 0.25em 0.5em rgb(59 73 92 / 10%),
      0px 0.25em 2em rgb(154 156 160 / 10%);
    padding: 1em 1em 1.5625em 1em;
    background: #ffffff;
    bottom: calc(100% + 0.3125em + 0.25em);
    border: 1px solid $gray7;
    border-radius: $border-radius;
    left: 50%;
    transform: translateX(-50%);
    color: #525a64;
    h5 {
      font-size: 0.875em;
      font-weight: 500;
      color: #27313d;
      margin-bottom: 0.5em;
      line-height: 1em;
    }

    ul,
    li {
      list-style: disc;
      line-height: 190%;
      font-size: 0.875em;
    }

    ul {
      padding-left: 1.2em;
    }

    &::after {
      content: "";
      width: 0.5em;
      height: 0.5em;
      transform: rotate(135deg) translateY(-50%);
      background: $white;
      position: absolute;
      left: 50%;
      text-align: center;
      box-shadow: 0.125em -0.125em 0.25em rgb(59 73 92 / 10%);
      z-index: -1;
      top: calc(100% - 0.4em);
      border-top: 1px solid $gray7;
      border-right: 1px solid $gray7;
    }
  }
}
