.clearInput {
  position: relative;
  display: inline-block;

  &Error {
    .clearInputWrapperInput {
      border-color: #f3657b;
      &:focus {
        border-color: #f3657b;
      }
    }
  }

  &Wrapper {
    position: relative;
    width: 100%;

    &Input {
      padding: 0.5em 1.75em 0.5em 1em;
      color: #525a64;
      border: 1px solid #fff;
      background-color: #f7f9fc;
      border-radius: 0.5em;
      outline: none;
      width: 100%;

      &:focus {
        background-color: #fff;
        border-color: #bcd6eb;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin: 0;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      margin-right: 0.75em;
      cursor: pointer;
    }
  }

  &ErrorMsg {
    position: absolute;
    left: 0;
    margin-top: 0.5em;
    font-weight: normal;
    font-size: 0.6875em;
    color: #f3657b;
  }
}
