.buttonActions {
  margin-top: 1em;
  :global(.braintree-dropin-react-submit-btn-wrapper) {
    align-items: center;
    border-top: 1px solid rgba(82, 90, 100, 0.1);
    display: flex;
    gap: 1em;
    justify-content: flex-end;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }
}
