.modal {
  &Portal {
    position: absolute;
    background: #fff;
    width: 33em;
    max-height: 95%;
    min-height: 13em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.5em;

    &.overflow {
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        margin: 0.5em 0;
      }
    }

    &:focus-visible {
      outline: none;
    }
  }

  &Overlay {
    position: fixed;
    inset: 0;
    opacity: 0;
    transition: opacity 360ms ease-in-out;
  }

  &Header {
    padding: 1em 1.3333em 0.68em 1.3333em;
    background-color: #fff;
    position: sticky;
    top: 0;
    font-weight: 600;
    font-size: 1.5em;
    color: #27313d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 20;
    border-radius: 0.5em 0.5em 0 0;

    &Title {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &Subtitle {
        font-size: 0.5833em;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &Close {
    margin-right: -0.3333em;
    cursor: pointer;

    svg path {
      transition: all 0.2s ease 0s;
      fill: #525a64;
    }

    &Subtitle {
      margin-top: -0.8em;
    }
  }

  &Content {
    color: #27313d;
    padding-bottom: 1.5em;
    margin: 0 2em 0;
    word-wrap: break-word;
    line-height: 1.5em;

    &Actions {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &Actions {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    padding: 1.5em 1.85em;
    position: sticky;
    background-color: #fff;
    z-index: 2;
    border-top: 1px solid #e3e8ee;
    border-radius: 0 0 0.5em 0.5em;

    &.sticky {
      bottom: 0;
    }

    &Buttons {
      display: flex;
      gap: 1em;
    }

    &Button {
      transition: max-width 0.3s ease 0s;
      justify-content: flex-end;

      &:first-child {
        padding: 0 2.042em;
      }

      &:last-child {
        padding: 0 1.93em;
        text-align: right;
      }

      svg {
        width: 1.15em;
        height: 1.15em;
      }
    }

    &WithSwitcher {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .switcher {
        display: flex;
        justify-content: center;
        align-items: center;

        &Title {
          font-size: 0.875em;
          margin-left: 0.5em;
          white-space: nowrap;
        }
      }
    }
  }
}

:global {
  .ReactModal__Overlay {
    &--after-open {
      opacity: 1;
      z-index: 13;
      background-color: rgba(0, 0, 0, 0.75) !important;
    }

    &--before-close {
      opacity: 0;
    }
  }
}
