@import "styles/vars";

.subscriptions {
  background: #fff;
  border: 1px solid #e3e8ee;
  border-radius: 0.5em;
  position: relative;

  :global {
    .react-tabs {
      height: 100%;
    }
  }

  &Tabs {
    &Title {
      color: #27313d;
      font-size: 1.5em;
      font-weight: 600;
      margin: 0;
      padding-left: 1.25em;
    }
    &Wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e3e8ee;
      :global {
        .react-tabs__tab-list {
          border-bottom: 0;
          &.no-tabs {
            .react-tabs__tab {
              visibility: hidden;
            }
          }
        }
      }
    }
  }

  &Content {
    position: relative;
    min-height: 22em;
    display: grid;
  }
}
