@import "styles/vars";

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border-radius: 0.25em;
  padding: 0 0.75em;
  font-weight: 600;
  font-size: 1em;
  min-width: 6em;
  width: auto;
  transition:
    color 0.2s ease 0s,
    background-color 0.2s ease 0s,
    border-color 0.2s ease 0s,
    0.2s transform cubic-bezier(0.1, 1.26, 0.83, 1) 0s;
  outline: none !important;

  &:active,
  &:active {
    outline: none;
    box-shadow: unset;
  }

  > span {
    position: relative;
  }

  &Primary {
    background-color: var(--button-bg, #4169b8);
    border-color: var(--button-border, #4169b8);
    color: var(--button-color, #fff);

    &:hover {
      background-color: var(--button-bg-hover, #5175bd);
      border-color: var(--button-border-hover, #5175bd);
    }

    &:active {
      background-color: var(--button-bg-active, #38558b);
      border-color: var(--button-border-active, #38558b);
    }

    &.buttonDisabled {
      background-color: var(--button-bg-disabled, #92a6ce);
      border-color: var(--button-border-disabled, #92a6ce);
      opacity: (var(--button-opacity, 1));
    }
  }

  &Outline {
    background-color: #fff;
    border: 1px solid #4169b8;
    color: #4169b8;

    svg path {
      fill: #4169b8;
    }

    &.buttonLoading {
      svg circle {
        stroke: #4169b8;
      }
    }

    &.buttonDisabled {
      svg circle {
        stroke: #92a6ce;
      }
    }

    &:hover {
      background-color: #5175bd;
      border-color: #5175bd;
      color: #fff;

      svg path {
        transition: color 0.2s ease 0s;
        fill: #fff;
      }
    }

    &:active {
      background-color: #38558b;
      border-color: #38558b;
      color: #fff;

      svg path {
        transition: color 0.2s ease 0s;
        fill: #fff;
      }
    }

    &.buttonDisabled {
      background-color: #fff;
      border-color: #92a6ce;
      color: #92a6ce;

      svg path {
        fill: #92a6ce;
      }
    }
  }

  &Secondary {
    border: 1px solid rgba(39, 49, 61, 0.3);
    background-color: #fff;
    color: #27313d;

    &.buttonDisabled {
      opacity: 0.5;
    }

    svg path {
      fill: #27313d;
    }

    &:hover {
      background-color: rgba(82, 90, 100, 0.05);
    }

    &:active {
      background: #3a4653;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }
  }

  &Loader {
    position: absolute;
    left: -1.5em;
    top: 50%;
    transform: translateY(-50%);
    width: 1.25em;
    height: 1.25em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    transition: transform 200ms;

    svg path {
      fill: #fff;
    }
  }

  &DeleteOutline {
    background-color: #fff;
    border: 1px solid #f3657b;
    color: #f3657b;

    &.buttonLoading {
      svg circle {
        stroke: #f3657b;
      }
    }

    &.buttonDisabled {
      opacity: 0.5;
    }

    &:hover {
      border-color: rgba(243, 101, 123, 0.8);
      color: rgba(243, 101, 123, 0.8);
      background-color: #fff;

      svg path {
        fill: rgba(243, 101, 123, 0.8);
      }
    }

    &:active {
      background-color: #f3657b;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }
  }

  &Delete {
    background-color: #f3657b;
    border: 1px solid #f3657b;
    color: #fff;

    &.buttonLoading {
      svg circle {
        stroke: #fff;
      }
    }

    &.buttonDisabled {
      background-color: #f9c1ca;
      border-color: #f9c1ca;
      color: #ffffff;
    }

    &:hover {
      border-color: #f58495;
      color: #fff;
      background-color: #f58495;

      svg path {
        fill: #fff;
      }
    }

    &:active {
      border-color: #ef3b57;
      background-color: #ef3b57;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }
  }

  &Icon {
    display: flex;
    align-items: center;
    gap: 0.5em;

    svg {
      width: 1.25em;
      height: 1.25em;
    }
  }

  &IconRight {
    .buttonIcon {
      flex-direction: row-reverse;
    }
  }

  &Rounded {
    border-radius: 1.1428em;
    border-width: 2px;
  }

  &Disabled {
    pointer-events: none;
  }

  &Size32 {
    font-size: 0.875em;
    line-height: 1.1429em;
    height: 2.3em;
  }

  &Size40 {
    height: 2.5em;
  }

  &Size48 {
    height: 3em;
  }
}

.buttonLink {
  font-size: 1em;
  font-weight: 500;
  padding: 0;
  border: none;
  text-decoration: none;
  color: $link;

  svg path {
    fill: $link;
  }

  &Bold {
    font-weight: 600;
  }

  &.buttonDisabled {
    color: #92a6ce;

    svg path {
      fill: #92a6ce;
    }
  }

  &:hover {
    color: $link-hover;

    svg path {
      fill: $link-hover;
    }
  }

  &:active {
    color: $link-active;

    svg path {
      fill: $link-active;
    }
  }
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 1.15em;
  height: 1.15em;

  &Path {
    stroke: #fff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
