.nameCell {
  width: calc(100% - 2em);
  flex-direction: column;
  align-self: flex-start;
  margin-top: 0.5em;

  &Value {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    font-weight: 500;
  }
  .email {
    font-size: 0.75em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
}
