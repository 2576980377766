@import "styles/vars";

.activeDirectory {
  &Title {
    padding: 0.6667em 1.3333em;
    font-weight: 600;
    font-size: 1.5em;
    margin: 0;
  }

  &Counter {
    padding: 1em 2em;
    > span {
      color: $link2;
      margin-right: 0.25em;
    }
  }
}
